import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        contacto:[],
        contribuyente:0,
    },
    mutations:{
        setContacto(state,data){
            state.contacto=data;
            //state.contacto.push(data);
        },
        setIdPersona(state,item){
            state.contribuyente=item;
        }
    },
    actions:{
        async getContacto({commit,rootState,getters}){
           await axios.get(`${rootState.urlapi}/contactos/${getters.getIdPersona}`,{headers:{'auth-user': getters.getToken}}).then(res=>{
                if(!res.data[0]){commit("setContacto",[])}
                commit("setContacto",res.data)
            })
        },
        async postContacto({rootState,getters},data){
            data.idpersona = getters.getIdPersona;
            await axios.post(`${rootState.urlapi}/contactos`,data,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo crearse el registro')})
        }, 
        async putContacto({rootState,getters},data){
            data.idpersona = getters.getIdPersona;
            await axios.put(`${rootState.urlapi}/contactos/${data.idcontacto}`,data,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo crearse el registro')})
        },
        async deleteContacto({rootState,getters},id){
           await axios.delete(`${rootState.urlapi}/contactos/${id}`,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo eliminarse el registro')})
        } , 
        setIdPersona({commit},item){
            commit("setIdPersona",item);
        }
    },
    getters: {
        getContactos(state){
            return state.contacto;
        },
        getIdPersona(state){
            return state.contribuyente.idpersona
        },
        getContribuyente(state){
            return state.contribuyente
        }
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}