import * as dayjs from "dayjs";

export default function anularFel(emisor,item) {

    //anularFel(nit,autorizacion,nitcliente,fechaemision,motivoanulacion)
    const anularDTE = `
            <?xml version="1.0" encoding="utf-8"?>

        <dte:GTAnulacionDocumento xmlns:dte="http://www.sat.gob.gt/dte/fel/0.1.0"

            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" Version="0.1">

            <dte:SAT>

                <dte:AnulacionDTE ID="DatosCertificados">

                    <dte:DatosGenerales ID="DatosAnulacion"

                        NumeroDocumentoAAnular="${item.autorizacion}" NITEmisor="${emisor.nit}"

                        IDReceptor="${item.nit}" FechaEmisionDocumentoAnular="${item.fechacertificacion}"

                        FechaHoraAnulacion="${dayjs().format()}"

                        MotivoAnulacion="Error en la descripcion"/>         

                </dte:AnulacionDTE>

            </dte:SAT>

        </dte:GTAnulacionDocumento>
    `
return anularDTE
}
