<template>
  <!-- Sidebar menu-->
    <div>
      <aside class="app-sidebar">
        
          <div>
            <template>
             
            <!-- avatar -->
            <v-card class="mx-auto" max-width="434" tile>
              <v-img height="100%" :src="$store.state.urlapi+'/serverimg/'+datauser.Banner">
                <v-row align="end" class="fill-height">
                  <v-col  align-self="start" class="pa-0" cols="12">
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar  class="profile"  color="grey" size="164" tile>
                      <v-img  :src="$store.state.urlapi+'/serverimg/'+datauser.Imagen"></v-img>
                      <v-expand-transition>
                         <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                          style="height: 100%;"
                        >
                          <v-btn color="blue" @click="dialog=true" style="text-decoration:none"><v-icon>mdi-account-edit</v-icon>Editar</v-btn>
                        </div>
                      </v-expand-transition>
                    </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col class="py-0">
                    <v-list-item color="rgba(0, 0, 0, .4)" dark>
                      <v-list-item-content>
                        <v-list-item-title class="title">{{$store.getters.getCurrentUser.Nombre}}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.getters.getCurrentUser.Login}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col class="text-center">
                      <v-btn depressed color="blue" @click="logout"><v-icon>mdi-account-arrow-left</v-icon>Cerrar sesión</v-btn>
                  </v-col>
                  
                </v-row>
                
              </v-img>
            </v-card>
          
            </template>
          </div>
       
        <template>
          <v-card
            class="mx-auto"
            max-width="500"
          >
            <v-list>
              <v-list-item-group color="#009688">
                <v-list-item
                  v-for="(item, i) in itemsmenu"
                  :key="i"
                  @click="menu(item.Path)"
                >
                
                  <v-list-item-icon>
                    <v-icon v-text="item.Icono"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.Nombre"></v-list-item-title>
                  </v-list-item-content>
              
                </v-list-item>
              </v-list-item-group>
            </v-list>
           
          </v-card>
       
         
        </template>

      </aside>
      <!-- MODAL SUBIR IMAGENES -->
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Cambiar Imagen
            </v-card-title>

              <v-card-text>
                <v-card class="mx-auto" max-width="900" tile>
                <v-img height="100%" :src="images.banner || $store.state.urlapi+'/serverimg/'+datauser.Banner">
                  <v-row align="end" class="fill-height">
                    <v-col align-self="start" class="pa-0" cols="12">
                      <v-avatar class="profile" color="grey" size="164" tile>
                        <v-img :src="images.avatar || $store.state.urlapi+'/serverimg/'+datauser.Imagen"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col class="py-0">
                      <v-list-item color="rgba(0, 0, 0, .4)" dark>
                        <v-list-item-content>
                          <v-list-item-title class="title">
                            {{datauser.nombre}}
                          </v-list-item-title>
                          <v-list-item-subtitle>{{datauser.login}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-img>
              </v-card>
            <v-card class="pa-5">  
                  <v-file-input
                    label="Perfil"
                    filled
                    prepend-icon="mdi-camera"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="onFileChange"
                  ></v-file-input>
        
                  <v-file-input       
                    label="Banner.."
                    filled
                    prepend-icon="mdi-image-area"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="fileBanner"
                  ></v-file-input>
                      
            </v-card>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="text-white green" @click="subirImagenes">Realizado</v-btn>
              <v-btn color="text-white red" @click="dialog=false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>       
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from 'vuex'
import Swal from 'sweetalert2'

export default {
    name:'Sidebar',
    data() {
      return { 
        dialog:false,
        token:this.$store.getters.getToken,   
        itemsmenu:[],
        imgavatar:false,
        imgbanner:false,
        datauser:'',
        images:{
          avatar:'',
          banner:''
        }
      }
    },
   created() {
     this.getMenuItems()
   },
   computed: {
     ...mapGetters(["getCurrentUser"])
   },
    methods:{ 
    
      getMenuItems(){
        this.datauser = this.$store.getters.getCurrentUser
        //modificar consulta para que solo devuelva los menus del usuario actual /userspermisos/:IdUsuario
        axios.get(`${this.$store.state.urlapi}/userspermisos/${this.datauser.IdUsuario}`,{headers:{'auth-user':this.token}})
            .then(response=>{
                this.itemsmenu = response.data;
            }).catch(error=>{
                console.log(error)
            })
      },  
       menu(path){
         const rutaActual = this.$route.path;
          //Validamos que se pidan rutas diferentes
         if(rutaActual !==path){
           let pathRG = path.indexOf('rg')
           let pathRP = path.indexOf('rp')
           if(pathRG>0){this.$store.dispatch("getItems",1)}
           if(pathRP>0){this.$store.dispatch("getItems",2)}
           this.$router.push(path)
           }
       },
       logout(){
         
        //this.$store.state.auth = false
        this.$store.commit("setCurrentUser",false)
        this.$store.commit("setTokenUser",'')
        this.$router.push('/')
      },
      onFileChange(e) {      
         if(e){
    
           this.imgavatar = e
           this.images.avatar = URL.createObjectURL(e);
         }else{
           this.images.avatar = this.$store.state.urlapi+'/serverimg/noUser.jpg'
           this.imgavatar = false
         }
       },
       fileBanner(e) { 
         if(e){
           this.imgbanner = e
           this.images.banner = URL.createObjectURL(e);
         }else{
           this.images.banner = this.$store.state.urlapi+'/serverimg/noBanner.jpg'
           this.imgbanner = false
         }
       },
       subirImagenes(){
         //action == 1 subir banner
        const formData = new FormData()
         this.imgavatar?formData.append('imagen',this.imgavatar):''
         this.imgbanner?formData.append('banner',this.imgbanner):'' 
         
        axios.post(`${this.$store.state.urlapi}/users/subirimg/${this.datauser.IdUsuario}`,formData).then(res=>{        
           this.dialog = false
           this.datauser.Banner = res.data.Banner
           this.datauser.Imagen = res.data.Imagen
           this.doneSwal('Imagenes Guardadas')
         }).catch(error=>{console.log(error,this.errorSwal('Error al Subir las Imagenes'),this.dialog = false)})
        
       },
        //Notificaciones Swal
      doneSwal(message){
        Swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      },
      errorSwal(message){
        Swal.fire({
          icon: 'error',
          title: message,        
        })
      }
    
    }
}

</script>
<style lang="css" scoped>
.app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
  .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>