import currency from '@/libs/currency.js'
//import moment from 'moment'
import * as dayjs from "dayjs";


async function generateXML(emisor,cliente,productos,totalFactura) {
    const felhead = await headFel(emisor,cliente)
    const felbody = await bodyFel(productos)
    const felfooter = await footerFel(totalFactura)

 const facturafel = felhead+felbody+felfooter
return  facturafel
}

async function headFel(emisor,cliente){
   
    let headxml=`<?xml version='1.0' encoding='UTF-8'?>
<dte:GTDocumento xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns:dte="http://www.sat.gob.gt/dte/fel/0.2.0" Version="0.1">
    <dte:SAT ClaseDocumento="dte">
        <dte:DTE ID="DatosCertificados">
            <dte:DatosEmision ID="DatosEmision">
                <dte:DatosGenerales Tipo="FPEQ" FechaHoraEmision="${dayjs().format()}"
                    CodigoMoneda="GTQ" />
                <dte:Emisor NITEmisor="${emisor.nit}" NombreEmisor="${emisor.propietario}" CodigoEstablecimiento="${emisor.codigo}"
                    NombreComercial="${emisor.nombrecomercial}" AfiliacionIVA="${emisor.codregimen}">
                    <dte:DireccionEmisor>
                        <dte:Direccion>${emisor.direccion}</dte:Direccion>
                        <dte:CodigoPostal>${emisor.codigopostal}</dte:CodigoPostal>
                        <dte:Municipio>${emisor.municipio}</dte:Municipio>
                        <dte:Departamento>${emisor.departamento}</dte:Departamento>
                        <dte:Pais>GT</dte:Pais>
                    </dte:DireccionEmisor>
                </dte:Emisor>
                <dte:Receptor NombreReceptor="${cliente.Nombre}" IDReceptor="${cliente.Nit}">
                    <dte:DireccionReceptor>
                        <dte:Direccion>${cliente.Direccion}</dte:Direccion>
                        <dte:CodigoPostal>2101</dte:CodigoPostal>
                        <dte:Municipio>JALAPA</dte:Municipio>
                        <dte:Departamento>JALAPA</dte:Departamento>
                        <dte:Pais>GT</dte:Pais>
                    </dte:DireccionReceptor>
                </dte:Receptor>
                <dte:Frases>
                    <dte:Frase TipoFrase="3" CodigoEscenario="1"/>
                </dte:Frases>
                <dte:Items>`
    return headxml
}

async function bodyFel(servicio){
    let items ='';
    for (let index = 0; index < servicio.length; index++) {
        let total = parseFloat(servicio[index].precio)*parseFloat(servicio[index].cantidad);
        items = items+`
        <dte:Item NumeroLinea="${index+1}" BienOServicio="S">
            <dte:Cantidad>${servicio[index].cantidad}</dte:Cantidad>
            <dte:UnidadMedida>CA</dte:UnidadMedida>
            <dte:Descripcion>${servicio[index].descripcion}</dte:Descripcion>
            <dte:PrecioUnitario>${servicio[index].precio}</dte:PrecioUnitario>
            <dte:Precio>${total}</dte:Precio>
            <dte:Descuento>0</dte:Descuento>
            <dte:Total>${currency(total,{ precision: 5 })}</dte:Total>
        </dte:Item>
    ` 
    
     }
     return items
}

async function footerFel(totalFactura) {
  
    let felfooter=`
                </dte:Items>
                <dte:Totales>
                    <dte:GranTotal>${currency(totalFactura,{ precision: 5 })}</dte:GranTotal>
                </dte:Totales>
            </dte:DatosEmision>
        </dte:DTE>
        <dte:Adenda>
            <dtecomm:Informacion_COMERCIAL xmlns:dtecomm="https://www.digifact.com.gt/dtecomm" xsi:schemaLocation="https://www.digifact.com.gt/dtecomm">
            <dtecomm:InformacionAdicional Version="7.1234654163">
                <dtecomm:REFERENCIA_INTERNA>${Date.now()}</dtecomm:REFERENCIA_INTERNA>
                <dtecomm:FECHA_REFERENCIA>${dayjs().format()}</dtecomm:FECHA_REFERENCIA>
                <dtecomm:VALIDAR_REFERENCIA_INTERNA>VALIDAR</dtecomm:VALIDAR_REFERENCIA_INTERNA>
            </dtecomm:InformacionAdicional>
            </dtecomm:Informacion_COMERCIAL>
            </dte:Adenda>   
    </dte:SAT>
</dte:GTDocumento>
`
return felfooter
}

export default generateXML