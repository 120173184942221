<template>
  <div>
    <div class="tile">
      <div class="tile-title-w-btn">
        <h3 class="title">{{title}}</h3>
        
      </div>
      <div class="tile-body">
        <form class="row informacion">
           <div class="form-group col-md-12">
             <v-text-field v-model="getContribuyente.Nombre" hint="Ejemplo: Andre Gomez " color="teal" label="Nombre" readonly></v-text-field>
           </div>
           <div class="form-group col-md-12">
             <v-text-field v-model="getContribuyente.Nit" hint="Ejemplo: 10054875 " color="teal" label="Nit" readonly></v-text-field>
           </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'EditarContribuyente',
    data(){
      return{
          title:'INFORMACIÓN',
          showform:false,
          editedIndex: -1,
          editedItem: {
              Nombre:'FADSAFDSAFDASF',
              Nit:'FASDFASDFAFDSA',
              Telefono:'1432143214231'
          }
      }
    },
    computed:{
      getContribuyente(){
        return this.$store.getters.getContribuyente
      }
    }
}
</script>
<style>
form .col-md-12{
  line-height:-5px;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>