<template>
    <div>
      <main class="app-content" justify="center">        
        <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <v-row class="text-center">
                <v-col>
                <v-card class="title pa-2" elevation="2">            
                  <v-row class="pt-3">
                    <v-col sm="10">
                      <v-text-field label="Nit | Establecimiento" outlined color="teal" dense
                      :value="establecimiento"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2">
                      <v-btn active-class="red" color="teal" dark rounded  elevation="2" @click="printPDF($store.getters.getCompra.IdCompra)"><v-icon color="white">mdi-printer</v-icon></v-btn>
                      <v-btn active-class="red" color="light-blue darken-3" dark rounded  elevation="2" @click="getTopProveedores($store.getters.getCompra)"><v-icon color="white">mdi-clipboard-text-outline</v-icon></v-btn>
                      <v-btn color="error" dark rounded elevation="2" to="/rg/compras"><v-icon color="white">mdi-close-circle</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pa-0">                   
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Folio" outlined color="teal" dense
                      :value="getCompraSeleccionada.Folio+' - '+getCompraSeleccionada.FinFolio"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Periodo" outlined color="teal" dense
                      :value="periodo"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Total con Iva" outlined color="teal" dense
                      :value="getCompraSeleccionada.Total"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Impuesto (IVA)" outlined color="teal" dense
                      :value="getCompraSeleccionada.IVA"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                    </v-col>
                  </v-row>
                  <v-chip class="ma-2" color="teal" small dark>
                  Ventas sin Iva Q {{ventaSeleccionada.Total}}
                  </v-chip>
                  <v-chip class="ma-2" color="teal" small dark>
                  Ventas Iva Q {{ventaSeleccionada.IVA}}
                  </v-chip>
                  <v-chip class="ma-2" color="blue" dark>
                 <span> Resta IVA Q {{ventaSeleccionada.IVA - getCompraSeleccionada.IVA | formatoMoneda}}</span>
                  </v-chip>
                </v-card>
                </v-col>
              </v-row>
              <!-- FORMULARIO INGRESO FACTURAS-->
              <v-row class="pt-16" justify="center">
                <v-card class="title pa-2" elevation="1" color="#17a2b8">
                    <form>
                  <v-row class="pt-3" justify="center" no-gutters>
                    <v-col sm="2" class="pa-1">
                      <input type="date" v-model="editedItem.FechaFactura" class="form-control unstyled" ref="fechafactura" name="fechafactura" @keyup.enter="nextFocus" autofocus/>                    
                    </v-col>
                    <v-col sm="1" class="pa-1">
                      <v-text-field label="No. Factura" color="black" dense solo
                      v-model="editedItem.Serie"
                      placeholder="Serie Factura"
                      hint="Serie Factura"                     
                      persistent-hint
                      ref="serie" name="serie"
                      @keyup.enter="nextFocus"
                      @focus="validarFechaFactura"                                     
                      ></v-text-field>
                    </v-col>
                    <v-col sm="1" class="pa-1">
                      <v-text-field label="No. Factura" color="black" dense solo
                      v-model="editedItem.NumeroFactura"
                      placeholder="No. Factura"
                      hint="Numero Factura" 
                      persistent-hint
                      ref="numfc"   name="numfc"
                      @keyup.enter="nextFocus"              
                      ></v-text-field>
                    </v-col>
                    <v-col sm="1" class="pa-1">
                      <v-text-field label="Nit" dense solo
                      color="red"
                      v-model="Nit"
                      hint="Nit Proveedor"
                      persistent-hint
                      placeholder="Nit" 
                      ref="nit" name="nit"   
                      @keyup.enter="nextFocus"            
                      @change="getProveedor"
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Proveedor" color="black" dense solo  
                      v-model="editedItem.Proveedor"                                      
                      hint="Nombre Proveedor"
                      persistent-hint
                      ref="proveedor" name="proveedor"
                      @keyup.enter="nextFocus"
                      placeholder="Proveedor"                   
                      ></v-text-field>
                    </v-col>
                    <v-col sm="1" class="pl-1 pt-1 pr-1">
                      <v-text-field label="Total" color="black" dense solo
                      v-model="editedItem.total"
                      hint="Total Factura"
                      persistent-hint
                      ref="totalfc" name="totalfc"
                      @keyup.enter="nextFocus"
                      placeholder="Total"             
                      ></v-text-field>
                    </v-col>
                    <v-col sm="1" class="pt-1">  
                       <v-text-field label="Combustible" color="black" dense solo
                      v-model="editedItem.Servicio"
                      hint="Total Combustible"
                      persistent-hint
                      ref="totalcombustible" name="totalcombustible"
                      @keyup.enter="nextFocus"
                      placeholder="Combustible"
                      ></v-text-field>                    
                    </v-col>
                    
                    <v-col sm="1" class="pa-1">
                      <v-btn type="button" depressed class="white--text" color="light-green" elevation="2" @click="save">
                      <v-icon color="white" v-show="editedIndex<0">mdi-sticker-plus</v-icon>       
                      <v-icon color="white" v-show="editedIndex>=0">mdi-clipboard-edit</v-icon>       
                      </v-btn>
                    </v-col>
                  </v-row> 
                    </form>
                </v-card>
              </v-row>
              <!-- TABLA DETALLE FACTURAS  -->
              <v-row>
                <v-col cols="12">
                        <v-card>
                            <v-card-title>
                              <v-btn color="blue" class="text-white" 
                              @click="procesarDTE">
                              <v-icon left color="white">mdi-file-upload</v-icon>Cargar DTE COMPRAS
                              </v-btn>

                              <v-divider class="mx-4" inset vertical></v-divider>
                              <v-btn color="red" class="text-white" @click="deleteAllItems">
                              <v-icon left color="white">mdi-delete-circle</v-icon>Vaciar
                              </v-btn>
                              <v-divider class="mx-4" inset vertical></v-divider>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                color="teal"
                                single-line
                                hide-details
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :headers="headers"
                            :items="dataitems"
                            :items-per-page="10"
                            :search="search"
                            color="teal"
                            class="elevation-0"
                            calculate-widths
                            dense
                            >                                             
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <v-btn icon @click="editItem(item)"><v-icon color="blue">mdi-file-document-edit</v-icon></v-btn>
                                    <v-btn icon @click="eliminarSwal(item)"><v-icon color="red">mdi-file-remove</v-icon></v-btn>
                                </div>       
                            </template>
                            <template v-slot:no-data>
                              <h4>No se encontrarón Facturas de compra</h4>
                             
                            </template>
                            </v-data-table>
                        </v-card>
                      </v-col>
              </v-row>
            </div>
          </div>
        </div>
       
      </main>
      <!-- MODAL AGREGAR CLIENTE -->
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
     
      <v-card>
        <v-card-title class="headline teal text-white">
          Agregar Proveedor
        </v-card-title>

        <v-card-text>
          <br><br>
           <v-text-field v-model="Nit" label="Nit" color="teal" outlined dense required autofocus></v-text-field>
           <v-text-field v-model="proveedor.Proveedor" label="Nombre Proveedor" color="teal" outlined dense required></v-text-field>
           <v-text-field v-model="proveedor.Tipo" label="Tipo" color="teal" outlined dense required disabled></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" class="btn btn-primary text-white ma-2" @click="guardarProveedor"><v-icon color="white">mdi-check-circle</v-icon> AGREGAR</button>         
          <button type="button" class="btn btn-secondary text-white" @click="dialog = false"><v-icon color="white">mdi-close-circle</v-icon> CANCELAR</button>         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- Modal Proveedores-->
  <div class="text-center">
    <v-dialog v-model="dialog2" width="70%">
      <v-card>
        <v-card-title class="text-white teal">
          Listado de Proveedores
        </v-card-title>
        
        <v-card-text>
          <v-divider></v-divider>
            <v-chip-group
            label
            active-class="red--text text--accent-4"
            mandatory
          >
            <v-chip>Facturas recibidas: <h4> {{getTotalFacRecibidas}} </h4> </v-chip>
            <v-chip>Total Proveedores del mes: <h4> {{getTopProveedoresCompras.length}} </h4></v-chip>
            <v-chip>Total Combustible Sin Iva:<h4> Q{{getSumaFacCombustible}}</h4> </v-chip>
          </v-chip-group>
        <v-divider></v-divider>
          <v-data-table
            :headers="headers2"
            :items="getTopProveedoresCompras"
            :items-per-page="10"
            dense
            class="elevation-1"
          ></v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey text-white" @click="dialog2 = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

    </div>
    
</template>
<script>
import axios from 'axios'
import * as dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import currency from '@/libs/currency'
import Swal from 'sweetalert2'
import {mapGetters} from 'vuex'

export default {
  name:'FacturasComprasRG',
  data() {
    return {
      urlapi:this.$store.getters.getUrlApi,  
      dataitems:[],
      dialog:false,
      dialog2:false,
      search: '',
      Nit:'',
      token:this.$store.getters.getToken,
      procesando:false,
      headers2:[
        { text: 'Nit', value: 'Nit'},
        { text: 'Proveedor', value: 'Proveedor'},
        { text: 'Cantidad Facturas', value: 'CantidadFacturas'},
        { text: 'Total Sin Iva', value: 'TotalSinIva'},
        { text: 'Iva', value: 'TotalIVA'},
      ],
      headers: [
            {text: 'Fecha:', align: 'center',value: 'FechaFactura'},
            { text: 'Serie', value: 'Serie' },
            { text: 'No. Fac:', value: 'NumeroFactura' },
            { text: 'Nit', value: 'Nit' },
            { text: 'Proveedor', value: 'Proveedor' },
            { text: 'Total Factura', value: 'Total' },
            { text: 'Combustible', value: 'Servicio' },
            { text: 'Total sin IVA', value: 'TotalSinIVA' },
            { text: 'Iva', value: 'IVA' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ], 
     editedIndex: -1,
     editedItem:{
        IdLibroCompra:0,
        FechaFactura:dayjs(this.$store.getters.getCompra.Periodo,'YYYY-MM-DD').format('YYYY-MM-DD'),
        NumeroFactura:'',
        Serie:'',
        IdProveedor:0,
        Proveedor:'',
        TotalSinIVA:0,
        IVA:0,
        total:0,
        Servicio:0,
      },
      defaultItem:{
        IdLibroCompra:0,
        NumeroFactura:'',
        Serie:'',
        IdProveedor:0,
        Proveedor:'',
        TotalSinIVA:0,
        IVA:0,
        total:0,
        Servicio:0,
      },
     proveedor:{
       Proveedor:'',
       Tipo:'Proveedor'
     },
     headeraxios:{
       headers:{'auth-user':this.$store.getters.getToken}
     }    
    }
  },
  computed: {  
    ...mapGetters(["getTopProveedoresCompras","getTotalFacRecibidas","getSumaFacCombustible"]),

    periodo(){
      return dayjs(this.$store.getters.getCompra.Periodo,'YYYY-MM-D').add(1, 'day').format('MMMM YYYY').toUpperCase()
    },
    establecimiento(){
      return (this.$store.getters.getCompra.Nit+" | "+this.$store.getters.getCompra.Establecimiento).toUpperCase()
    },
    ventaSeleccionada(){
      return this.$store.getters.getVentaSeleccionada;
    },
    getCompraSeleccionada(){
      return this.$store.getters.getCompra;
    } 
  },
  created() {
    this.init()
  }, 
  methods: {
    init(){
      const IdCompra = this.$store.getters.getCompra.IdCompra
      axios.get(`${this.urlapi}/facturascompras/${IdCompra}`,this.headeraxios).then(res=>{
        this.dataitems = res.data       
      }).catch(()=>{})
    },
    //Metodo para obtener el Nombre y el Id del Proveedor
    getProveedor(){
      const persona=this.$store.getters.buscarProveedor(this.Nit)

      if(persona){
        this.editedItem.Proveedor = persona.Proveedor
        this.editedItem.IdProveedor = persona.IdProveedor
        this.validarFactura()
      }else{
        axios.get(`${this.urlapi}/personas/${this.Nit}`,this.headeraxios).then(res=>{          
          if(res.data.length>0){
            this.editedItem.Proveedor = res.data[0].Proveedor
            this.editedItem.IdProveedor = res.data[0].IdProveedor
          }else{
           
            Swal.fire({text:'No se encontró el Proveedor presione Ok Para agregar!'}).then(res=>{
              res.isConfirmed?this.dialog=true:this.Nit=''             
            })            
          }

        }).catch(err=>{console.log(err)})       
      }
    },
    editItem(item){
      this.Nit = item.Nit
      this.editedIndex = this.dataitems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      let fecha = item.FechaFactura.split("-");
      this.editedItem.FechaFactura= `${fecha[2]}-${fecha[1]}-${fecha[0]}`
      this.$refs.fechafactura.focus(); 
    }, 
    save(){
      this.procesando=true
      if (this.editedIndex <0) {
            this.guardarRegistro(); 
            } else {
             this.editarRegistro();
          }       
    },
    //Metodo INSERTAR Factura Compra
    guardarRegistro(){
      this.editedItem.IdLibroCompra = this.$store.getters.getCompra.IdCompra
      axios.post(`${this.urlapi}/facturascompras`,this.editedItem,this.headeraxios).then(()=>{
        
        this.init()
        this.limpiar()
      }).catch(err=>{
        console.error(err)
      })
    },
    //Metodo editar Factura Venta 
    editarRegistro(){
      this.editedItem.IdLibroCompra = this.$store.getters.getCompra.IdCompra   
      axios.put(`${this.urlapi}/facturascompras/${this.editedItem.IdfacCompra}`,this.editedItem,this.headeraxios).then(()=>{
        this.exitoSwal('Factura Modificada','')
        this.init()
        this.limpiar()
      }).catch(err=>{
        this.errorSwal('Error al Actualizar la Factura','')
        console.error(err)
      })
    },
    guardarProveedor(){      
      this.proveedor.Nit = this.Nit
      axios.post(`${this.urlapi}/personas`,this.proveedor,this.headeraxios).then(()=>{
        this.editedItem.Proveedor=this.proveedor.Proveedor
        //Traemos listado de los proveedor 
        this.proveedor.Proveedor=''
        this.$store.dispatch("setTopProveedores")
        this.dialog=false
        this.exitoSwal('Proveedor Agregado','')
        this.getProveedor();
      }).catch(err=>{
        this.errorSwal('Error al guardar proveedor','')
        console.error(err)
      })
    },   
    eliminarFactura(item){
      const total_actual= currency(this.$store.getters.getCompra.Total,2).value
      const nuevototal = total_actual-(parseFloat(item.total))
      
      axios.delete(`${this.urlapi}/facturascompras/${item.IdfacCompra}`,this.headeraxios).then(()=>{
        this.init()
        this.$store.dispatch("setTotalCompra",nuevototal)
        this.exitoSwal('Factura Eliminada','')
      }).catch(err=>{console.log(err),this.errorSwal('Error al Eliminar la Factura','')})
    },
    deleteAllItems(){
      Swal.fire({
        title: `¿DeseaEliminar Todas las Facturas?`,
        text: "¡Está acción no podra revertirse, presione Estoy Seguro para eliminarlas!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Estoy Seguro!',
        cancelButtonText: 'No, Cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          let IdLibroCompra = this.$store.getters.getCompra.IdCompra
          //Relizamos la petición para eliminar las Facturas
          axios.delete(`${this.urlapi}/facturascompras/allitems/${IdLibroCompra}`,this.headeraxios).then(()=>{
            this.init()
            this.$store.dispatch("setTotalCompra",0.00)
            this.exitoSwal('Facturas Eliminadas','')
          }).catch(err=>{console.log(err),this.errorSwal('Error al Eliminar la Factura','')})
        }
      })

        
    },
    //Validaciones
    validarFechaFactura(){
               //Validar que la fecha de la factura este dentro del trimestre  
       //VALIDAR QUE LAS FECHAS SEAN VALIDAS 
       dayjs.extend(isBetween)
        const periodo = dayjs(this.$store.getters.getCompra.Periodo).add(1,'day')
        //PERIODO: 2020-10-01T04:0

        // A periodo Le quitamos un dia para que el rango este entre el ultimo dia del mes anterior 
        //Por ejempo periodo =  01/04/2019 quitandole un dia quedaria 31/03/2019
        let FechaInicio = dayjs(periodo).subtract(3, 'months').endOf('months').format('YYYY-MM-DD');
        //Al ultimo día del periodo le sumamos un dia más 31/03/2019 y 01/05/2019
        const FechaFin = periodo.add(1,"month").format('YYYY-MM-DD') //.add(1,"day").format('YYYY-MM-DD');
        const FechaActual=dayjs(this.editedItem.FechaFactura).format('YYYY-MM-DD')   
        let FechaValida = dayjs(FechaActual).isBetween(FechaInicio,FechaFin);      
        if(!FechaValida){
          this.$refs.fechafactura.focus();      
          this.errorSwal("La Fecha es invalida 😦", "Verifique la fecha de la factura");
        }
    },
    //Validar que la factura no haya sido ingresada previamente
    validarFactura(){     
       axios.get(`${this.urlapi}/facturascompras/buscarfactura/${this.editedItem.NumeroFactura}/${this.editedItem.Serie}/${this.editedItem.IdProveedor}`,this.headeraxios).then(res=>{        
         if(res.data.length>0){
           console.log(res.data[0])
           this.Nit='', this.editedItem.Proveedor='',this.editedItem.IdProveedor=0
           this.$refs.fechafactura.focus(); 
          
           const periodo = dayjs(res.data[0].Periodo)
           let establecimiento = this.$store.getters.getItems.filter(elemento=>elemento.IdEstablecimiento==res.data[0].IdEstablecimiento)
           this.mensajeFacturaEncontrada(periodo,establecimiento)
         }
        }).catch(err=>{console.log(err)})
    }, 
    mensajeFacturaEncontrada(periodo,establecimiento){
        this.errorSwal('Factura ya Ingresada ☹️','Factura ingresada en el periodo de '+dayjs(periodo).format('MMMM YYYY').toUpperCase()+' en el negocio '+establecimiento[0].Establecimiento)
    },
     procesarDTE(){
      this.$store.dispatch("setTipo",'Compras')
      this.$router.push('/procesarfel')
      }, 
      //Focus
      nextFocus(e){
      let valor = e.target.name
      let valorvacio = e.target.value  
      //Validamos que los campos no esten vacios
      if(valorvacio.length>0){
        if(valor=='fechafactura')this.$refs.serie.focus();
        if(valor=='serie') this.$refs.numfc.focus();
        if(valor=='numfc') this.$refs.nit.focus();
        if(valor=='nit') this.$refs.proveedor.focus();
        if(valor=='proveedor')this.$refs.totalfc.focus();
        if(valor=='totalfc')this.$refs.totalcombustible.focus();
        if(valor=='totalcombustible' && !this.procesando)this.save()
      }else{
        this.errorSwal('¡Campo Vacio! 😦','El campo no puede estar vacio')
      }  
    },         
    //Limpiar Campos
    limpiar(){
            this.procesando=false
            this.init()
           const total_actual= currency(this.$store.getters.getCompra.Total?this.$store.getters.getCompra.Total:0,2).value
          if (this.editedIndex <0) {
            //Al Crear Item
              const nuevototal=total_actual+parseFloat(this.editedItem.total)
              this.editedItem.NumeroFactura=''  
              this.$store.dispatch("setTotalCompra",nuevototal)
            } else {
              const itemfactura = this.dataitems.find(elemento=>elemento.IdfacCompra==this.editedItem.IdfacCompra)
              const nuevototal = (total_actual-parseFloat(itemfactura.total) )+parseFloat(this.editedItem.total)
              this.$store.dispatch("setTotalCompra",nuevototal)
          }
              this.Nit=''
              this.defaultItem.FechaFactura = this.editedItem.FechaFactura
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
              this.$refs.fechafactura.focus(); 
              
    },
    //Top Proveedores
    getTopProveedores(item){
         this.$store.dispatch("setTopProveedoresCompras",item);
         this.dialog2=true;
    },
    //Mesajes Sweet Alert
    errorSwal(titulo,texto){
      Swal.fire({
        title:titulo,
        text:texto,
        icon:'error'
      })
    },
    exitoSwal(titulo,texto){
      Swal.fire({
        icon: 'success',
        title: titulo,
        text:texto,
        showConfirmButton: false,
        timer: 1500
      })
    },
    eliminarSwal(item){
      Swal.fire({
        title: `Eliminar Factura?`,
        text: "Esta seguro de eliminar esta factura!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarFactura(item)
        }
      })
    },
    printPDF(idcompra){
        window.open("/reportes/reportecompras.php?id="+idcompra,"blank")
      },
  },
  filters:{
    formatoMoneda: function (value) {
        if (!value) return ''
        return currency(value,{ precision: 2 })
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans&display=swap');
.title{
  margin-top: -50px;
  line-height: 10px;
}
a{
text-decoration: none;
}
.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
       display: none;
       -webkit-appearance: none;
}
</style>





