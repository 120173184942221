import currency from '@/libs/currency';
import store from '@/store/index'
import axios from "axios"

export function ProcesarXML(archivoxml,estado,IdLibroCompra){
 
  const Estado = estado;
       let patharchivoXML = (window.URL || window.webkitURL).createObjectURL(archivoxml);
   
       let xhttp = new XMLHttpRequest();
       xhttp.onreadystatechange = async function() {
           if (xhttp.readyState == 4 && xhttp.status == 200) {
             // Validamos el estado
             if(Estado==='Recibida'){
               //Procesamos facturas Emitidas
               await procesarItemEmitidos(xhttp)
             }
           }
       };
       //FACTURA COMBUSTIBLE 
       xhttp.open("GET",patharchivoXML, true);
       xhttp.send();

       //Función para procesar los xml
       async function procesarItemEmitidos(xml){
           let xmlDoc = xml.responseXML;

           let dteReceptor = xmlDoc.getElementsByTagName("dte:Emisor")[0];
           let Persona = dteReceptor.getAttributeNode("NombreEmisor").nodeValue;
           const Nit = dteReceptor.getAttributeNode("NITEmisor").nodeValue;

           let dteFac = xmlDoc.getElementsByTagName("dte:NumeroAutorizacion")[0];
           const Serie = dteFac.getAttributeNode("Serie").nodeValue;
           const NumeroFactura = dteFac.getAttributeNode("Numero").nodeValue;
       
           const IdProveedor = await getProveedor(Nit,Persona);
           //Obtenemos Fecha de Emision
           let datosgenerales=xmlDoc.getElementsByTagName("dte:DatosGenerales")[0];
           const FechaFactura=datosgenerales.getAttributeNode("FechaHoraEmision").nodeValue;

 
            let FelGenerado={
                IdLibroCompra:IdLibroCompra,
                FechaFactura:FechaFactura,
                NumeroFactura:NumeroFactura,
                Serie:Serie,
                IdProveedor:IdProveedor,
                Proveedor:Persona,
                Nit:Nit,
                TotalSinIVA:0,
                IVA:0,
                total:0,
                Servicio:0,
            }
            let impuestosFactura = xmlDoc.getElementsByTagName("dte:TotalImpuesto");
            
            if(impuestosFactura.length>1){
              let TotalPetroleo = 0;
              
              for (let i = 0; i < impuestosFactura.length; i++) { 
                if(impuestosFactura[i].getAttribute('NombreCorto')=='PETROLEO'){
                  TotalPetroleo = currency((xmlDoc.getElementsByTagName("dte:TotalImpuesto")[i].getAttribute("TotalMontoImpuesto")),2).value;
                }
              }
              
              
              let TotalFactura = currency((xmlDoc.getElementsByTagName("dte:GranTotal")[0].childNodes[0].nodeValue),2).value;
              
              FelGenerado.Servicio=TotalFactura-TotalPetroleo;

              FelGenerado.TotalSinIVA=currency((FelGenerado.Servicio/1.12),2).value;
              FelGenerado.IVA = currency((FelGenerado.Servicio/1.12)*0.12,2).value;
              
            }else{
                FelGenerado.total=currency((xmlDoc.getElementsByTagName("dte:GranTotal")[0].childNodes[0].nodeValue),2).value;
                FelGenerado.TotalSinIVA=currency((FelGenerado.total/1.12),2).value;
                FelGenerado.IVA = currency((FelGenerado.total/1.12)*0.12,2).value;
            }    
            
                                  
        store.dispatch("setFacturas",FelGenerado)      
       }

       //funcion para buscar el nuevo proveedor
      async function getProveedor(nit,proveedor){
        const persona=store.getters.buscarProveedor(nit)
        let idpersona = 0;
        let headeraxios={
          headers:{'auth-user':store.getters.getToken}
        }
        if(persona){
          idpersona = persona.IdProveedor
        }else{
          await axios.get(`${store.getters.getUrlApi}/personas/${nit}`,headeraxios).then(res=>{        
            if(res.data.length>0){
              idpersona = res.data[0].IdProveedor
            }else{ idpersona = false}
          }).catch(err=>{console.log(err)})       
        }

        if(!idpersona){
         await axios.post(`${store.getters.getUrlApi}/personas`,{Nit:nit,Proveedor:proveedor,Tipo:'Proveedor'},headeraxios).then(res=>{
            //Traemos listado de los proveedor
            idpersona = res.data[0].insertId
            store.dispatch("setTopProveedores")
          }).catch(err=>{
            console.error(err)
          })
        }
        return idpersona
      }
      
}

