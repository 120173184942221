import currency from '@/libs/currency'
import axios from "axios"
import * as dayjs from 'dayjs'

export default {
    state:{
        idcompra:false,
        topProveedores:[]
    },
    mutations:{
        setIdCompra(state,item){
            state.idcompra=item
        },
        setTotalCompra(state,totalfactura){
            state.idcompra.Total = totalfactura
            state.idcompra.IVA = currency((totalfactura/1.12)*0.12,2).value
        },
        setTopProveedoresCompras(state,items){
            state.topProveedores = items
        }
    },
    actions:{
        setCompra({commit},item){
            commit("setIdCompra",item)
        },
        setTotalCompra({commit},totalfactura){
            commit("setTotalCompra",totalfactura)
        },
        setTopProveedoresCompras({commit,rootState},item){
            const token = rootState.Usuario.token
            const periodo = dayjs(item.Periodo).add(1,'day').format('YYYY-MM-DD')
        
            axios.get(`${rootState.urlapi}/compras/topProveedores/${item.IdPersona}/${periodo}`,{headers:{'auth-user':token}}).then(res=>{        
                commit("setTopProveedoresCompras",res.data)
              })
        }
    },
    getters:{
        getCompra(state){
            return state.idcompra
        },
        getTopProveedoresCompras(state){
            return state.topProveedores
        },
        getTotalFacRecibidas(state){
            if(state.topProveedores.length>0){
                return state.topProveedores.reduce((acumulado,item)=>acumulado+parseInt(item.CantidadFacturas),0)
            }else{
                return 0
            }
        },
        getSumaFacCombustible(state){
            if(state.topProveedores.length>0){
                const totalcombustible =state.topProveedores.reduce((acumulado,item)=>acumulado+parseFloat(item.Combustible),0)
                return currency(totalcombustible,2).value
            }else{
                return 0
            }
        }
    }
}