import axios from "axios";

export default {
  state: {
    contribuyentes:[]
  },

  mutations: {
    setContribuyentes(state,items){
        state.contribuyentes = items
    }
  },

  actions:{
    async setContribuyentes({commit,rootState,getters}){  
        try {
           const {data} = await axios.get(`${rootState.urlapi}/contribuyentes/`,{headers:{'auth-user':getters.getToken}})
            commit("setContribuyentes",data)  
        } catch (error) {
            console.log(error)
        }
    }
  },

  getters:{
    listadoContribuyentes(state){
      return state.contribuyentes
    }
  },

}
