<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div>
          <h1><v-icon color="black">mdi-contacts</v-icon>Contribuyentes</h1>
          
          <p>Listado de todos los contribuyentes</p>
        </div>
        <ul class="app-breadcrumb breadcrumb">          
          <v-btn class="white--text" color="#009688" data-toggle="modal" data-target="#modalRegistro">
              <v-icon left>mdi-account-plus</v-icon>
              NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
              <!-- DATA TABLES-->
            <template>
            <v-card>
                <v-card-title>
                Contribuyentes
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    color="teal"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :items="dataitems"
                :search="search"
                >
                <template v-slot:[`item.Estado`]="{item}">
                    <span class="badge" :class="getcondicion(item.Estado)"> {{item.Estado}}</span>
                   
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                        <button class="btn btn-primary btn-sm" @click="perfil(item)"><v-icon class="text-white">mdi-badge-account-horizontal</v-icon></button>
                        <button class="btn btn-info btn-sm"  @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white">mdi-account-edit</v-icon></button>                      
                        <button class="btn btn-danger btn-sm" @click="deleteSwal(item)"  ><v-icon class="text-white">mdi-account-remove</v-icon></button>
                    </div>       
                </template>
                <template v-slot:no-data>
                    <button class="btn btn-primary text-white" type="button" @click="getItems">Cargar Información</button>
                </template>
                </v-data-table>
            </v-card>
            </template>
          </div>
        </div>
      </div>

<!-- Modal -->
<div class="modal fade" id="modalRegistro" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalRegistroLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalRegistroLabel">{{formTitle}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>     
      <div class="modal-body">        
          <span class="badge badge-light" v-show="editedIndex=== -1">1) Los campos marcados con <span>*</span> son OBLIGATORIOS.</span>        
          <span class="badge badge-light" v-show="editedIndex=== -1">2) Escriba primero los datos del Contribuyente y luego los del Establecimiento</span>
        <v-card>   
            <v-tabs
            v-model="tab"
            background-color="blue"
            centered
            dark
            icons-and-text
            v-show="editedIndex=== -1"
            >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" style="text-decoration:none">
                Contribuyente
                <v-icon>mdi-clipboard-account</v-icon>
            </v-tab>

            <v-tab href="#tab-2" style="text-decoration:none">
                Establecimiento
                <v-icon>mdi-city-variant-outline</v-icon>
            </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <v-card flat>
                <v-card-text>              
                        <div class="form-group">
                            <label>Nombre *</label>
                            <input type="text" class="form-control"  v-model="editedItem.Nombre"  placeholder="Nombre contribuyente" required>
                        </div>
                        <div class="form-group">
                            <label>Dirección *</label>
                            <input type="text" class="form-control"  v-model="editedItem.Direccion"  placeholder="Dirección contribuyente" required>
                        </div>
                        <div class="form-group">
                            <label>Nit *</label>
                            <input type="text" class="form-control" v-model="editedItem.Nit" placeholder="Numero de nit" required>
                        </div>
                        <div class="form-group">
                            <label>Telefono</label>
                            <input type="text" class="form-control" v-model="editedItem.Telefono" placeholder="Numero de teléfono">
                        </div>
                        <div class="form-group">
                            <label>Estado *</label>
                            <select class="form-control" v-model="editedItem.Estado" required>
                                <option value="Activo">Activo</option>
                                <option value="Inactivo">Inactivo</option>
                            </select>
                        </div>                       
                </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
                <v-card flat>
                <v-card-text>
                        <div class="form-group">
                            <label>Nombre Establecimiento *</label>
                            <input type="text" class="form-control" v-model="dataEstablecimiento.Establecimiento" placeholder="Nombre establecimiento" required>
                        </div>
                        <div class="form-group">
                            <label>Regimen Tributario *</label>
                            <select class="form-control" v-model="dataEstablecimiento.IdRegimen" required>
                                <option value="1">General (Normal)</option>
                                <option value="2">Pequeño Contribuyente</option>                               
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Observaciones</label>
                            <textarea class="form-control" id="Descripcion" rows="3"  v-model="dataEstablecimiento.Descripcion"></textarea>
                        </div>               
                </v-card-text>
                </v-card>
            </v-tab-item>
            </v-tabs-items>
        </v-card>    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary white--text" data-dismiss="modal" @click="close">
            <v-icon color="white">mdi-close-circle-outline</v-icon>
            Cancelar
        </button>
        <!--Botton Editar-->
        <button type="button" class="btn btn-primary white--text" data-dismiss="modal" v-show="inputvalidos || editedIndex>=0" @click="save">
            <v-icon color="white">mdi-check-circle</v-icon>
            {{editedIndex>=0?'Guardar Cambios':'Guardar'}}
        </button> 
      </div>
    </div>
  </div>
</div>
        <!--FIN MODAL -->
    
           
    </main>
    </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2'

export default {
    name:'Contribuyente',
    data() {
        return {
            tab: null,
            token:this.$store.getters.getToken,
            dataitems:[],
            editedIndex: -1,
            search: '',
            headers: [
            {
                text: 'Nit',
                align: 'start',
               // sortable: false,
                value: 'Nit',
            },
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Direccion', value: 'Direccion' },
            { text: 'No. Establecimientos', value: 'NumEstablecimientos' },
            { text: 'Estado', value: 'Estado' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],
            editedItem: {
            Nit:'',
            Nombre:'',
            Direccion:'',
            Telefono:'',
            Estado:'Activo'
        },
        defaultItem: {
          Nit:'',
          Nombre:'',
          Direccion:'',
          Telefono:'',
          Estado:'Activo'
        },
        dataEstablecimiento:{
            Establecimiento:'',
            Descripcion:'',
            Estado:'Activo',
            IdRegimen:1,
        }
       }
    },
    created() {
        this.getItems()
    },
    computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
        },
        inputvalidos(){
           
            if(this.editedItem.Nit.length>5 && this.editedItem.Nombre.length>5 && this.dataEstablecimiento.Establecimiento.length>3){
                return  true
            }
            return false
        }
    },
    methods: {
        getItems(){
            axios.get(`${this.$store.state.urlapi}/contribuyentes/`,{headers:{'auth-user':this.token}})
            .then(response=>{
                if(response.data.length>0){                   
                    this.dataitems = response.data;
                }
            }).catch(error=>{
                console.log(error)
            })        
        },
        perfil(item){
            this.$store.dispatch("setIdPersona",item)
            this.$router.push('/contribuyentes/perfil');
        },
        editItem (item) { 
          this.tab='tab-1'       
          this.editedIndex = this.dataitems.indexOf(item)       
          this.editedItem = Object.assign({}, item)
          
        },
        getcondicion (condicion) {
          if (condicion =='Inactivo') return 'badge-danger'
          else return 'badge-success'
        },
        guardarRegistro(){          
          axios.post(`${this.$store.state.urlapi}/contribuyentes/`,this.editedItem,{headers:{'auth-user':this.token}}).then(response=>{
            if(response.data.insertId>0){
                this.putEstablecimiento(response.data.insertId)             
            }else{
                this.errorSwal()
            }
            })
            
        },
        editarRegistro(){
          const id = this.editedItem.idpersona
            axios.put(`${this.$store.state.urlapi}/contribuyentes/${id}`,{Nit:this.editedItem.Nit,Nombre:this.editedItem.Nombre,Direccion:this.editedItem.Direccion,Telefono:this.editedItem.Telefono,Estado:this.editedItem.Estado},{headers:{'auth-user':this.token}}).then(()=>{
                this.getItems();
                this.exitoSwal()
            })
        },
        close () {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        eliminarItem(item){
                this.editItem (item);
                this.editedItem.Estado ='Eliminado';
                this.editarRegistro();
        },
        save () {
          if (this.editedIndex > -1) {
                this.editarRegistro();
          } else {
                this.guardarRegistro(); 
          }
          this.close()
        },
        putEstablecimiento(IdPersona){
            this.dataEstablecimiento.IdPersona = IdPersona
            axios.post(`${this.$store.state.urlapi}/establecimientos/`,this.dataEstablecimiento,{headers:{'auth-user':this.token}}).then(response=>{
                   if(response.status==201){
                       this.getItems();
                       this.exitoSwal()
                   }else{
                       this.errorSwal()
                   }
               }) 
        },
        //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        },
        deleteSwal(item){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(item);
            }
          })
       } 
      
    },
}
</script>

<style lang="css">
.progress-bar5{
    display: flex;
    margin:40px,0;
}

.progress-bar5{
    text-align: center;
    width: 300px;
}
</style>