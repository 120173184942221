

export default {
    state:{
        facturasFel:[],
        regimen:'',
        tipo:'',
    },
    mutations:{
        setFacturas(state,item){
            state.facturasFel.push(item)
        },
        deleteFacturas(state){
            state.facturasFel=[]
        },
        setRegimen(state,regimen){
            state.regimen=regimen
        },
        setTipo(state,item){
            state.tipo=item
        }
    },
    actions:{
        setFacturas({commit},item){
            commit("setFacturas",item)
        },
        deleteFacturas({commit}){
            commit("deleteFacturas")
        },
        setRegimen({commit},item){
            commit("setRegimen",item)
        },
        setTipo({commit},item){
            commit("setTipo",item)
        }
    },
    getters:{
        getFacturas(state){
            return state.facturasFel
        },
        getRegimen(state){
            return state.regimen
        },
        getTipo(state){
            return state.tipo
        },
    }
}