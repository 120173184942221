import currency from '@/libs/currency'

export default {
    state:{
        data:[],
        idventa:false,
        venta:'' //Esta venta sirve para saber la diferencia entre compras y ventas
    },
    mutations:{
        setVentas(state,data){
            state.data=data;
        },
        setVentasSeleccionada(state,data){
            state.venta=data;
        },
        setIdVenta(state,item){
            state.idventa=item
        },
        setTotalVenta(state,totalfactura){
            state.idventa.Total = currency(totalfactura,2).value
        },
        setTotalIva(state,iva){
            state.idventa.IVA = iva
        }
    },
    actions:{
        setVentas({commit},data){
            commit("setVentas",data)
        },
        setVentasSeleccionada({commit},data){
            commit("setVentasSeleccionada",data)
        },
        setIdVenta({commit},item){
            commit("setIdVenta",item)
        },
        setTotalVenta({commit},totalfactura){
            commit("setTotalVenta",totalfactura)
        },
        setTotalIva({commit},iva){
            commit("setTotalIva",iva)
        }
    },
    getters:{
        getIdVenta(state){
            return state.idventa
        },
        getIdRegimen(state){
            return state.idregimen
        },
        getVentas(state){
            return state.data;
        },
        getVentaSeleccionada(state){
            return state.venta;
        }
    }
}
