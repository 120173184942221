<template>
  <div>
     
    <main class="app-content"  id="lateral">
      <div class="row">
            <div class="col-md-6">
                <EditarContribuyente/>
             </div>
            <div class="col-md-6">
              <Contacto/>
               
            </div>
            <div class="col-md-12">
              <Claves/> 
            </div>
           
      </div>
    </main>
  </div>
</template>
<script>
import Contacto from './contacto/Contacto.vue'
import Claves from './claves/Claves.vue'
import EditarContribuyente from './contribuyente/editar.vue'
export default {
  components:{
    Contacto,
    Claves,
    EditarContribuyente
  }
}
</script>