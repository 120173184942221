<template>
    <div>
        <main class="app-content">
          <div class="app-title">
            <div>
              <h1><v-icon color="black">mdi-layers-triple</v-icon>Facturas Electronicas Ventas</h1>
              <p>Carga masiva de archivos xml fel ventas</p>
            </div>
            <ul class="app-breadcrumb breadcrumb">
              <v-btn class="white--text" color="red" @click="$router.go(-1)">
                  <v-icon left>mdi-close-circle-outline</v-icon>
                  Cerrar
              </v-btn>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="tile">              
                    <v-row>
                        <v-col>
                            
                            <v-file-input
                                v-model="files"
                                color="blue"
                                counter
                                label="Seleccione los archivos XML"
                                multiple
                                accept="text/xml"
                                placeholder="Select sus archivos XML"
                                prepend-icon="mdi-paperclip"
                                @change="loadfiles"
                                outlined
                                >
                                <template v-slot:selection="{ index, text }">
                                <v-chip v-if="index < 3" color="blue" dark label small>
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 3"
                                    class="overline grey--text text--darken-3 mx-2"
                                >
                                    +{{ files.length - 3 }} Archivo(s)
                                </span>
                                </template>
                            </v-file-input>
                        </v-col>
                        <v-col cols="4">
                            <v-select v-model="estadoseleccionado" :items="estado" hide-selected label="Estado Facturas" color="blue" outlined></v-select>
                        </v-col>
                    </v-row>
                   <v-divider></v-divider>
                   <v-row >
                       <v-col cols="8">
                           <h5>{{this.$store.getters.getSelectedItem.Nit+' - '+this.$store.getters.getSelectedItem.Establecimiento}}</h5>
                       </v-col>
                       <v-col cols="4" v-show="DTE.length>0">
                            <v-btn @click="procesarFel" color="green" class="text-white"> <v-icon color="white">mdi-layers-triple</v-icon>Procesar Facturas</v-btn> | 
                            <v-btn @click="limpiar()" color="blue" class="text-white"> <v-icon color="white">mdi-table-off</v-icon> Vaciar Tabla</v-btn>
                       </v-col>
                   </v-row>
                    <v-row>
                        <v-col>
                        <v-data-table
                        :headers="headers"
                        :items="DTE"
                        :items-per-page="10"
                        calculate-widths
                        class="elevation-1"
                        ></v-data-table>
                        </v-col>
                    </v-row>
                
              </div>
            </div>
          </div>
  <!-- DIALOG CARGANDO -->        
  <div class="text-center">
        <v-dialog v-model="dialog" hide-overlay persistent width="300" >
        <v-card color="teal" dark>
            <v-card-text>
            {{cargadotexto}}
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
        </main>

    </div>
</template>
<script>
import axios from 'axios'
import {ProcesarXMLventa} from '@/libs/procesardteventas'
import Swal from 'sweetalert2'
import currency from '@/libs/currency';

export default {
    name:'FelVentas',
    data() {
        return {
            dialog:false,
            cargadotexto:'',
            files: [],
            urlapi:this.$store.getters.getUrlApi,
            estadoseleccionado:'Emitida',
            estado: ['Emitida','Anulada'],
            headers: [
            { text: 'FechaFactura', value: 'FechaFactura'},
            { text: 'Serie', value: 'Serie'},
            { text: 'Numero Factura', value: 'NumeroFactura'},
            { text: 'Nit', value: 'Nit'},
            { text: 'Cliente', value: 'Cliente'},
            { text: 'Total Factura', value: 'TotalFactura'},
            { text: 'Total Sin Iva', value: 'TotalSinIVA'},
            { text: 'Iva', value: 'Iva'},
            { text: 'Estado', value: 'EstadoFactura'}
            ],
            DTE:[],
        }
    },
    created() {
        this.$store.dispatch("deleteFacturas")
    },
    computed:{
        FacturasFel() {
        return this.$store.getters.getFacturas
        },
    },
    methods: {
        loadfiles(e){
            this.dialog=true;
            this.cargadotexto='Cargando Archivos...'

            e.forEach(element => {
               ProcesarXMLventa(element,this.estadoseleccionado,this.$store.getters.getIdVenta.IdVenta)
            //  this.DTE.push(dteProcesado)
            });
            this.DTE = this.$store.getters.getFacturas

           this.dialog=false;   
                 
          },
          procesarFel(){  
            this.dialog=true;
            this.cargadotexto='Procesando Facturas..' 
    
            axios.post(`${this.urlapi}/facturasventas/fel`,this.DTE,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
                this.dialog=false;
                this.actualizartotales()
                this.exitoSwal('Facturas Procesadas','')
                this.limpiar()
              }).catch(err=>{console.log(err),this.dialog=false,this.errorSwal('Error al procesar las Facturas','')})
              
          },
          limpiar(){
              
              this.$store.dispatch("deleteFacturas") 
              this.DTE=[]
              this.files=[]
          },
          actualizartotales(){
            const regimen = this.$store.getters.getRegimen
            const total_actual= parseFloat(this.$store.getters.getIdVenta.Total)
            let sumatotal = this.DTE.reduce((acumulado,item)=>acumulado+item.TotalFactura,0)
            
            const nuevototal = currency(total_actual+sumatotal,2).value

            this.$store.dispatch("setTotalVenta",nuevototal)
            if(regimen=='rg'){
                let Iva = currency((nuevototal/1.12)*0.12,2).value
                this.$store.dispatch("setTotalIva",Iva)

                }else{
                let Iva = currency(nuevototal*0.05,2).value
                this.$store.dispatch("setTotalIva",Iva)
            }
          },
         //Mesajes Sweet Alert
        errorSwal(titulo,texto){
            Swal.fire({
                title:titulo+' 😦',
                text:texto,
                icon:'error'
            })
        },
        exitoSwal(titulo,texto){
            Swal.fire({
                icon: 'success',
                title: titulo+' 😦',
                text:texto,
                showConfirmButton: false,
                timer: 1500
            })
        }, 
    },
}
</script>