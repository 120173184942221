<template>
  <div>
    
   <template v-if="getCurrentUser">
     <v-app>
        <v-main>
          <NavBar auth="CONTABILIDAD"/>
          <Sidebar/>
          <router-view/>
        </v-main>
      </v-app>
    
   </template>
  <template v-else>
      <Login/>
  </template> 
  </div>
</template>


<script>
import NavBar from './components/NavBar'
import Login from './components/login.vue'
import Sidebar from './views/template/Sidebar'
import {mapGetters} from 'vuex'
export default {
  name: 'App',
  components: {
    NavBar,
    Sidebar,
    Login, 
  },
  data() {
    return {
      variable:true
    }
  },
  computed: {
    ...mapGetters(["getCurrentUser"])
  },
 
  
};
</script>
