<template>
    <div>
      <main class="app-content">        
        <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <v-row class="text-center">
                <v-col>
                <v-card class="title pa-2" elevation="2">            
                  <v-row class="pt-3">
                    <v-col sm="10">
                      <v-text-field label="Nit | Establecimiento" outlined color="teal" dense
                      :value="establecimiento"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2">
                      <v-btn active-class="red" color="teal" dark rounded  elevation="2" @click="printPDF($store.getters.getIdVenta)"><v-icon color="white">mdi-printer</v-icon></v-btn>
                      <v-btn color="error" dark rounded elevation="2" @click="$router.go(-1)"><v-icon color="white">mdi-close-circle</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pa-0">
                    <v-col sm="2" class="pa-1">
                    <v-text-field label="Serie" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Serie"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Folio" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Folio"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Periodo" outlined color="teal" dense
                      :value="periodo"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Total sin IVA" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Total"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Impuesto (IVA)" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.IVA"
                      readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                </v-col>
              </v-row>
              <!-- FORMULARIO INGRESO FACTURAS-->
              <v-row>
                <v-card class="pl-2 pr-2 center" color="#343a40">
                  <form @submit.prevent="save">
                    <div class="form-row justify-content-sm-center" id="frmDatos">
                      <div class="col-1 mb-3">
                        <input class="form-control" v-model="dia" required 
                        v-on:keyup.enter="nextFocus"
                        v-on:keyup.right="$refs.combustible.focus()" 
                        @change="validarDias" type="number" name="dia" ref="dia">
                        <p>Día</p> 
                      </div>
                      <div class="col-sm-3 row no-gutters">
                        <div class="col pr-0" v-show="$store.getters.getIdVenta.IdResolucion<1">
                          <input class="form-control" type="text" v-model="editedItem.Serie" placeholder="No. Serie" name="serie" ref="serie"  @keyup.enter="nextFocus" required>   
                          <p>Serie</p>  
                        </div>
                        <div class="col pr-0 pl-0">
                          <input class="form-control" v-model="editedItem.NumeroFactura" placeholder="No. FC" @change="validarUltimaFactura" @keyup.enter="nextFocus" type="text" ref="numfc" name="numfc" required>   
                          <p>Número Factura</p> 
                        </div>
                        <div class="col pl-0">
                          <input type="text" class="form-control" v-model="editedItem.Nit" placeholder="No. NIT"  @keyup.enter="nextFocus" @change="getCliente" name="nit" ref="nit"  required>
                          <p>Nit Cliente</p> 
                        </div> 
                      </div>                
                      <div class="col-sm-2"> 
                      <input type="text" class="form-control" v-model="editedItem.Cliente" placeholder="Nombre Cliente"  @keyup.enter="nextFocus" name="cliente" ref="cliente" required>
                        <p>Nombre Cliente</p> 
                      </div>                   
                      <div class="col-sm-4 row no-gutters">
                        <div class="col-3">
                          <select class="form-control" v-model="editedItem.TipoCombustible" @change="calculIDP" name="combustible" ref="combustible" required>
                              <option value="Diesel">Diesel</option>
                              <option value="Regular">Regular</option>
                              <option value="Super">Super</option>
                              <option value="Bienes">Bienes</option>
                          </select>
                          <p>Tipo Combustible</p>
                        </div> 
                        <div class="col-3">
                          <input type="text" class="form-control" v-model="editedItem.ValorTotal" placeholder="Total" @keyup.enter="nextFocus" name="totalfc" ref="totalfc" @keyup="calculIDP" required>
                          <p>Total Factura</p>
                        </div>
                        <div class="col-2">
                          <input type="text" class="form-control" v-model="editedItem.Galones" @keyup="calculIDP" @keyup.enter="nextFocus" name="galones" ref="galones" placeholder="Galones" required>
                          <p>Galones</p>
                        </div>
                        <div class="col-2">
                          <input type="text" class="form-control" v-model="editedItem.TotalFactura" placeholder="Total sin IDP" @keyup.enter="nextFocus" name="idp" ref="idp" required>
                          <p>Total sin IDP</p>
                        </div>
                        <div class="col-2">
                          <input type="text" class="form-control" v-model="editedItem.Iva" placeholder="Iva" readonly>
                          <p>Impuesto 12%</p>
                        </div>
                      </div>
                      <div class="col-sm-2 row no-gutters">                    
                        <div class="col-sm-6">
                        <select class="form-control" v-model="editedItem.Estado" @change="anularFac" name="estado" ref="estado" required>
                            <option value="Emitida">Emitida</option>
                            <option value="Anulada">Anulada</option>
                        </select>
                        </div>
                        <div class="col-sm-6">
                        <v-divider class="ma-1" inset vertical></v-divider>
                        <v-btn type="button" depressed class="white--text" @keyup.enter="save" @click="save" color="light-green" elevation="2">
                        <v-icon color="white" v-show="editedIndex<0">mdi-sticker-plus</v-icon>       
                        <v-icon color="white" v-show="editedIndex>=0">mdi-clipboard-edit</v-icon>       
                        </v-btn>
                        </div>
                         <!-- BOTONES FORMULARIO  -->
                      </div>
                    </div>
                  </form>
                </v-card>
              </v-row>
              <!-- TABLA DETALLE FACTURAS  -->
              <v-row>
                <v-col cols="12">
                        <v-card>
                            <v-card-title>
                              <v-btn color="blue" class="text-white" 
                              @click="$router.push('/procesardte')">
                              <v-icon left color="white">mdi-file-upload</v-icon>Cargar DTE
                              </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn color="red" class="text-white" @click="deleteAllItems">
                              <v-icon left color="white">mdi-delete-circle</v-icon>Vaciar
                              </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="btn-group">
                              <span class="badge badge-warning">Diesel<h6>{{sumaCombustibles[0]}}</h6></span>
                              <span class="badge badge-danger">Regular<h6>{{sumaCombustibles[1]}}</h6></span>
                              <span class="badge badge-success">Super<h6>{{sumaCombustibles[2]}}</h6></span>
                              <span class="badge badge-info">Bienes<h6>{{sumaCombustibles[3]}}</h6></span>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                color="teal"
                                single-line
                                hide-details
                            ></v-text-field>                           
                            </v-card-title>
                            <v-data-table
                            :headers="headers"
                            :items="dataitems"
                            :items-per-page=5
                            item-class="fondo"
                            :search="search"
                            calculate-widths
                            color="teal"
                            class="elevation-0"
                            dense
                            >
                            <template v-slot:[`item.Serie`]="{ item }">
                              {{item.Serie==0?$store.getters.getIdVenta.Serie:item.Serie}}
                            </template>
                            <template v-slot:[`item.TotalSinIva`]="{ item }">
                              {{moneda(item.TotalFactura)}}
                            </template>  
                            <template v-slot:[`item.ValorTotal`]="{ item }" class="alert-info" >
                              {{item.ValorTotal}}
                            </template>             
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <v-btn icon @click="editItem(item)"><v-icon color="blue">mdi-file-document-edit</v-icon></v-btn>
                                    <v-btn icon @click="mensajeEliminarFactura(item)"><v-icon color="red">mdi-file-remove</v-icon></v-btn>
                                </div>       
                            </template>
                            <template v-slot:no-data>
                                <button class="btn btn-primary text-white" type="button">Sin Ventas</button>
                            </template>
                            </v-data-table>
                        </v-card>
                      </v-col>
              </v-row>
            </div>
          </div>
        </div>
       
      </main>
      <!-- MODAL AGREGAR CLIENTE -->
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
     
      <v-card>
        <v-card-title class="headline teal text-white">
          Agregar Cliente
        </v-card-title>

        <v-card-text>
          <br><br>
           <v-text-field v-model="editedItem.Nit" label="Nit" color="teal" outlined dense required autofocus></v-text-field>
           <v-text-field v-model="cliente.Proveedor" label="Nombre Cliente" color="teal" outlined dense required></v-text-field>
           <v-text-field v-model="cliente.Tipo" label="Tipo" color="teal" outlined dense required disabled></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" class="btn btn-primary text-white ma-2" @click="guardarCliente"><v-icon color="white">mdi-check-circle</v-icon> AGREGAR</button>         
          <button type="button" class="btn btn-secondary text-white" @click="dialog = false"><v-icon color="white">mdi-close-circle</v-icon> CANCELAR</button>         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    </div>
    
</template>
<script>
import axios from 'axios'
import * as dayjs from 'dayjs'
import customParseFormat  from 'dayjs/plugin/customParseFormat'
import currency from '@/libs/currency'
import Swal from 'sweetalert2'
import {reportes} from '@/libs/reportes'
// @submit.prevent="save"
export default {
  name:'FacturasCombustible',
  data() {
    return {
      urlapi:this.$store.getters.getUrlApi,
      TipoCombustible:["Diesel","Regular","Super","Bien"],
      dataitems:[],
      dialog:false,
      search:'',
      dia:1,
      NumeroFactura:0,
      headers: [
            {text: 'Fecha Factura',value: 'FechaFactura'},
            { text: 'Serie', value: 'Serie'},
            { text: 'No. Fac:', value: 'NumeroFactura' },
            { text: 'Nit', value: 'Nit' },
            { text: 'Cliente', value: 'Cliente' },
            { text: 'Total Factura', value: 'TotalFactura' },
            { text: 'Total sin IVA', value: 'TotalSinIva' },
            { text: 'Iva', value: 'Iva' },
            { text: 'Valor Total', value: 'ValorTotal'},
            { text: 'Galones', value: 'Galones'},
            { text: 'Precio', value: 'Precio'}, 
            { text: 'Tipo', value: 'TipoCombustible'},	 
            { text: 'Estado', value: 'Estado'},
            { text: 'Opciones', value: 'actions', sortable: false },
            ], 
     editedIndex: -1,
     editedItem:{
        IdLibroVenta:0,
        Nit:'C-F',
        Cliente:'Clientes Varios',
        NumeroFactura:0,       
        Serie:'',
        FechaFactura:1,
        TotalFactura:0,
        Iva:0,
        ValorTotal:0,
        Galones:0,
        TipoCombustible:'Diesel',
        Estado:'Anulada'
      },
     defaultItem:{
        IdLibroVenta:0,
        Nit:'C-F',
        Cliente:'Clientes Varios',
        NumeroFactura:0,       
        Serie:'',
        FechaFactura:1,
        TotalFactura:0,
        Iva:0,
        ValorTotal:0,
        Galones:0,
        TipoCombustible:'Diesel',
        Estado:'Anulada'
     }, 
     cliente:{
       Proveedor:'',
       Tipo:'Cliente'
     } 
    }
  },
  computed: {  
    periodo(){
      return dayjs(this.$store.getters.getIdVenta.Fecha,'YYYY-MM-DD').add(1, 'day').format('MMMM YYYY').toUpperCase()
    },
    establecimiento(){
      return (this.$store.getters.getIdVenta.Nit+" | "+this.$store.getters.getIdVenta.Establecimiento).toUpperCase()
    },
    Iva() {      
      return this.$route.path.indexOf('rg')>0?currency((this.editedItem.TotalFactura/1.12)*0.12,2):currency((this.editedItem.TotalFactura)*0.05,2)
    },
    totalSinIva() {
      return currency((this.editedItem.TotalFactura/1.12),2)
    },
    sumaCombustibles(){ return this.TotalCombustibles()}
  },
  created() {
    this.init()
    this.getUltimaFactura()
    this.getCliente()
  }, 
  methods: {
    nextFocus(e){
      let serie = this.$store.getters.getIdVenta.Serie
      let valor = e.target.name     
      if(valor=='dia'){serie==='FEL'?this.$refs.serie.focus():this.$refs.numfc.focus()}
      if(valor=='serie') this.$refs.numfc.focus();
      if(valor=='numfc') this.$refs.nit.focus();
      if(valor=='nit') this.$refs.cliente.focus();
      if(valor=='cliente') this.$refs.combustible.focus();
      if(valor=='combustible') this.$refs.totalfc.focus();
      if(valor=='totalfc') this.$refs.galones.focus();
      if(valor=='galones') this.$refs.idp.focus();
      if(valor=='idp'){
        //Guardamos la Factura
        this.save()
      }
    },
    TotalCombustibles(){
      let sumadiesel=0;
      let sumaregular=0;  
      let sumasuper=0;  
      let sumabienes=0; 
      if(this.dataitems.length){

        this.dataitems.forEach(element => {
            element.TipoCombustible==='Diesel'?sumadiesel+=parseFloat(element.Galones):0  
            element.TipoCombustible==='Regular'?sumaregular+=parseFloat(element.Galones):0         
            element.TipoCombustible==='Super'?sumasuper+=parseFloat(element.Galones):0         
            element.TipoCombustible==='Bienes'?sumabienes+=parseFloat(element.TotalFactura):0         
          });
      }
        return [currency(sumadiesel,2),currency(sumaregular,2),currency(sumasuper,2),currency(sumabienes,2)]
    },
    init(){
      const IdVenta = this.$store.getters.getIdVenta.IdVenta
      this.$store.dispatch("setTopClientesCombustible")
      axios.get(`${this.urlapi}/facturascombustible/${IdVenta}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
        if(res.data.length>0){
          this.dataitems = res.data 
          }else{
            this.dataitems = [];
          }
      }).catch(err=>{console.log(err)})   
    },
    //Metodo para obtener el último No. de factura
    getUltimaFactura(){
      const IdResolucion = this.$store.getters.getIdVenta.IdResolucion
      const IdEstablecimiento = this.$store.getters.getSelectedItem.IdEstablecimiento
      //Que no sea FEL
      if(this.$store.getters.getIdVenta.IdResolucion){
        axios.get(`${this.urlapi}/facturascombustible/${IdEstablecimiento}/${IdResolucion}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{   
          this.editedItem.NumeroFactura = res.data[0].NumeroFactura
          this.NumeroFactura = res.data[0].NumeroFactura
        }).catch(()=>{})
      }
    },
    //Metodo para obtener el Nombre y el Id del Cliente
    getCliente(){
      const persona=this.$store.getters.buscarClienteCombustible(this.editedItem.Nit)
      if(persona){
        this.editedItem.Cliente = persona.Cliente
        this.editedItem.IdCliente = persona.IdProveedor
      }else{
        axios.get(`${this.urlapi}/personas/${this.editedItem.Nit}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
          
          if(res.data.length>0){
            this.editedItem.Cliente = res.data[0].Proveedor
            this.editedItem.IdCliente = res.data[0].IdProveedor
          }else{
           
            Swal.fire({text:'No se encontró el Cliente presione Ok Para agregar!'}).then(res=>{
              if(res.isConfirmed){
                this.dialog=true
              }else{
                this.Nit='C-F'
              }
            })
            
          }

        }).catch(err=>{console.log(err)})

        
      }
    },
    editItem(item){

      this.editedIndex = this.dataitems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      let fecha = item.FechaFactura.split("-");
      this.dia = fecha[0]   
      this.$refs.dia.focus();
    },
    
    save(){
      if (this.editedIndex <0) {
            this.guardarRegistro(); 
            } else {
             this.editarRegistro();
          }
         // this.close()
    },
    //Metodo INSERTAR Factura Venta
    async guardarRegistro(){
      this.editedItem.IdLibroVenta = this.$store.getters.getIdVenta.IdVenta
      this.validarDias()
      
      axios.post(`${this.urlapi}/facturascombustible/`,[this.editedItem],{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
       this.limpiar()
      }).catch(err=>{
        console.error(err)
      })
    },
    //Metodo editar Factura Venta 
    async editarRegistro(){
      this.editedItem.IdLibroVenta = this.$store.getters.getIdVenta.IdVenta  
      
      this.validarDias()
      axios.put(`${this.urlapi}/facturascombustible/${this.editedItem.IdDetalle}`,this.editedItem,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
        this.limpiar()
        this.exitoSwal('Factura Modificada','')
      }).catch(err=>{
        this.swalmsg('Error al Actualizar la Factura','')
        console.error(err)
      })
    },
    guardarCliente(){      
      this.cliente.Nit = this.editedItem.Nit
      axios.post(`${this.urlapi}/personas`,this.cliente,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
        this.editedItem.Cliente=this.cliente.Proveedor
        //Traemos listado de los clientes 
        this.cliente.Proveedor=''
        this.$store.dispatch("setTopClientes")
        this.dialog=false
        this.exitoSwal('Cliente Agregado','')
      }).catch(err=>{
        console.error(err)
      })
    },
    mensajeEliminarFactura(item){
      Swal.fire({
        title: `Eliminar Factura No. ${item.NumeroFactura}?`,
        text: "Esta seguro de eliminar esta factura!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarFactura(item)
        }
      })
    },
    eliminarFactura(item){
     
      const total_actual= parseFloat(this.$store.getters.getIdVenta.Total)
      const nuevototal = total_actual-(parseFloat(item.ValorTotal))
      
      axios.delete(`${this.urlapi}/facturascombustible/${item.IdDetalle}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
        this.$store.dispatch("setTotalVenta",nuevototal)
        this.getUltimaFactura()
        this.init()
        this.exitoSwal('Factura Eliminada','')
      }).catch(err=>{console.log(err),this.swalmsg('Error al Eliminar la Factura','')})
    },
    deleteAllItems(){
      Swal.fire({
        title: `¿Desea Eliminar Todas las Facturas?`,
        text: "¡Está acción no podra revertirse, presione Estoy Seguro para eliminarlas!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Estoy Seguro!',
        cancelButtonText: 'No, Cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          let IdVenta =  this.$store.getters.getIdVenta.IdVenta
          //Relizamos la petición para eliminar las Facturas
          axios.delete(`${this.urlapi}/facturascombustible/allitems/${IdVenta}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
            this.init()
            this.getUltimaFactura()
            this.exitoSwal() 
            this.$store.dispatch("setTotalVenta",0.00)
            this.$store.dispatch("setTotalIva",0.00)
          }).catch(err=>{console.log(err),this.errorSwal()})
        }
      })      
    },
    //Validaciones
    validarDias(){
           const periodo= dayjs(this.$store.getters.getIdVenta.Fecha,'YYYY-MM-DD')
        
           const ultimodiames = periodo.add(1,'day').endOf('month').format('DD')
          

          if(this.dia<1 ||this.dia>parseInt(ultimodiames)){
            this.dia=1
            this.editedItem.FechaFactura = dayjs(periodo).format('YYYY-MM-DD'); 
            this.swalmsg('Dia incorrecto','El día no puede ser menor a 1 ni mayor a '+ultimodiames)
          }else{
            dayjs.extend(customParseFormat)
            const anio_mes = dayjs(periodo).format('YYYY-MM')
            this.dia = parseInt(this.dia)
            this.editedItem.FechaFactura = anio_mes+'-'+this.dia

            
          }          
    },
    //Validar que la factura no haya sido ingresada previamente
    validarUltimaFactura(){
      const idventa = this.$store.getters.getIdVenta.IdVenta
      if(parseInt(this.NumeroFactura)!==parseInt(this.editedItem.NumeroFactura) ){

       axios.get(`${this.urlapi}/facturasventas/buscarfactura/${idventa}/${this.editedItem.NumeroFactura}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
         
         if(res.data.length>0){
           this.swalmsg('Factura ya Ingresada','El numero de Factura '+this.editedItem.NumeroFactura+' ya fue Ingresado')
           this.editedItem.NumeroFactura = this.NumeroFactura
         }

        }).catch(err=>{console.log(err)})
       
      }
    },   
    //Metodo para Calcular el Total -IVA y calcular el IVA 12%
    moneda(total){
        return currency((total/1.12),2)    
    },
    calculIDP(e){
      this.editedItem.ValorTotal>0?this.editedItem.Estado='Emitida':this.editedItem.Estado='Anulada'

      if(this.editedItem.TipoCombustible==='Diesel'){
         this.editedItem.TotalFactura= currency(this.editedItem.ValorTotal-(this.editedItem.Galones*1.30),2).value
      }else if(this.editedItem.TipoCombustible==='Regular'){
         this.editedItem.TotalFactura= currency(this.editedItem.ValorTotal-(this.editedItem.Galones*4.60),2).value
      }else{
         this.editedItem.TotalFactura= currency(this.editedItem.ValorTotal-(this.editedItem.Galones*4.70),2).value
      }
      this.editedItem.Iva = currency((this.editedItem.TotalFactura/1.12)*0.12,2).value
      if(e.target.name =='combustible') this.$refs.totalfc.focus();
    },
    anularFac(){
      if(this.editedItem.Estado==='Anulada'){
        this.editedItem.TotalFactura=0
        this.editedItem.ValorTotal=0
        this.editedItem.Galones=0
        this.editedItem.Iva=0
      }
    },
    //Limpiar Campos
    limpiar(){
           const total_actual= parseFloat(this.$store.getters.getIdVenta.Total)
           
          if (this.editedIndex <0) {
            //Al Crear Item
              const nuevototal=total_actual+parseFloat(this.editedItem.TotalFactura)
              this.editedItem.NumeroFactura++
              this.NumeroFactura++
              this.$store.dispatch("setTotalVenta",nuevototal)
            } else {
              this.editedIndex= -1
              const itemfactura = this.dataitems.find(elemento=>elemento.IdDetalle==this.editedItem.IdDetalle)
              const nuevototal = (total_actual-parseFloat(itemfactura.TotalFactura) )+parseFloat(this.editedItem.TotalFactura)
              this.$store.dispatch("setTotalVenta",nuevototal) 
              this.editedItem = Object.assign({}, this.defaultItem)
              this.getCliente()
              this.getUltimaFactura()
          }
              
              this.editedItem.Estado='Anulada'
              this.anularFac()//Lo use para limpiar los campos :)
              this.$refs.dia.focus();
              this.init()
    },
    //Mesajes Sweet Alert
    swalmsg(titulo,texto){
      Swal.fire({
        title:titulo,
        text:texto,
        icon:'error'
      })
    },
    exitoSwal(titulo,texto){
      Swal.fire({
        icon: 'success',
        title: titulo,
        text:texto,
        showConfirmButton: false,
        timer: 1500
      })
    },
    printPDF(item){
         reportes(item,this.$route.path)
      },
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans&display=swap');
.title{
  margin-top: -50px;
  line-height: 10px;
}
a{
text-decoration: none;
}
#frmDatos{ 
  color: rgb(27, 179, 103) !important; 
}

#frmDatos p{
  text-align: left;
  line-height: 12px;
  margin-left: 3px;
  margin-top:2px;
  margin-bottom: -4px;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
}
</style>