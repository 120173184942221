import axios from "axios"


export default{
    state:{
        topClientes:[],
        topClientesCombustible:[],
        topProveedores:[]
    },
    mutations:{
        setTopClientes(state,items){
            state.topClientes=items
        },
        setTopClientesCombustible(state,items){
            state.topClientesCombustible=items
        },
        setTopProveedores(state,items){
            state.topProveedores=items
        }
    },
    actions:{
        setTopClientes({commit,rootState}){
            const IdEstablecimiento = rootState.Establecimientos.selecteditem.IdEstablecimiento
            axios.get(`${rootState.urlapi}/personas/topClientes/${IdEstablecimiento}`,{headers:{'auth-user':rootState.Usuario.token}}).then(res=>{
                commit("setTopClientes",res.data)
            })
        },
        setTopClientesCombustible({commit,rootState}){
            const IdEstablecimiento = rootState.Establecimientos.selecteditem.IdEstablecimiento
            axios.get(`${rootState.urlapi}/personas/topClientesCombustible/${IdEstablecimiento}`,{headers:{'auth-user':rootState.Usuario.token}}).then(res=>{
                commit("setTopClientesCombustible",res.data)
            })
        },
        setTopProveedores({commit,rootState}){
            const IdEstablecimiento = rootState.Establecimientos.selecteditem.IdEstablecimiento
            axios.get(`${rootState.urlapi}/personas/topProveedores/${IdEstablecimiento}`,{headers:{'auth-user':rootState.Usuario.token}}).then(res=>{
                commit("setTopProveedores",res.data)
            })
        }
    },
    getters:{
        buscarCliente:(state) => (nit) =>{
            return state.topClientes.length>0?state.topClientes.find(elemento=>elemento.Nit==nit):false
        },
        buscarClienteCombustible:(state) => (nit) =>{
            return state.topClientesCombustible.length>0?state.topClientesCombustible.find(elemento=>elemento.Nit==nit):false
        },
        buscarProveedor:(state) => (nit) =>{
            return state.topProveedores.length>0?state.topProveedores.find(elemento=>elemento.Nit==nit):false
        }
    }
}