import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import axios from 'axios'
//Configuración de Fechas 
import * as dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
