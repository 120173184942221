var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('main',{staticClass:"app-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 bg-white"},[_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.itemslist,"disable-pagination":"","hide-default-footer":"","search":_vm.search,"sort-by":"fecha"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","color":"teal","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio","prepend-icon":"mdi-calendar","outlined":"","dense":"","readonly":"","color":"teal"},model:{value:(_vm.fechas.fechainicio),callback:function ($$v) {_vm.$set(_vm.fechas, "fechainicio", $$v)},expression:"fechas.fechainicio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"es-GT","color":"teal"},on:{"input":function($event){_vm.menu = false},"change":_vm.validarfechas},model:{value:(_vm.fechas.fechainicio),callback:function ($$v) {_vm.$set(_vm.fechas, "fechainicio", $$v)},expression:"fechas.fechainicio"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"fechafin",attrs:{"label":"Fecha Fin","prepend-icon":"mdi-calendar","outlined":"","dense":"","readonly":"","color":"teal"},model:{value:(_vm.fechas.fechafin),callback:function ($$v) {_vm.$set(_vm.fechas, "fechafin", $$v)},expression:"fechas.fechafin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-GT","color":"teal"},on:{"input":function($event){_vm.menu2 = false},"change":_vm.validarfechas},model:{value:(_vm.fechas.fechafin),callback:function ($$v) {_vm.$set(_vm.fechas, "fechafin", $$v)},expression:"fechas.fechafin"}})],1)],1),_c('v-btn',{staticClass:"ml-5 text-white",attrs:{"color":"blue","id":"btnprint01"},on:{"click":_vm.printPDF}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-cloud-print-outline")]),_vm._v(" Imprimir")],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.fecha))])]}},{key:"item.cliente",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.cliente))]),_vm._v(" "+_vm._s(item.categoria)+" "+_vm._s(item.descripcion)+" ")]}},{key:"no-data",fn:function(){return [_c('h1',[_vm._v("No se encontrarón datos en estas fechas")]),_c('h5',[_vm._v("Seleccione otro rango de fechas")])]},proxy:true}],null,true)})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }