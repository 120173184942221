
export default {
    state:{
        cliente:false
    },
    mutations:{
        setCliente(state,item){
            state.cliente=item
        }
    },
    actions:{
        setCliente({commit},cliente){  
            commit("setCliente",cliente)  
        }                   
    },
    getters:{
        getCliente(state){
            return state.cliente
        }
    }
}
