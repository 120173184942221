import currency from '@/libs/currency';
import store from '@/store/index'


export function ProcesarXML(archivoxml,estado,idventa){
       
  const IdLibroVenta = idventa;
  const Estado = estado;
       let patharchivoXML = (window.URL || window.webkitURL).createObjectURL(archivoxml);
   
       let xhttp = new XMLHttpRequest();
       xhttp.onreadystatechange = async function() {
           if (xhttp.readyState == 4 && xhttp.status == 200) {
             // Validamos el estado
             if(Estado==='Emitida'){
               //Procesamos facturas Emitidas
               await procesarItemEmitidos(xhttp)
             }else{
               //Procesamos facturas Anuladas
               await procesarItemAnulados(xhttp)
             }
           }
       };
       //FACTURA COMBUSTIBLE 
       xhttp.open("GET",patharchivoXML, true);
       xhttp.send();

       //Función para procesar los xml
       async function procesarItemEmitidos(xml){
           let xmlDoc = xml.responseXML;
        

           let dteReceptor = xmlDoc.getElementsByTagName("dte:Receptor")[0];
           let Cliente = dteReceptor.getAttributeNode("NombreReceptor").nodeValue;
           const Nit = dteReceptor.getAttributeNode("IDReceptor").nodeValue;

           let dteFac = xmlDoc.getElementsByTagName("dte:NumeroAutorizacion")[0];
           const Serie = dteFac.getAttributeNode("Serie").nodeValue;
           const NumeroFactura = dteFac.getAttributeNode("Numero").nodeValue;
       
          //DATOS DEL CLIENTE
          Cliente = Cliente.split(",,")
          Cliente=  Cliente[1]?Cliente[1]+' '+Cliente[0]: Cliente[0]
          Cliente = Cliente.replace(',',' ')
          const nomCliente = Cliente.replace(',',' ')

           //Obtenemos Fecha de Emision
           let datosgenerales=xmlDoc.getElementsByTagName("dte:DatosGenerales")[0];
           const FechaFactura=datosgenerales.getAttributeNode("FechaHoraEmision").nodeValue;

            //Obtenemos los productos de la factura
            let data = xmlDoc.getElementsByTagName("dte:Item");
            
            let bienes=false;
            let sumatoriabienes = 0;
       
               for (let index = 0; index < data.length; index++) {
                  
                let FelGenerado={
                  IdLibroVenta:IdLibroVenta,
                  Nit:Nit,
                  Cliente:nomCliente,
                  NumeroFactura:NumeroFactura,       
                  Serie:Serie,
                  FechaFactura:FechaFactura,
                  Estado:Estado,
 
                } 
                //Obtenemos el Tipo de Combustible
                let TipoCombustible= (xmlDoc.getElementsByTagName("dte:Descripcion")[index].childNodes[0].nodeValue).toLowerCase()

                FelGenerado.ValorTotal=parseFloat(xmlDoc.getElementsByTagName("dte:Total")[index].childNodes[0].nodeValue)
                FelGenerado.Galones=parseFloat(xmlDoc.getElementsByTagName("dte:Cantidad")[index].childNodes[0].nodeValue)

              switch (TipoCombustible) {
                case 'diesel':
                  FelGenerado.TipoCombustible='Diesel'
                  FelGenerado.TotalFactura=currency(FelGenerado.ValorTotal-(FelGenerado.Galones*1.30),2).value
                  FelGenerado.Iva=currency((FelGenerado.TotalFactura/1.12)*0.12,2).value
                  store.dispatch("setFacturas",FelGenerado) 
                  break;
                case 'regular':
                  FelGenerado.TipoCombustible='Regular'
                  FelGenerado.TotalFactura=currency(FelGenerado.ValorTotal-(FelGenerado.Galones*4.60),2).value
                  FelGenerado.Iva=currency((FelGenerado.TotalFactura/1.12)*0.12,2).value
                  store.dispatch("setFacturas",FelGenerado) 
                  break;
                case 'super':
                  FelGenerado.TipoCombustible='Super'
                  FelGenerado.TotalFactura=currency(FelGenerado.ValorTotal-(FelGenerado.Galones*4.70),2).value
                  FelGenerado.Iva=currency((FelGenerado.TotalFactura/1.12)*0.12,2).value
                  store.dispatch("setFacturas",FelGenerado) 
                  break;
                default:
                  bienes =true;
                  sumatoriabienes += parseFloat(FelGenerado.ValorTotal);
                  break;
              }

               }//finFor

               if(bienes){
                  let FelGenerado={
                    IdLibroVenta:IdLibroVenta,
                    Nit:Nit,
                    Cliente:nomCliente,
                    NumeroFactura:NumeroFactura,       
                    Serie:Serie,
                    FechaFactura:FechaFactura,
                    Estado:Estado,
                    Galones:0,
                    TipoCombustible:'Bienes',
                    
                  }
                  FelGenerado.ValorTotal=currency(sumatoriabienes,2).value;
                  FelGenerado.TotalFactura=currency(FelGenerado.ValorTotal,2).value;
                  FelGenerado.Iva = currency((FelGenerado.ValorTotal/1.12)*0.12,2).value;
                  store.dispatch("setFacturas",FelGenerado) 
                 
                }

       }

       async function procesarItemAnulados(xml){
        let xmlDoc = xml.responseXML;

        let dteReceptor = xmlDoc.getElementsByTagName("dte:Receptor")[0];
        let Cliente = dteReceptor.getAttributeNode("NombreReceptor").nodeValue;
        const Nit = dteReceptor.getAttributeNode("IDReceptor").nodeValue;

        let dteFac = xmlDoc.getElementsByTagName("dte:NumeroAutorizacion")[0];
        const Serie = dteFac.getAttributeNode("Serie").nodeValue;
        const NumeroFactura = dteFac.getAttributeNode("Numero").nodeValue;
    
        //DATOS DEL CLIENTE
        Cliente = Cliente.split(",,")
        Cliente=  Cliente[1]?Cliente[1]+' '+Cliente[0]: Cliente[0]
        Cliente = Cliente.replace(',',' ')
        const nomCliente = Cliente.replace(',',' ')

        //Obtenemos Fecha de Emision
        let datosgenerales=xmlDoc.getElementsByTagName("dte:DatosGenerales")[0];
        const FechaFactura=datosgenerales.getAttributeNode("FechaHoraEmision").nodeValue;

         
         let FelGenerado={
          IdLibroVenta:IdLibroVenta,
          Nit:Nit,
          Cliente:nomCliente,
          NumeroFactura:NumeroFactura,       
          Serie:Serie,
          FechaFactura:FechaFactura,
          Galones:0,
          Estado:Estado,
          TotalFactura:0,
          ValorTotal:0,
          Iva:0,
          TipoCombustible:'Bien',
          
        }
        store.dispatch("setFacturas",FelGenerado) 
       }
}

