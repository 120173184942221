import currency from '@/libs/currency';
import store from '@/store/index'
import axios from "axios"

export function ProcesarXMLventa(archivoxml,estado,IdVenta){
 
  const Estado = estado;
       let patharchivoXML = (window.URL || window.webkitURL).createObjectURL(archivoxml);
   
       let xhttp = new XMLHttpRequest();
       xhttp.onreadystatechange = async function() {
           if (xhttp.readyState == 4 && xhttp.status == 200) {
             // Validamos el estado
             if(Estado==='Emitida'){
               //Procesamos facturas Emitidas
               await procesarItemEmitidos(xhttp)
             }
           }
       };
       //FACTURA COMBUSTIBLE 
       xhttp.open("GET",patharchivoXML, true);
       xhttp.send();

       //Función para procesar los xml
       async function procesarItemEmitidos(xml){
           //Obtenemos el Regimen
           const regimen =store.getters.getRegimen
           //console.log(xml.response)

           let parser = new DOMParser();

           let xmlDoc = parser.parseFromString(xml.response,"text/xml");

          //  if(xmlDoc){
          //   xmlDoc = xml.responseXML;
          //  }
          //  else {
          //   xmlDoc = parser.parseFromString(xml.response,"text/xml");
          //  }


           let dteReceptor = xmlDoc.getElementsByTagName("dte:Receptor")[0];
           let Cliente = dteReceptor.getAttributeNode("NombreReceptor").nodeValue;
           const Nit = dteReceptor.getAttributeNode("IDReceptor").nodeValue;

           let dteFac = xmlDoc.getElementsByTagName("dte:NumeroAutorizacion")[0];
           const Serie = dteFac.getAttributeNode("Serie").nodeValue;
           const NumeroFactura = dteFac.getAttributeNode("Numero").nodeValue;

           //DATOS DEL CLIENTE
           Cliente = Cliente.split(",,")
           Cliente=  Cliente[1]?Cliente[1]+' '+Cliente[0]: Cliente[0]
           Cliente = Cliente.replace(',',' ')
           const nomCliente = Cliente.replace(',',' ')
           const IdCliente = await getProveedor(Nit,nomCliente);
           //Obtenemos Fecha de Emision
           let datosgenerales=xmlDoc.getElementsByTagName("dte:DatosGenerales")[0];
           const FechaFactura=datosgenerales.getAttributeNode("FechaHoraEmision").nodeValue;

            let FelGenerado={
                IdLibroVenta:IdVenta,
                FechaFactura:FechaFactura,
                NumeroFactura:NumeroFactura,
                Serie:Serie,
                IdCliente:IdCliente,
                Cliente:nomCliente,
                Nit:Nit,
                VentaExcenta:0,
                Iva:0,
                EstadoFactura:estado
            }

            FelGenerado.TotalFactura=currency((xmlDoc.getElementsByTagName("dte:GranTotal")[0].childNodes[0].nodeValue),2).value;
            if(regimen=='rg'){
              FelGenerado.TotalSinIVA=currency((FelGenerado.TotalFactura/1.12),2).value;
              FelGenerado.Iva = currency((FelGenerado.TotalFactura/1.12)*0.12,2).value;
            }else{
              FelGenerado.TotalSinIVA=0.00;
              FelGenerado.Iva = currency(FelGenerado.TotalFactura*0.05,2).value;
            }
          
        store.dispatch("setFacturas",FelGenerado)      
       }

       //funcion para buscar el nuevo proveedor
      async function getProveedor(nit,proveedor){
        const persona=store.getters.buscarProveedor(nit)
        let idpersona = 0;
        let headeraxios={
          headers:{'auth-user':store.getters.getToken}
        }
        if(persona){
          idpersona = persona.IdProveedor
        }else{
          await axios.get(`${store.getters.getUrlApi}/personas/${nit}`,headeraxios).then(res=>{        
            if(res.data.length>0){
              idpersona = res.data[0].IdProveedor
            }else{ idpersona = false}
          }).catch(err=>{console.log(err)})       
        }

        if(!idpersona){
         await axios.post(`${store.getters.getUrlApi}/personas`,{Nit:nit,Proveedor:proveedor,Tipo:'Proveedor'},headeraxios).then(res=>{
            //Traemos listado de los proveedor
            idpersona = res.data[0].insertId
            store.dispatch("setTopProveedores")
          }).catch(err=>{
            console.error(err)
          })
        }
        return idpersona
      }
      
}

