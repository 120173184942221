<template>
    <div>
        <div class="tile">
            <div class="tile-title-w-btn">
            <h3 class="title">{{title}}</h3>
            <p><a class="btn btn-primary icon-btn text-white" @click="showform=true"><i class="fa fa-plus"></i></a></p>
            </div>
            <form class="row"  v-on:submit.prevent="save()" v-if="showform">
                <div class="form-group col-md-3">
                <v-text-field v-model="editedItem.usuario" hint="Ingrese el usuario" color="teal" label="Usuario"></v-text-field>
                </div>
                <div class="form-group col-md-4">
                <v-text-field v-model="editedItem.clave" hint="Ingrese la clave" color="teal" label="Clave"></v-text-field>
                </div>
                <div class="form-group col-md-4">
                <v-text-field v-model="editedItem.descripcion" hint="Ejemplo: Clave SAT" color="teal" label="Descripción"></v-text-field>
                </div>
                <div class="form-group col-md-1 align-self-center">
                  <v-btn type="submit" icon color="#0288D1"><v-icon>mdi-check-circle-outline</v-icon></v-btn>
                </div>
            </form>
            <div class="tile-body">
                <v-simple-table v-if="getClaves.length>0">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Usuario</th>
                            <th>Clave</th>
                            <th>Descripción</th>
                            <th>Creación</th>
                            <th>Actualizada el:</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in getClaves" :key="item.idclave">
                        <td>{{index+1}}</td>
                        <td >{{item.usuario}}</td>
                        <td >{{item.clave}}</td>
                        <td >{{item.descripcion}}</td>
                        <td >
                            <v-chip class="ma-2" color="teal" small dark>
                            {{item.fechacreacion | formatoFechas}}
                            </v-chip>
                        </td>
                        <td >
                            <v-chip class="ma-2" color="teal" small dark>
                            {{item.fechaactualizacion | formatoFechas}}
                            </v-chip>
                        </td>
                        <td>
                            <v-btn icon color="teal" @click="editItem(item)"><v-icon>mdi-lead-pencil</v-icon></v-btn>
                            <v-btn icon color="red" @click="deleteSwal(item.idclaves)"><v-icon>mdi-close-circle</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <div v-else>
                    <h3 class="text-center" style="color:#BDBDBD">SIN DATOS</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

export default {
    name:'Claves',
  data(){
    return{
        title:'CREDENCIALES',
        showform:false,
        editedIndex: -1,
        editedItem: {
            usuario:'',
            clave:'',
            descripcion:'',
            fechaactualizacion:dayjs().format('YYYY-MM-DD'),
            idpersona:1
        }
    }
  },
  created(){
      this.$store.dispatch("getClaves")
  },  
  computed:{
    getClaves(){
      return this.$store.getters.getClaves;
    }
  },
  methods:{
      async getItems(){
            await this.$store.dispatch("getClaves");
      },
      async postItem(){
        
        await  this.$store.dispatch("postClaves",this.editedItem);
        await this.getItems()
      },
      async putItem(){
          await this.$store.dispatch("putClaves",this.editedItem);
          await this.getItems()
      },
      editItem(item) {   
          this.showform = true; 
          this.editedIndex = this.getClaves.indexOf(item)       
          this.editedItem = Object.assign({}, item)
      },
      async deleteItem(id){
          await this.$store.dispatch("deleteClaves",id);
          await this.getItems()
      },
     save () {
         this.editedItem.fechaactualizacion=dayjs().format('YYYY-MM-DD')
          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){  
         this.$nextTick(() => {
            this.showform = false;
            this.editedItem = {},
            this.editedIndex = -1
          })
     },
     deleteSwal(id){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(id);
        }
        })
    },
  },
  filters: {
    formatoFechas: function (value) {
        if (!value) return ''
        //value = value.toString()
        return dayjs(value).format('DD-MM-YYYY') //value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
    
}
</script>