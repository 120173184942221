<template>
  <div>
    <main class="app-content">
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <v-row justify="center" no-gutters>
              <v-col cols="6" id="tipodocumento">
                <p id="selectipo">TIPO DOCUMENTO:</p>
                <v-row>
                  <v-col cols="7">
                    <v-radio-group v-model="transaccion.tipo" row>
                      <v-radio
                        v-for="item in tipo"
                        :key="item"
                        :label="`${item}`"
                        :value="item"
                        color="teal"
                        @change="tipoDocumento(item)"
                      >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="transaccion.estado"
                      color="teal"
                      :items="estadoitem"
                      hide-selected
                      label="Estado"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <h3 v-if="transaccion.tipo == 'Recibo'">
                  Código Recibo:
                  <strong style="color:rgb(223, 114, 51)">{{ transaccion.codigo }}</strong>
                </h3>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="error"
                  dark
                  rounded
                  elevation="2"
                  @click="$router.go(-1)"
                  ><v-icon color="white">mdi-close-circle</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <div class="tile-body">
              <v-row v-if="transaccion.idpersona">
                <div id="cliente" class="col-md-12">
                  <v-row>
                    <v-col cols="2">
                      <v-autocomplete
                        :items="categorias"
                        v-model="editedItem.idcategoria"
                        dense
                        color="teal"
                        outlined
                        clearable
                        hide-selected
                        item-text="categoria"
                        item-value="idcategoria"
                        label="Seleccione Tipo"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              <strong>No se contro ningun registro</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedItem.descripcion"
                        label="Descripcion"
                        color="teal"
                        outlined
                        dense
                        autofocus
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        v-model="editedItem.cantidad"
                        label="Cantidad"
                        color="teal"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        v-model="editedItem.precio"
                        label="Precio"
                        color="teal"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        label="Total"
                        color="teal"
                        required
                        :value="editedItem.cantidad * editedItem.precio"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        :items="mediosdepagos"
                        v-model="editedItem.idmedpago"
                        dense
                        color="teal"
                        outlined
                        clearable
                        hide-selected
                        item-text="nombre"
                        item-value="idmediopago"
                        label="Medio de Pago"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              <strong>No se contro ningun registro</strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        fab
                        dark
                        small
                        color="pink"
                        @click="save"
                        v-show="editedItem.cantidad * editedItem.precio > 0"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                    
                  </v-row>
                </div>
                <div id="transacciondetalles" class="col-md-12">
                  <v-row>
                    <v-col cols="12">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Categoria</th>
                              <th class="text-left">
                                Descripcion
                              </th>
                              <th>Medio Pago</th>
                              <th>Cantidad</th>
                              <th class="text-left">
                                Precio
                              </th>
                              <th>
                                Total
                              </th>
                              <th>Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in transaccionItems"
                              :key="item.index"
                            >
                              <td>{{ getNameCategoria(item) }}</td>
                              <td width="40%">{{ item.descripcion }}</td>
                              <td >{{ mediosdepagos.find(medios=>medios.idmediopago ==item.idmedpago).nombre }}</td>
                              <td>{{ item.cantidad }}</td>
                              <td>{{ item.precio }}</td>
                              <td>{{ item.precio * item.cantidad }}</td>

                              <td>
                                <v-btn
                                  icon
                                  color="pink"
                                  @click="popDetail(item)"
                                >
                                  <v-icon>mdi-delete-forever</v-icon></v-btn
                                >
                                <v-btn
                                  icon
                                  color="blue"
                                  @click="editItem(item)"
                                >
                                  <v-icon>mdi-lead-pencil</v-icon></v-btn
                                >
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="4"></td>
                              <td colspan="2"><h4>Total</h4></td>
                              <td>
                                <h4>Q {{ calculoTotales }}</h4>
                              </td>
                            </tr>
                          </tfoot>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <div class="float-right  mr-10">
                    <hr />
                    <v-btn
                      rounded
                      color="green"
                      dark
                      v-show="transaccion.total > 0"
                      @click="generarDocumento"
                      >Pagar</v-btn
                    >
                  </div>
                </div>
              </v-row>
              <!-- SINO SE HA SELECCIONADO CONTRIBUYENTE-->
              <v-row v-else>
                <v-col>
                  <h3>
                    <v-icon class="teal--text mdi-48px"
                      >mdi-arrow-up-bold-circle-outline</v-icon
                    >Primero debe seleccionar un Contribuyente
                  </h3>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>
<script>
import generateXML from "@/libs/xmlFel.js";
import axios from "axios";
import Swal from "sweetalert2";
import currency from "@/libs/currency";
import * as dayjs from "dayjs";
import { mapGetters } from 'vuex'

export default {
  name: "TransaccionDetalle",
  data() {
    return {
      procesardatos:false,
      token: this.$store.getters.getToken,
      titlerouter: "Control de Pagos",
      categorias: [],
      mediopago:[],
      transaccionItems: [],
      editedIndex: -1,
      headers: [
        { text: "Cantidad", value: "cantidad" },
        { text: "Descripcion ", value: "descripcion" },
        { text: "Precio", value: "precio" },
        { text: "Total", value: "total" },
        { text: "Opciones", value: "actions", sortable: false },
      ],
      tipo: ["Recibo", "FEL"],
      estadoitem: ["Cancelado", "Pendiente"],
      //Transaccion
      transaccion: {
        codigo: "", //será la serie
        numero:'',
        autorizacion:'',
        fecha: dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
        idpersona: this.$store.getters.getCliente,
        idusuario: this.$store.getters.getCurrentUser.IdUsuario,
        descuento: 0,
        total: "",
        tipo: "Recibo",
        estado: "Cancelado",
      },
      cleartransaccion: {
        codigo: "", //será la serie
        numero:'',
        autorizacion:'',
        fecha: dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
        idpersona: this.$store.getters.getCliente,
        idusuario: this.$store.getters.getCurrentUser.IdUsuario,
        descuento: 0,
        total: 0,
        tipo: "Recibo",
        estado: "Cancelado",
      },
      //DetallesTransaccion
      editedItem: {
        cantidad: 1,
        precio: 0,
        periodo: "",
        descripcion: "",
        idcategoria: 1,
        idtransaccion: "",
        idmedpago:1
      },
      defaultItem: {
        cantidad: 1,
        precio: 0,
        periodo: "",
        descripcion: "",
        idtransaccion: "",
        idmedpago:1
      },
    };
  },
  created() {
    this.getCategorias();
    this.codigotransaccion();
  },
  computed: {
    ...mapGetters(['listadoContribuyentes','getUrlCertificacion']),
    inputvalidos() {
      if (
        this.editedItem.Nombre.length > 5 &&
        this.editedItem.Clave.length > 5 &&
        this.editedItem.Login.length > 5
      ) {
        return true;
      }
      return false;
    },
    calculoTotales() {
      return this.calculos();
    },
    mediosdepagos(){
      return this.$store.getters.getTransaccionMedioPagos
    }
  },
  methods: {
    getCategorias() {
      axios
        .get(`${this.$store.state.urlapi}/pagos/categorias`, {
          headers: { "auth-user": this.token },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.categorias = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Calculos
    calculos() {
      
      this.transaccion.total = currency(
        this.transaccionItems.reduce(
          (acumulado, item) =>
            acumulado + parseFloat(item.cantidad * item.precio),
          0
        ),
        2
      ).value;
      return this.transaccion.total;
    },
    //GenerarCodigo
    codigotransaccion() {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      for (let i = 0; i < 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.transaccion.codigo = result;
    },
    //Verificamos tipo de documento
    tipoDocumento(tipo) {
      if (tipo === "Factura") {
        this.transaccion.codigo = 3601;
        axios
          .get(`${this.$store.state.urlapi}/pagos/transaccion/ultimafactura`, {
            headers: { "auth-user": this.token },
          })
          .then((response) => {
            if (response.status == 200) {
              this.transaccion.codigo = parseInt(response.data[0].codigo) + 1;
            }
          })
          .catch((error) => {
            console.log(error);
            this.errorSwal();
          });
      } else {
        this.codigotransaccion();
      }
    },
    editItem(item) {
      this.editedIndex = this.transaccionItems.indexOf(item);
      const arreglo = item.descripcion.split('-');
      item.descripcion = arreglo[1].trim();
      this.editedItem = Object.assign({}, item);
    },
    async generarDocumento() {
      if (!this.procesardatos) {
        this.procesardatos = true;
        
        //DATOS FEL
        if (this.transaccion.tipo === "FEL") {
          const establecimiento = this.$store.getters.getEstablecimiento;
          const cliente = this.$store.getters.getCliente;
          const contribuyente = this.listadoContribuyentes.find(element=>element.idpersona == cliente);
          let felgenerado = await generateXML(
            establecimiento,
            contribuyente,
            this.transaccionItems,
            this.transaccion.total
          );
          this.validarDTE(felgenerado);
          //FIN DATOS FEL
        } else {
          //Post Crear Venta
          await this.postTransaccion();
        }
      }
    },

    async validarDTE(xml) {
    
    try {
      this.loadingSwal()
      const factura = await axios.post(
        `${this.getUrlCertificacion}/fel/felSAT.php?op=validarFel`,
        { xmlfel: xml }
      );
      if (factura.data.Codigo == 1) {
        Swal.hideLoading()
        this.transaccion.fecha = factura.data.Fecha_DTE;
        this.transaccion.codigo = factura.data.Serie;
        this.transaccion.numero = parseInt(factura.data.NUMERO);
        this.transaccion.autorizacion = factura.data.Autorizacion;
        //Post Crear Venta
        await this.postTransaccion();
      }else{
        Swal.hideLoading()
        this.procesardatos = false;
        this.errorSwal(factura.data.Mensaje);
      }
    } catch (error) {
        this.procesardatos = false;
        this.errorSwal(error);
    }
      

    },

    async postTransaccion() {
      await axios
        .post(
          `${this.$store.state.urlapi}/pagos/transaccion`,
          this.transaccion,
          { headers: { "auth-user": this.token } }
        )
        .then((response) => {
          if (response.status == 200) {
            this.postTransaccionItems(response.data.insertId);
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorSwal();
        });
    },
    async postTransaccionItems(insertId) {
      this.transaccionItems.forEach((element) => {
        element.idtransaccion = insertId;
        axios.post(
          `${this.$store.state.urlapi}/pagos/transacciondetalle`,
          element,
          { headers: { "auth-user": this.token } }
        );
      });
      this.$router.go(-1);
      this.exitoSwal('Transacción Realizada');
    },
    pushDetail() {
      this.editedItem.descripcion = `${this.getNameCategoria(this.editedItem)} - ${this.editedItem.descripcion}`
      this.transaccionItems.push(this.editedItem);
    },
    popDetail(item) {
      let indice = this.transaccionItems.indexOf(item);
      this.transaccionItems.splice(indice, 1);
    },
    //Obtenemos el nombre de la categoria por su idcategoria
    getNameCategoria(item) {
      let dato = this.categorias.find((elemento) => {
        return elemento.idcategoria === item.idcategoria;
      });
      return dato.categoria;
    },
    close() {
      this.$nextTick(() => {
        this.defaultItem.idcategoria = this.editedItem.idcategoria 
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.editedItem.descripcion = `${this.getNameCategoria(this.editedItem)} - ${this.editedItem.descripcion}`
        Object.assign(this.transaccionItems[this.editedIndex], this.editedItem)
        this.exitoSwal('Registro Actualizado')
      } else {
        this.pushDetail();
        // this.guardarRegistro();
      }
      this.close();
    },

    //NOTIFICACIONES SWAL ALERT
    exitoSwal(message) {
      Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorSwal() {
      Swal.fire({
        icon: "error",
        title: "Ocurrio un Error :(",
        text: "Si el error persiste contacte a soporte técnico",
      });
    },
    loadingSwal(){
      Swal.fire({
        title: 'Procesando Factura!',
        html: 'La factura se esta procesando por favor espere...',
        timerProgressBar: true,
        onBeforeOpen: () => {
                    Swal.showLoading()
        },
      })
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.Bold {
  font-weight: bold;
}
p {
  margin: 0 0 0 0;
  padding: 0;
}
#tipodocumento {
  border-radius: 25px;
  border: 2px solid rgb(12, 136, 115);
  padding-left: 25px;
  padding-right: 25px;
}
#selectipo {
  background-color: rgb(12, 136, 115);
  color: white;
  width: 30%;
  padding: 3px;
  border-radius: 5px;
  margin-top: -10px;
}
</style>
