<template>
  <div>
    <main class="app-content">
      <div class="row">
        <div class="col-md-12 bg-white">
          <br>
          <v-data-table
          dense
            :headers="headers"
            :items="itemslist"
            disable-pagination
            hide-default-footer
            :search="search"
            sort-by="fecha"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar..."
                  single-line
                  color="teal"
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <!--FECHA INICIO-->
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechas.fechainicio"
                      label="Fecha inicio"
                      prepend-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="teal"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechas.fechainicio"
                    @input="menu = false"
                    locale="es-GT"
                    @change="validarfechas"
                    color="teal"
                  ></v-date-picker>
                </v-menu>
                <!--FECHA FIN-->
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      ref="fechafin"
                      v-model="fechas.fechafin"
                      label="Fecha Fin"
                      prepend-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="teal"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechas.fechafin"
                    @input="menu2 = false"
                    locale="es-GT"
                    color="teal"
                    @change="validarfechas"
                  ></v-date-picker>
                </v-menu>
              </v-toolbar>

              <v-btn color="blue" class="ml-5 text-white" @click="printPDF" id="btnprint01">
                <v-icon color="white" class="mr-2"
                  >mdi-cloud-print-outline</v-icon
                >
                Imprimir</v-btn
              >
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              <p>{{item.fecha}}</p>
            </template>
            <template v-slot:[`item.cliente`]="{ item }">
             <b>{{item.cliente}}</b> {{item.categoria}} {{item.descripcion}}
            </template>
            <template v-slot:no-data>
              <h1>No se encontrarón datos en estas fechas</h1>
              <h5>Seleccione otro rango de fechas</h5>
            </template>
          </v-data-table>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from 'dayjs';
import customParseFormat  from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
export default {
  data: () => ({
    title: "REPORTE PAGOS",
    search: "",
    menu: false,
    menu2: false,
    modalfechafin: false,
    fechas:{
        fechainicio:dayjs().format('YYYY-MM-DD'),
        fechafin: dayjs().format('YYYY-MM-DD'),
    },
    headers: [
      { text: "Fecha", value: "fecha",width: '10%' },
      { text: "Codigo", align: "center", value: "codigo" },
      { text: "Nit", align: "center", value: "nit" },
      { text: "Descripcion", align: "left", value: "cliente" },
      { text: "Total", align: "center", value: "total" },
      { text: "Medio de Pago", align: "left", value: "mediopago" },
      { text: "Estado", align: "center", value: "estado"},
    ],
    itemslist: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    validarfechas() {
      this.modal = false;
      this.modalfechafin = false;
      
      if (this.fechas.fechafin < this.fechas.fechainicio) {
        Swal.fire("Error!! Fecha Fin no puede ser menor a fecha inicio");
        Swal.fire({
          icon: "error",
          title: "Error rango de fechas",
          text: "Error!! Fecha Fin no puede ser menor a fecha inicio",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs.fechafin.focus();
      } else {
        
        this.initialize();
      }
    },
    initialize() {
        let token = this.$store.getters.getToken
      axios.post(`${this.$store.state.urlapi}/pagos/transaccion/porfechas`,this.fechas,{headers:{'auth-user':token}}).then((response) => {
         if(response.status===200){          
             this.itemslist = response.data;
         }
        });
       
    },

    printPDF() {
      //Ocultamos el Boton de Imprimir encabezados
      document.getElementById("btnprint01").style.visibility="hidden"
      
      window.print()
      //Mostramos el Boton DESPUES de Imprimir
      document.getElementById("btnprint01").style.visibility="visible"
    },
    //Notificaciones Swal
    alertDone(message) {
      Swal.fire({
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorSwal(message) {
      Swal.fire({
        icon: "error",
        title: message,
      });
    },
  },
};
</script>