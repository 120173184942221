<template>
    <div>
        <!-- <a href="#" class="dropdown-item" data-action="app-fullscreen">
    <span data-i18n="drpdwn.fullscreen">Pantalla Completa</span>
    <i class="float-ight text-muted fw-n">F11</i>
</a> -->
        <div class="tile">
            <div class="tile-title-w-btn">
            <h3 class="title">{{title}}</h3>
            <p><a class="btn btn-primary icon-btn text-white" @click="showform=true"><i class="fa fa-plus"></i></a></p>
            </div>
            <form class="row"  v-on:submit.prevent="save()" v-if="showform">
                <div class="form-group col-md-5">
                <v-text-field v-model="editedItem.tipo" hint="Ejemplo: correo,telefono,trabajo" color="teal" label="Tipo"></v-text-field>
                </div>
                <div class="form-group col-md-5">
                <v-text-field v-model="editedItem.valor" hint="Ingrese el valor del contacto" color="teal" label="Contacto"></v-text-field>
                </div>
                <div class="form-group col-md-2 align-self-center">
                  <v-btn type="submit" icon color="#0288D1"><v-icon>mdi-check-circle-outline</v-icon></v-btn>
                </div>
            </form>
            <div class="tile-body">
                <v-simple-table v-if="getContactos.length>0">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Tipo</th>
                            <th>Valor</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in getContactos" :key="item.idcontacto">
                        <td>{{index+1}}</td>
                        <td >{{item.tipo}}</td>
                        <td >{{item.valor}}</td>
                        <td>
                            <v-btn icon color="teal" @click="editItem(item)"><v-icon>mdi-lead-pencil</v-icon></v-btn>
                            <v-btn icon color="red" @click="deleteSwal(item.idcontacto)"><v-icon>mdi-close-circle</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <div v-else>
                    <h3 class="text-center" style="color:#BDBDBD">SIN DATOS</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
  data(){
    return{
        title:'AGENDA',
        showform:false,
        editedIndex: -1,
        editedItem: {
            tipo:'',
            valor:'',
            idpersona:1
        }
    }
  },
  created(){
      this.getItems();
  },  
  computed:{
    getContactos(){
      return this.$store.getters.getContactos;
    }
  },
  methods:{
      async getItems(){
            await this.$store.dispatch("getContacto");
      },
      async postItem(){
        await  this.$store.dispatch("postContacto",this.editedItem);
        await this.getItems()
      },
      async putItem(){
          await this.$store.dispatch("putContacto",this.editedItem);
          await this.getItems()
      },
      editItem(item) {   
          this.showform = true; 
          this.editedIndex = this.getContactos.indexOf(item)       
          this.editedItem = Object.assign({}, item) 
      },
      async deleteItem(id){

        await this.$store.dispatch("deleteContacto",id);
        await this.getItems()
            
      },
     save () {
          if (this.editedIndex > -1) {
                this.putItem();
          } else {
               this.postItem(); 
          }
          this.close()
     },
     async close(){        
         this.$nextTick(() => {
            this.showform = false;
            this.editedItem = {},
            this.editedIndex = -1
          })
     },
     deleteSwal(id){
        Swal.fire({
        title: 'Presione Sí para eliminar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Sí'
        }).then((result) => {
        if (result.isConfirmed) {
            this.deleteItem(id);
        }
        })
    },
  }
}
</script>