<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div>
          <h1><v-icon color="black">mdi-folder-table-outline </v-icon> Establecimientos</h1>         
          <p>Listado de todos los establecimientos</p>
        </div>
        <ul class="app-breadcrumb breadcrumb">          
          <v-btn class="white--text" color="#009688" data-toggle="modal" data-target="#modalRegistro">
              <v-icon left>mdi-plus-outline</v-icon>
              NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <template>
              <v-tabs color="blue darken-3">
                <v-tab @click="tabRegimen=1,getItems(1)">REGIMEN GENERAL</v-tab>
                <v-tab @click="tabRegimen=2,getItems(2)">PEQUEÑO CONTRIBUYENTE</v-tab>
              </v-tabs>
            </template>
            <v-divider></v-divider>
              <!-- DATA TABLES-->
            <template>
            <v-card>
                <v-card-title>
                Establecimientos
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    color="teal"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>
                <v-data-table
                :headers="headers"
                :items="getEstablecimientos"
                :search="search"
                >
                <template v-slot:[`item.Estado`]="{item}">
                    <span class="badge" :class="getcondicion(item.Estado)"> {{item.Estado}}</span>
                   
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="btn-group btn-group-toggle" data-toggle="buttons">
                        <button class="btn btn-info btn-sm"  @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white">mdi-lead-pencil</v-icon></button>                      
                        <button class="btn btn-danger btn-sm" @click="eliminarItem(item)"  ><v-icon class="text-white">mdi-delete</v-icon></button>
                    </div>       
                </template>
                <template v-slot:no-data>
                    <button class="btn btn-primary text-white" type="button" @click="getItems(1)">Cargar Información</button>
                </template>
                </v-data-table>
            </v-card>
            </template>
          </div>
        </div>
      </div>
<!-- Modal -->
<div class="modal fade" id="modalRegistro" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalRegistroLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalRegistroLabel">{{formTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          
            <v-text-field  v-model="editedItem.Establecimiento" label="Nombre establecimiento" color="blue" outlined dense required></v-text-field>           
 
           <v-autocomplete  
              v-model="editedItem.IdPersona"
              :items="contribuyente"
              item-text='Nombre'
              item-value='idpersona'
              label="Seleccione Contribuyente"  
              hide-selected                       
              outlined
              dense
              color="blue"
              required>
            </v-autocomplete>
           
            <v-autocomplete  
              v-model="editedItem.IdRegimen"
              :items="regimen"
              item-text='Regimen'
              item-value='IdRegimen'
              label="Seleccione Regimen"  
              hide-selected                       
              outlined
              dense
              color="blue"
              required>
            </v-autocomplete>                
          <v-textarea  v-model="editedItem.Descripcion" color="blue" outlined dense>
              <template v-slot:label>
                <div>
                  Observaciones <small>(opcional)</small>
                </div>
              </template>
            </v-textarea>     
             <div class="form-group">
              <label>Cambiar Estado</label>
              <select class="form-control" v-model="editedItem.Estado">
                <option value="Activo">Activo</option>
                <option value="Inactivo">Inactivo</option>
              </select>
            </div> 
        </form>  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary white--text" data-dismiss="modal" @click="close">Cancelar</button>
        <button type="button" class="btn btn-primary white--text" data-dismiss="modal" v-show="inputvalidos" @click="save">Guardar</button>
      </div>
    </div>
  </div>
</div>

    </main>
    </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2'

export default {
    name:'Establecimiento',
    data() {
        return {
          urlapi:this.$store.getters.getUrlApi,
          token:this.$store.getters.getToken,
          regimen:[
            {IdRegimen:1,Regimen:'Regimen General Normal'},
            {IdRegimen:2,Regimen:'Pequeño Contribuyente'},            
            ],
                      
            tabRegimen: 1,
            stepform:0,
            editedIndex: -1,
            search: '',
            headers: [
            {
                text: 'Nit',
                align: 'start',
               // sortable: false,
                value: 'Nit',
            },
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Establecimiento', value: 'Establecimiento' },
            { text: 'Estado', value: 'Estado' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],
        editedItem: {
          Establecimiento:'',
          Descripcion:'',
          IdPersona:'',
          IdRegimen:1,
          Estado:'Activo'
        },
        defaultItem: {
          Establecimiento:'',
          Descripcion:'',
          IdPersona:'',
          IdRegimen:1,
          Estado:'Activo'
        },
        contribuyente:[],
       }
    },
    created() {
        this.getItems(this.tabRegimen)
        this.getContribuyentes()
    },
    computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
        },
        inputvalidos(){
            if(this.editedItem.Establecimiento.length>3){
                return  true
            }
            return false
        }, 
        getEstablecimientos(){
          return this.$store.getters.getItems.length>0?this.$store.getters.getItems:[]
        }      
    },
    methods: {
        getItems(tabRegimen){
          this.$store.dispatch("getItems",tabRegimen)
              
        },
        getContribuyentes(){
            axios.get(`${ this.urlapi}/contribuyentes`,{headers:{'auth-user': this.token}})
              .then(response=>{
                  this.contribuyente = response.data;
                  //Recorremos el arreglo para eliminar los inactivos
                  for (let index = 0; index < this.contribuyente.length; index++) {
                    const estado = this.contribuyente[index].Estado;
                   if(estado=='Inactivo'){
                     this.contribuyente.splice(index,1)
                   }
                  }
              }).catch(error=>{
                  console.log(error)
            }) 
            
        },
        editItem(item){
          this.editedIndex = this.$store.getters.getItems.indexOf(item)
          this.editedItem = Object.assign({}, item)
        },
        getcondicion (condicion) {
          if (condicion =='Inactivo') return 'badge-danger'
          else return 'badge-success'
        },
        guardarRegistro(){
                
               axios.post(`${ this.urlapi}/establecimientos/`,this.editedItem,{headers:{'auth-user': this.token}}).then(response=>{
                   if(response.status==201){
                       this.getItems(this.tabRegimen);
                        this.exitoSwal()
                   }else{
                       this.errorSwal()
                   }
               }) 
        },
        editarRegistro(){
         const id = this.editedItem.IdEstablecimiento
         const formData ={
             Establecimiento:this.editedItem.Establecimiento,
             Descripcion:this.editedItem.Descripcion,
             IdPersona:this.editedItem.IdPersona,
             IdRegimen:this.editedItem.IdRegimen,
             Estado:this.editedItem.Estado
         }     
            axios.put(`${ this.urlapi}/establecimientos/${id}`,formData,
            {headers:{'auth-user': this.token}}).then(()=>{
                this.getItems(this.tabRegimen);
                this.exitoSwal()
            })
        },
        close () {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        eliminarItem(item){
          this.editItem (item);
          this.editedItem.Estado ='Eliminado';
          Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.editarRegistro();
            }
          })      
                
        },
        save () {
          if (this.editedIndex <0) {
            this.guardarRegistro();
          } else {
            this.editarRegistro();
          }
          this.close()
        },
      //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        }
    },
}
</script>

<style>

@import url(https://cdn.syncfusion.com/ej2/material.css);
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    width: 100%;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }

</style>