import axios from "axios";
export default{
    state:{
        dataitems:[]
    },
    mutations:{
        setDataItems(state,items){
            state.dataitems=items;
        }
    },
    actions:{
        setDataItems({commit,rootState}){
            axios.get(`${rootState.urlapi}/pagos/medio`,{headers:{'auth-user':rootState.Usuario.token}}).then(res=>{
                commit("setDataItems",res.data)
            })
        }
    },
    getters:{
        getTransaccionMedioPagos(state){
            return state.dataitems
        }
    }
}