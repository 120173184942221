<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div class="col-md-6">
          <v-autocomplete
            :items="getItemsActivos"
            v-model="selecteditem"
            dense
            color="#17a2b8"
            outlined
            clearable
            hide-details
            hide-selected
            item-text="Negocio"
            item-value="IdEstablecimiento"
            label="Buscar Nit ..."        
            @change="init()"     
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong>No se contro ningun registro</strong>
              </v-list-item-title>
            </v-list-item>
          </template>          
          </v-autocomplete>
        </div>
        <ul class="app-breadcrumb breadcrumb">
         
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
           
          <template v-if="!$store.getters.getSelectedItem">
            <h3>No ha seleccionado ningún Nit <v-icon class="amber--text mdi-48px">mdi-emoticon-confused-outline</v-icon></h3>
          </template>
          <template v-else>
            <!-- Series vencidas!-->
              <div v-for="item in getSeriesVencimiento" :key="item.IdSerie" id="vencimientoseries">
                <v-alert dense border="left" type="warning" v-if="item.Diasrestantes>0">
                  La serie <strong>{{item.Serie}}</strong> vencerá en  <strong> {{item.Diasrestantes}}</strong> dias.
                </v-alert>
                <v-alert dense border="left" type="error" v-else>
                  La serie <strong>{{item.Serie}}</strong> se encuentra <strong> VENCIDA </strong>.
                </v-alert>
              </div>
            <v-tabs v-model="tab" fixed-tabs background-color="blue" dark>
              
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab href="#tab-1" @click="rutas('/rg/ventas')">
                Ventas
              </v-tab>
              <v-tab href="#tab-2" @click="rutas('/rg/compras')">
                Compras
              </v-tab>             
              <v-tab href="#tab-3" @click="rutas('/rg/series')">
                Serie
              </v-tab>
            </v-tabs>
            <div>
              <!-- INICIO TAB 1-->  
              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-2">
                    <div id="ventas">
                        <v-card>
                            <v-card-title>
                              <v-btn class="mx-2" fab dark small color="blue" data-toggle="modal" data-target="#modalRegistro" @click="editItem(0)">
                                <v-icon dark>mdi-plus</v-icon>
                              </v-btn>
                              LISTADO COMPRAS
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                color="teal"
                                single-line
                                hide-details
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :headers="headers"
                            :items="dataitems"
                            :items-per-page=5
                            :search="search"
                            locale="es_ES"
                            >
                            <template v-slot:[`item.Folio`]="{ item }">
                              <p>{{item.Folio +' - '+item.FinFolio}}</p>
                            </template>  
                            <template v-slot:[`item.Establecimiento`]="{ item }">
                              <h6 style="margin:0;padding:0">{{item.Establecimiento}}</h6>
                              <p style="margin:0;padding:0">{{item.Nombre}}</p>
                            </template>            
                            <template v-slot:[`item.TotalSinIva`]="{ item }">
                              <h5><span class="badge badge-primary" v-show="item.TotalSinIva>0">Q {{item.TotalSinIva}}</span></h5>
                              <h6><span class="badge badge-danger" v-show="item.TotalSinIva<1">Sin movimiento</span></h6>
                            </template>
                            <template v-slot:[`item.IVA`]="{ item }">
                              <h5><span class="badge badge-primary" v-show="item.IVA>0">Q {{item.IVA}}</span></h5>
                              <h6><span class="badge badge-danger" v-show="item.IVA<1">Sin movimiento</span></h6>
                            </template>
                            <template v-slot:[`item.Total`]="{ item }">
                              <h5><span class="badge badge-primary" v-show="item.Total>0">Q {{item.Total}}</span></h5>
                              <h6><span class="badge badge-danger" v-show="item.Total<1">Sin movimiento</span></h6>
                            </template>
                            <template v-slot:[`item.Periodo`]="{ item }">
                              <p>{{formatFecha(item)}}</p>
                            </template>                 
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <button class="btn btn-info btn-sm"  @click="detallecompra(item)"><v-icon class="text-white mdi-18px">mdi-note-plus-outline</v-icon></button>                      
                                    <button class="btn btn-warning btn-sm" @click="printPDF(item.IdCompra)"><v-icon class="mdi-18px">mdi-printer-check</v-icon></button>                      
                                    <button class="btn btn-primary btn-sm" @click="getTopProveedores(item)"><v-icon class="text-white mdi-18px">mdi-clipboard-text-outline</v-icon></button>                      
                                    <button class="btn btn-danger btn-sm" @click="deleteSwal(item.IdCompra)"  ><v-icon class="text-white mdi-18px">mdi-trash-can-outline</v-icon></button>
                                    <button class="btn btn-primary active btn-sm" @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white mdi-18px">mdi-order-bool-ascending</v-icon></button>
                                </div>       
                            </template>
                            <template v-slot:no-data>
                                <button class="btn btn-primary text-white" type="button">No hay compras registradas</button>
                            </template>
                            </v-data-table>
                        </v-card>
                      </div>
                </v-tab-item>        
              </v-tabs-items>
            </div>
          
          </template>
          
          </div>
        </div>
      </div>
    </main>
<!-- Modal Regimen-->
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" >
      <v-card>
        <v-card-title class="text-white teal">
          {{formTitle}}
        </v-card-title>
        <v-card-text>
         <v-container>
           <v-row>
             <v-col cols="6">
                <v-col cols="12">
                    <v-text-field color="blue" :value="getEstablecimiento" disabled outlined label="Establecimiento*" required></v-text-field>
                </v-col>               
                <v-col cols="12">
                    <v-text-field color="blue" v-model="editedItem.Folio" outlined label="Folio*" placeholder="1000" required></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field color="blue" v-model="editedItem.Periodo" outlined label="Periodo*" disabled placeholder="Enero" required></v-text-field>
                </v-col>
             </v-col>
             <v-col cols="6">
                <v-col>
                  <p>Seleccione periodo haciendo Clic en el mes</p>
                <v-date-picker
                    v-model="editedItem.Periodo"
                    :show-current="false"
                    header-color="teal"
                    color="red"
                    type="month"
                    locale="es"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                  ></v-date-picker>  
                </v-col>            
             </v-col>
         
           </v-row>          
         </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal text-white"  @click="save">
            Guardar
          </v-btn>
          <v-btn color="grey darken-2 text-white"  @click="close(), dialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
<!-- Modal Proveedores-->
  <div class="text-center">
    <v-dialog v-model="dialog2" width="70%">
      <v-card>
        <v-card-title class="text-white teal">
          Listado de Proveedores
        </v-card-title>
        
        <v-card-text>
          <v-divider></v-divider>
            <v-chip-group
            label
            active-class="red--text text--accent-4"
            mandatory
          >
            <v-chip>Facturas recibidas: <h4> {{getTotalFacRecibidas}} </h4> </v-chip>
            <v-chip>Total Proveedores del mes: <h4> {{getTopProveedoresCompras.length}} </h4></v-chip>
            <v-chip>Total Combustible Sin Iva:<h4> Q{{getSumaFacCombustible}}</h4> </v-chip>
          </v-chip-group>
        <v-divider></v-divider>
          <v-data-table
            :headers="headers2"
            :items="getTopProveedoresCompras"
            :items-per-page="10"
            dense
            class="elevation-1"
          ></v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey text-white" @click="dialog2 = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

    </div>
</template>
<script>

import axios from "axios"
import * as dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {mapGetters} from 'vuex'

export default {
  name:'LibroComprasRG',
  data() {
    return {
      dialog:false,
      dialog2:false,
      urlapi:this.$store.getters.getUrlApi,
      token:this.$store.getters.getToken,   
      tab:'tab-2',        
      dataitems:[],
      search: '',
      nit:'',
      idestablecimiento:this.$store.getters.getSelectedItem.IdEstablecimiento,
      headers2:[
        { text: 'Nit', value: 'Nit'},
        { text: 'Proveedor', value: 'Proveedor'},
        { text: 'Cantidad Facturas', value: 'CantidadFacturas'},
        { text: 'Total Sin Iva', value: 'TotalSinIva'},
        { text: 'Iva', value: 'TotalIVA'},
      ],
      headers: [
            {text: 'Nit', align: 'start',value: 'Nit'},
            { text: 'Establecimiento', value: 'Establecimiento' },
            { text: 'Folio', value: 'Folio' },
            { text: 'Periodo', value: 'Periodo' },
            { text: 'Total Sin Iva', value: 'TotalSinIva'},
            { text: 'Iva', value: 'IVA' },
            { text: 'Total', value: 'Total' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],     
      editedIndex: -1,
      editedItem:{
        IdEstablecimiento:0,
        Folio:0,
        Periodo:dayjs().subtract(1, 'months').format('YYYY-MM'),
        FechaCreacion:dayjs().format('YYYY-MM-DD'),
      },
      defaultItem:{
        IdEstablecimiento:0,
        Folio:0,
        Periodo:dayjs().subtract(1, 'months').format('YYYY-MM'),
        FechaCreacion:dayjs().format('YYYY-MM-DD'),
      },
    }
  },
  created() {
   if(this.$store.getters.getSelectedItem){
     this.init()
   }
   
  },
  computed:{
    formTitle () {
          return this.editedIndex === -1 ? 'Nueva Compra' : 'Editar Compra'
        },
    ...mapGetters(["getItemsActivos","getTopProveedoresCompras","getTotalFacRecibidas","getSumaFacCombustible"]),
     selecteditem: {
        get () {
          return this.$store.getters.getSelectedItem
        },
        set (value) {
          this.$store.dispatch('setSelectedItem', value)
        }
      },
      getSeries(){
        return this.$store.getters.getSeries
      },
      getSeriesVencimiento(){
        return this.$store.getters.getvencimiento
      },
      getEstablecimiento(){
        return this.$store.getters.getSelectedItem?this.$store.getters.getSelectedItem.Establecimiento:'Error al cargar negocio'
      },
  },
    methods: {
        init(){
          const item=this.$store.getters.getSelectedItem
          if(item){
            axios.get(`${this.urlapi}/compras/${item.IdEstablecimiento}`,{headers:{'auth-user':this.token}}).then(res=>{ 
              if(res.data.length>0){
                this.dataitems=res.data
                
              }else{this.dataitems=[]}       
              this.$store.dispatch("setTopProveedores")
            })
          }
        },
        formatFecha(item){
          return dayjs(item.Periodo).add(1, 'day').format('MMMM YYYY').toUpperCase()
        },
        editItem(item) { 
          this.editedIndex = this.dataitems.indexOf(item) 
           if (this.editedIndex <0) {
             this.getFolio()
             this.dialog=true 
          } else { 
                      
            this.editedItem = Object.assign({}, item)
            this.editedItem.Periodo = dayjs(item.Periodo).add(1,'day').format('YYYY-MM');
            this.dialog=true      
          }
          
        },
        close() {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            this.dialog=false
          })
        },
        save () {
          if (this.editedIndex <0) {
             this.guardarRegistro();  
             
          } else {
             this.editarRegistro();                 
          }
          
          this.close()
        },
        //Metodo Guardar
        guardarRegistro(){     
          this.editedItem.Periodo=dayjs(this.editedItem.Periodo).format('YYYY-MM-DD')
          this.editedItem.IdEstablecimiento = this.idestablecimiento     
          axios.post(`${this.urlapi}/compras/`,this.editedItem,{headers:{'auth-user': this.token}})
            .then(()=>{
                this.init();
                this.exitoSwal('¡Realizado!','')
              }).catch(()=>{this.errorSwal('Error al crear compra','')}) 
        },
        editarRegistro(){
          this.editedItem.Periodo=dayjs(this.editedItem.Periodo).format('YYYY-MM-DD')
          this.editedItem.IdEstablecimiento = this.idestablecimiento   
       
          axios.put(`${this.urlapi}/compras/${this.editedItem.IdCompra}`,this.editedItem,{headers:{'auth-user': this.token}})
            .then(()=>{
                this.init();
                this.exitoSwal('¡Realizado!','')
              }).catch(()=>{this.errorSwal('Error al actualizar compra','')})  
        }, 
        eliminarItem(Id){
          axios.delete(`${this.urlapi}/compras/${Id}`,{headers:{'auth-user': this.token}})
              .then(()=>{
                this.init()
                this.exitoSwal()         
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        getFolio(){  
          let idestablecimiento = this.$store.getters.getSelectedItem.IdEstablecimiento
          axios.get(`${this.urlapi}/compras/folio/${idestablecimiento}`,{headers:{'auth-user': this.token}}).then(res=>{     
            console.log(res.data[0])     
            if(res.status==200){
              this.editedItem.Folio = res.data[0].SiguienteFolio
              //Calculando automaticamente el siguiente periodo a trabajar
                let ultimafecha = dayjs(res.data[0].Periodo)
                this.editedItem.Periodo = dayjs(ultimafecha).add(2, 'months').format('YYYY-MM')
              }else{
                this.editedItem.Folio=1
                }
          })
        },
        detallecompra(item){
          const dataventas= this.$store.getters.getVentas.find(elemento =>elemento.Fecha==item.Periodo);
          this.$store.dispatch("setCompra",item)
          this.$store.dispatch("setVentasSeleccionada",dataventas)
          this.$router.push('/rg/facturascompras')
        }, 
        //Obtenemos el Top de Proveedores de librocompras
        getTopProveedores(item){
          
          this.$store.dispatch("setTopProveedoresCompras",item);
          this.dialog2=true;
        },      
        rutas(path){
         const rutaActual = this.$route.path;
          //Validamos que se pidan rutas diferentes
         if(rutaActual !==path){this.$router.push(path)}
       },
       //NOTIFICACIONES SWAL
       exitoSwal(titulo,texto){
         Swal.fire({
          icon: 'success',
          title: titulo,
          text:texto,
          showConfirmButton: false,
          timer: 2000
          }) 
       },
       errorSwal(titulo,texto){
         Swal.fire({
          icon: 'error',
          title: titulo,
          text:texto,
          }) 
       },
       deleteSwal(Id){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(Id);
            }
          })
       },
       printPDF(idcompra){
        window.open("/reportes/reportecompras.php?id="+idcompra,"blank")
      },    
    },
  }
</script>

<style scoped>
a{
text-decoration: none;
}
.Bold { font-weight: bold; }
</style>