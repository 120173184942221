<template>
    <div>
      <main class="app-content">        
        <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <v-row class="text-center">
                <v-col>
                <v-card class="title pa-2" elevation="2">            
                  <v-row class="pt-3">
                    <v-col sm="10">
                      <v-text-field label="Nit | Establecimiento" outlined color="teal" dense
                      :value="establecimiento"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2">
                      <v-btn active-class="red" color="teal" dark rounded  elevation="2" @click="printPDF($store.getters.getIdVenta)"><v-icon color="white">mdi-printer</v-icon></v-btn>
                      <v-btn color="error" dark rounded elevation="2" @click="$router.go(-1)"><v-icon color="white">mdi-close-circle</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pa-0">
                    <v-col sm="2" class="pa-1">
                    <v-text-field label="Serie" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Serie"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Folio" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Folio"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="2" class="pa-1">
                      <v-text-field label="Periodo" outlined color="teal" dense
                      :value="periodo"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Total sin IVA" outlined color="teal" dense
                      :value="$store.getters.getIdVenta.Total"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col sm="3" class="pa-1">
                      <v-text-field label="Impuesto (IVA)" outlined color="teal" dense
                      :value="totalPeriodoIva"
                      readonly
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <!-- Series vencidas!-->
                      <div v-for="item in getSeriesVencimiento" :key="item.IdSerie" id="vencimientoseries">
                        <v-alert dense border="left" type="warning" v-if="item.Diasrestantes>0">
                          La serie <strong>{{item.Serie}}</strong> vencerá en  <strong> {{item.Diasrestantes}}</strong> dias.
                        </v-alert>
                        <v-alert dense border="left" type="error" v-else>
                          La serie <strong>{{item.Serie}}</strong> se encuentra <strong> VENCIDA </strong>.
                        </v-alert>
                      </div>
                      <v-alert dense border="left" type="warning" color="orange darken-2" v-if="fcrestantes<=100 & regimengeneral">
                          {{message}}
                        </v-alert>
                    </v-col>
                  </v-row>
                </v-card>
                </v-col>
              </v-row>
              <!-- FORMULARIO INGRESO FACTURAS-->
              <v-row class="text-center pt-16" justify="center">
                <v-card class="title pa-2" elevation="1" color="#343a40">
                    <form>
                      <v-row no-gutters class="mb-0" justify="center" id="frmDatos">
                        <v-col sm="1" class="pa-1">
                          <v-text-field label="Día Factura" filled dense
                          v-model="dia"
                          color="light-green"
                          background-color="grey lighten-5"
                          type="number"  name="dia" ref="dia"
                          @keyup.enter="nextFocus"   
                          v-on:keyup.right="$refs.totalfc.focus()"               
                          @change="validarDias"
                          required
                          autofocus
                          ></v-text-field>
                        </v-col>
                        <v-col sm="1" class="pa-1" v-show="$store.getters.getIdVenta.IdResolucion<1">
                          <v-text-field label="Serie Factura" color="light-green" dense filled
                          background-color="white"
                          v-model="editedItem.Serie"
                          @keyup.enter="nextFocus"
                          placeholder="Serie Factura"  name="serie" ref="serie"                                   
                          ></v-text-field>
                        </v-col>                   
                        <v-col sm="1" class="pa-1">
                          <v-text-field label="No. Factura" color="light-green" dense filled
                          background-color="white"
                          v-model="editedItem.NumeroFactura"
                          placeholder="No. Factura" name="numfc" ref="numfc"
                          @keyup.enter="nextFocus"
                          @change="validarUltimaFactura"  
                          required                   
                          ></v-text-field>
                        </v-col>
                        <v-col sm="1" class="pa-1">
                          <v-text-field label="Nit" dense filled
                          color="light-green"
                          background-color="white"
                          v-model="Nit" name="nit" ref="nit"
                          placeholder="Nit"                        
                          @keyup.enter="nextFocus"
                          @change="getCliente"
                          required
                          ></v-text-field>
                        </v-col>
                        <v-col sm="2" class="pa-1" id="formcolor">
                          <v-text-field label="Cliente" color="light-green" dense filled 
                          background-color="white" 
                          v-model="editedItem.Cliente" 
                          @keyup.enter="nextFocus" 
                          placeholder=" Cliente" name="cliente" ref="cliente"
                          append-icon="mdi-lead-pencil"
                          @click:append="edithCliente"
                          required                  
                          ></v-text-field>
                        </v-col>
                        <v-col sm="1" class="pl-1 pt-1 pr-1">
                          <v-text-field label="Total" color="light-green" dense filled
                          background-color="white"
                          v-model="editedItem.TotalFactura"
                          placeholder="Total" name="totalfc" ref="totalfc"
                          v-show="editedItem.EstadoFactura!=='Exenta'"
                          @keyup.enter="nextFocus"
                          @keyup="validarEstadoFactura"
                          ></v-text-field>

                          <v-text-field label="Total Exento" color="light-green" dense filled
                          background-color="white"
                          v-model="editedItem.VentaExcenta"
                          @keyup.enter="nextFocus"
                          placeholder="Total Exento" name="facexenta" ref="facexenta"
                          v-show="editedItem.EstadoFactura=='Exenta'"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="1" class="pa-1" v-show="$route.path.indexOf('rg')>0">
                          <v-text-field label="Total sin IVA"  dense filled
                          color="light-green"
                          background-color="white"
                          :value="totalSinIva"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="1" class="pa-1">
                          <v-text-field label="IVA"  dense filled
                          color="light-green"
                          background-color="white"
                          :value="Iva"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="2" class="pa-1">
                          <v-select
                            v-model="editedItem.EstadoFactura"
                            :items="estadofac"
                            :menu-props="{ top: true, offsetY: true }"
                            hide-selected
                            dense filled
                            background-color="white"
                            color="light-green"
                            label="Estado Factura"
                            @change="validarFacturaExenta"
                          ></v-select>
                        </v-col>
                        <v-col sm="1" class="pa-3" >
                          <v-btn type="button" depressed class="white--text" @keyup.enter="save" @click="save" color="light-green" elevation="2">
                          <v-icon color="white" v-show="editedIndex<0">mdi-sticker-plus</v-icon>       
                          <v-icon color="white" v-show="editedIndex>=0">mdi-clipboard-edit</v-icon>       
                          </v-btn>
                        </v-col>
                      </v-row> 
                    </form>
                </v-card>
              </v-row>
              <!-- TABLA DETALLE FACTURAS   -->
              <v-row>
                <v-col cols="12">
                        <v-card>
                            <v-card-title>
                           
                              <v-btn color="blue" class="text-white"
                              @click="procesarDTE" v-show="$store.getters.getIdVenta.IdResolucion<1">
                              <v-icon left color="white">mdi-file-upload</v-icon>Cargar DTE
                              </v-btn>
                              <v-divider class="mx-4" inset vertical></v-divider>

                              <v-btn color="red" class="text-white" @click="deleteAllItems">
                              <v-icon left color="white">mdi-delete-circle</v-icon>Vaciar
                              </v-btn>
                              <v-divider class="mx-4" inset vertical></v-divider>

                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                color="teal"
                                single-line
                                hide-details
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :headers="headers"
                            :items="dataitems"
                            :items-per-page=5
                            :search="search"
                            color="teal"
                            class="elevation-0"
                            dense
                            >
                            <template v-slot:[`item.Serie`]="{ item }">
                              <p>{{item.Serie==0?$store.getters.getIdVenta.Serie:item.Serie}}</p>
                            </template>
                            <template v-slot:[`item.TotalSinIva`]="{ item }">
                              <p v-text="moneda(item.TotalFactura)" v-if="item.VentaExcenta<1"></p>
                              <p v-text="0" v-else></p>
                            </template>               
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <v-btn icon @click="editItem(item)"><v-icon color="blue">mdi-file-document-edit</v-icon></v-btn>
                                    <v-btn icon @click="mensajeEliminarFactura(item)"><v-icon color="red">mdi-file-remove</v-icon></v-btn>
                                </div>       
                            </template>
                            <template v-slot:no-data>
                                <button class="btn btn-primary text-white" type="button">Sin Ventas</button>
                            </template>
                            </v-data-table>
                        </v-card>
                      </v-col>
              </v-row>
            </div>
          </div>
        </div>
       
      </main>
      <!-- MODAL AGREGAR CLIENTE -->
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
     
      <v-card>
        <v-card-title class="headline teal text-white">
          Agregar Cliente
        </v-card-title>

        <v-card-text>
          <br><br>
           <v-text-field v-model="Nit" label="Nit" color="teal" outlined dense required autofocus></v-text-field>
           <v-text-field v-model="cliente.Proveedor" label="Nombre Cliente" color="teal" outlined dense required></v-text-field>
           <v-text-field v-model="cliente.Tipo" label="Tipo" color="teal" outlined dense required disabled></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button type="button" class="btn btn-primary text-white ma-2" @click="saveCliente"><v-icon color="white">mdi-check-circle</v-icon> AGREGAR</button>         
          <button type="button" class="btn btn-secondary text-white" @click="dialog = false"><v-icon color="white">mdi-close-circle</v-icon> CANCELAR</button>         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    </div>
    
</template>
<script>
import axios from 'axios'
import dayjs from 'dayjs'
import currency from '@/libs/currency'
import Swal from 'sweetalert2'
import {reportes} from '@/libs/reportes'
//@submit.prevent="save"
export default {
  name:'IngresoFacturasRG',
  data() {
    return {
      urlapi:this.$store.getters.getUrlApi,
      estadofac:["Emitida","Exenta","Anulada"],
      dataitems:[],
      dialog:false,
      search: '',
      Nit:'C-F',
      dia:1,
      NumeroFactura:0,
      message:"",
      fcrestantes:0,
      regimengeneral:false,
      headers: [
            {text: 'Fecha:',align: 'start',value: 'FechaFactura'},
            { text: 'Serie', value: 'Serie'},
            { text: 'No. Fac:', value: 'NumeroFactura' },
            { text: 'Nit', value: 'Nit' },
            { text: 'Cliente', value: 'Cliente' },
            { text: 'Total Factura', value: 'TotalFactura' },
            { text: 'Total sin IVA', value: 'TotalSinIva' },
            { text: 'Iva', value: 'Iva' },
            { text: 'Estado', value: 'EstadoFactura'},
            { text: 'Opciones', value: 'actions', sortable: false },
            ], 
     editedIndex: -1,
     editedItem:{
        IdLibroVenta:0,
        FechaFactura:1,
        Serie:'',
        NumeroFactura:0,
        IdCliente:0,
        Cliente:'Clientes Varios',
        TotalFactura:0,
        VentaExcenta:0,
        Iva:0,
        EstadoFactura:'Anulada',
      },
      editedCliente:0,
      cliente:{
        Proveedor:'',
        Tipo:'Cliente'
      } 
    }
  },
  computed: {  
    periodo(){
      return dayjs(this.$store.getters.getIdVenta.Fecha,'YYYY-MM-DD').add(1, 'day').format('MMMM YYYY').toUpperCase()
    },
    totalPeriodoIva(){
      return this.$route.path.indexOf('rg')>0?currency((this.$store.getters.getIdVenta.Total/1.12)*0.12,2):currency((this.$store.getters.getIdVenta.Total)*0.05,2)
    },
    establecimiento(){
      return (this.$store.getters.getIdVenta.Nit+" | "+this.$store.getters.getIdVenta.Establecimiento).toUpperCase()
    },
    Iva() {      
      return this.$route.path.indexOf('rg')>0?currency((this.editedItem.TotalFactura/1.12)*0.12,2):currency((this.editedItem.TotalFactura)*0.05,2)
    },
    totalSinIva() {
      return currency((this.editedItem.TotalFactura/1.12),2)
    },
    getSeriesVencimiento(){
        return this.$store.getters.getvencimiento
    },
    
  },
  created() {
    this.init()
    this.getUltimaFactura()
    this.getCliente()
  }, 
  methods: {
    init(){
      const IdVenta = this.$store.getters.getIdVenta.IdVenta
      axios.get(`${this.urlapi}/facturasventas/${IdVenta}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
        this.dataitems = res.data       
      }).catch(()=>{})     
    },
    //Metodo para obtener el último No. de factura
    getUltimaFactura(){
      const IdResolucion = this.$store.getters.getIdVenta.IdResolucion
      const IdEstablecimiento = this.$store.getters.getSelectedItem.IdEstablecimiento
       if(this.$store.getters.getIdVenta.IdResolucion){
         axios.get(`${this.urlapi}/facturasventas/${IdEstablecimiento}/${IdResolucion}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{       
           this.editedItem.NumeroFactura = res.data[0].NumeroFactura
           this.NumeroFactura = res.data[0].NumeroFactura  
           this.validaciones(res.data[0].NumeroFactura)           
         }).catch(()=>{})
       }
       
    },
    validaciones(numerofc){
      this.regimengeneral  = (this.$route.path).indexOf('rg')>0?true:false

      //Validar que la ultima factura  no sea mayor al maximo autorizado en la resolucion
      let serie = (this.$store.getters.getSeriesActivas).find(item=>{
       return item.IdResolucion === this.$store.getters.getIdVenta.IdResolucion
      })
      
      this.fcrestantes= parseInt(serie.FinResolucion)-parseInt(numerofc);
      if(this.fcrestantes<=100 & this.regimengeneral){
        this.fcrestantes>0? this.message=`El número de facturas restantes es ${this.fcrestantes}`:this.message='Ha llegado al limite de facturas autorizadas' ;
      }
    },
    //Metodo para obtener el Nombre y el Id del Cliente
    async getCliente(){
      const persona=this.$store.getters.buscarCliente(this.Nit)
      if(persona){
        this.editedItem.Cliente = persona.Cliente
        this.editedItem.IdCliente = persona.IdProveedor
      }else{
       await  axios.get(`${this.urlapi}/personas/${this.Nit}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
          
          if(res.data.length>0){
            this.editedItem.Cliente = res.data[0].Proveedor
            this.editedItem.IdCliente = res.data[0].IdProveedor
          }else{
           
            Swal.fire({text:'No se encontró el Cliente presione Ok Para agregar!'}).then(res=>{
              if(res.isConfirmed){
                this.dialog=true
              }else{
                this.Nit='C-F'
              }
            })
            
          }

        }).catch(err=>{console.log(err)})

        
      }
    },
    editItem(item){
    
      this.Nit = item.Nit
      this.editedIndex = this.dataitems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      item.VentaExcenta>0?this.editedItem.TotalFactura=0:''
      
      let fecha = item.FechaFactura.split("-");
      this.dia = fecha[0] 
      this.$refs.dia.focus();
    },
    save(){
      if (this.editedIndex <0) {
            this.guardarRegistro(); 
            } else {
             this.editarRegistro();
          }
    },
    //Metodo INSERTAR Factura Venta
    async guardarRegistro(){
      this.editedItem.IdLibroVenta = this.$store.getters.getIdVenta.IdVenta
      this.calculosImpuestos()
      this.validarDias()
      axios.post(`${this.urlapi}/facturasventas`,this.editedItem,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
       this.limpiar()
      }).catch(err=>{
        console.error(err)
      })
    },
    //Metodo editar Factura Venta 
    async editarRegistro(){
      this.editedItem.IdLibroVenta = this.$store.getters.getIdVenta.IdVenta
      
      this.calculosImpuestos()
      this.validarDias()
      axios.put(`${this.urlapi}/facturasventas/${this.editedItem.IdDetalle}`,this.editedItem,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
        this.limpiar()
        this.exitoSwal('Factura Modificada','')
      }).catch(err=>{
        this.swalmsg('Error al Actualizar la Factura','')
        console.error(err)
      })
    },
    saveCliente(){
      if(this.editedCliente==0){
          this.cliente.Nit = this.Nit
          this.calculosImpuestos()
          axios.post(`${this.urlapi}/personas`,this.cliente,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
            this.editedItem.Cliente=this.cliente.Proveedor
            //Traemos listado de los clientes 
            this.cliente.Proveedor=''
            this.$store.dispatch("setTopClientes")
            this.dialog=false
            this.exitoSwal('Cliente Agregado','')
            
          }).catch(err=>{console.error(err) })
        
      }else{      
          axios.put(`${this.urlapi}/personas/${this.editedItem.IdCliente}`,this.cliente,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
            this.editedItem.Cliente=this.cliente.Proveedor
            //Traemos listado de los clientes           
            this.cliente.Proveedor=''
            this.dialog=false
            this.exitoSwal('Cliente Editado','')
            
          }).catch(err=>{console.error(err) })
       this.editedCliente=0  
      }      
      
      this.getCliente(); 
      this.$refs.totalfc.focus()
    },
    edithCliente(){
      this.editedCliente=1
      this.cliente.Proveedor = this.editedItem.Cliente
      this.cliente.Tipo='Cliente'
      this.dialog=true
    },
    mensajeEliminarFactura(item){
      Swal.fire({
        title: `Eliminar Factura No. ${item.NumeroFactura}?`,
        text: "Esta seguro de eliminar esta factura!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!',
        cancelButtonText: 'No, cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.eliminarFactura(item)
        }
      })
    },
    eliminarFactura(item){
     
      const total_actual= parseFloat(this.$store.getters.getIdVenta.Total)
      const nuevototal = total_actual-(parseFloat(item.TotalFactura)+parseFloat(item.VentaExcenta))
      
      axios.delete(`${this.urlapi}/facturasventas/${item.IdDetalle}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
        this.$store.dispatch("setTotalVenta",nuevototal)
        this.getUltimaFactura()
        this.init()
        this.exitoSwal('Factura Eliminada','')
      }).catch(err=>{console.log(err),this.swalmsg('Error al Eliminar la Factura','')})
    },
    deleteAllItems(){
      Swal.fire({
        title: `¿Desea Eliminar Todas las Facturas?`,
        text: "¡Está acción no podra revertirse, presione Estoy Seguro para eliminarlas!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Estoy Seguro!',
        cancelButtonText: 'No, Cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
          let IdVenta =  this.$store.getters.getIdVenta.IdVenta
          //Relizamos la petición para eliminar las Facturas
          axios.delete(`${this.urlapi}/facturasventas/allitems/${IdVenta}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
            this.init()
            this.getUltimaFactura()
            this.exitoSwal() 
            this.$store.dispatch("setTotalVenta",0.00)
          }).catch(err=>{console.log(err),this.errorSwal()})
        }
      })      
    },
    //Validaciones
    validarDias(){

          const periodo= dayjs(this.$store.getters.getIdVenta.Fecha)
        
          const ultimodiames = periodo.add(1,'day').endOf('month').format('DD')
          
          if(this.dia<1 ||this.dia>parseInt(ultimodiames)){
            this.dia=1
            this.editedItem.FechaFactura = dayjs(periodo).format('YYYY-MM-DD'); 
            this.swalmsg('Dia incorrecto','El día no puede ser menor a 1 ni mayor a '+ultimodiames)
          }else{
            const anio_mes = dayjs(periodo).add(1,'day').format('YYYY-MM')
            this.dia = parseInt(this.dia)
            this.editedItem.FechaFactura = anio_mes+'-'+this.dia
          }         
    },
    //Validar que la factura no haya sido ingresada previamente
    validarUltimaFactura(){
      const idventa = this.$store.getters.getIdVenta.IdVenta
      if(parseInt(this.NumeroFactura)!==parseInt(this.editedItem.NumeroFactura) ){

       axios.get(`${this.urlapi}/facturasventas/buscarfactura/${idventa}/${this.editedItem.NumeroFactura}`,{headers:{'auth-user':this.$store.getters.getToken}}).then(res=>{
         
         if(res.data.length>0){
           this.swalmsg('Factura ya Ingresada','El numero de Factura '+this.editedItem.NumeroFactura+' ya fue Ingresado')
           this.editedItem.NumeroFactura = this.NumeroFactura
         }

        }).catch(err=>{console.log(err)})
       
      }
    },
    calculosImpuestos(){
      const IdRegimen = this.$route.path.indexOf('rg')
      if(IdRegimen>0){
        this.editedItem.Iva = currency((this.editedItem.TotalFactura/1.12)*0.12,2).value
      }else{
        this.editedItem.Iva = currency((this.editedItem.TotalFactura)*0.05,2).value
        }
        //Si es Exenta establecemos Iva=0, TotalSinIva=0 
      if(this.editedItem.VentaExcenta>0){this.editedItem.Iva=0,this.editedItem.TotalSinIva=0}
    },   
    //Metodo para Calcular el Total -IVA y calcular el IVA 12%
    moneda(total){
      // tipo == 1 IVA 12%
      const IdRegimen = this.$route.path.indexOf('rg')
      if(IdRegimen>0){
        return currency((total/1.12),2)
      }else{return 0}     
    },
    //Estado de la factura
    validarEstadoFactura(){
      this.editedItem.TotalFactura>0?this.editedItem.EstadoFactura='Emitida':this.editedItem.EstadoFactura='Anulada'
    },
    //Validar Ventas Exentas
    validarFacturaExenta(){
      if(this.editedItem.EstadoFactura=='Anulada'){
        this.$refs.totalfc.focus();
        this.editedItem.TotalFactura=0
      }
      if(this.editedItem.EstadoFactura=='Exenta'){
        this.$refs.facexenta.focus();
        this.editedItem.VentaExcenta=this.editedItem.TotalFactura
        this.editedItem.TotalFactura=0
        this.editedItem.Iva=0
      }
      if(this.editedItem.EstadoFactura=='Emitida'){
        this.$refs.totalfc.focus();        
       this.editedItem.TotalFactura=this.editedItem.VentaExcenta
       this.editedItem.VentaExcenta=0
      }
    },
    procesarDTE(){
      const IdRegimen = this.$route.path.indexOf('rg')
      this.$store.dispatch("setTipo",'ventas')
      if(IdRegimen>0){
        this.$store.dispatch("setRegimen",'rg')
        this.$router.push('/ProcesarFelVentas')
      }else{
        this.$store.dispatch("setRegimen",'rp')
        this.$router.push('/ProcesarFelVentas')
      } 

    },
    nextFocus(e){
      let serie = this.$store.getters.getIdVenta.Serie
      let valor = e.target.name     
      if(valor=='dia'){serie==='FEL'?this.$refs.serie.focus():this.$refs.numfc.focus()}
      if(valor=='serie') this.$refs.numfc.focus();
      if(valor=='numfc') this.$refs.nit.focus();
      if(valor=='nit') this.$refs.cliente.focus();
      if(valor=='cliente'){this.editedItem.EstadoFactura=='Exenta'?this.$refs.facexenta.focus():this.$refs.totalfc.focus()}
      
      if(valor=='totalfc'){
        this.save()
      }
      if(valor=='facexenta'){
        this.save();
      }
    },
    //Limpiar Campos
    limpiar(){
           const total_actual= parseFloat(this.$store.getters.getIdVenta.Total)
          if (this.editedIndex <0) {
            //Al Crear Item
              const nuevototal=total_actual+parseFloat(this.editedItem.TotalFactura)
              this.editedItem.NumeroFactura++
              this.NumeroFactura++
              this.$store.dispatch("setTotalVenta",nuevototal)
            } else {
              const itemfactura = this.dataitems.find(elemento=>elemento.IdDetalle==this.editedItem.IdDetalle)
              const nuevototal = (total_actual-parseFloat(itemfactura.TotalFactura) )+parseFloat(this.editedItem.TotalFactura)
              this.$store.dispatch("setTotalVenta",nuevototal)
              this.editedIndex= -1
              this.getUltimaFactura()
          }
              this.editedItem.TotalFactura=0
              this.editedItem.VentaExcenta=0
              this.$refs.dia.focus();
              this.init()
    },
    //Mesajes Sweet Alert
    swalmsg(titulo,texto){
      Swal.fire({
        title:titulo,
        text:texto,
        icon:'error'
      })
    },
    exitoSwal(titulo,texto){
      Swal.fire({
        icon: 'success',
        title: titulo,
        text:texto,
        showConfirmButton: false,
        timer: 1500
      })
    },
    printPDF(item){
      reportes(item,this.$route.path)
      },
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans&display=swap');
.title{
  margin-top: -50px;
  line-height: 10px;
}
a{
text-decoration: none;
}
#frmDatos{ 
  color: rgb(27, 179, 103) !important; 
  height: 60px;
}

#frmDatos p{
  text-align: left;
  line-height: 12px;
  margin-left: 3px;
  margin-top:-25px;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
}
</style>