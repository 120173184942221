<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div class="col-md-6">
          <v-autocomplete
            :items="getItems"
            v-model="selecteditem"
            dense
            color="#17a2b8"
            outlined
            clearable
            hide-details
            hide-selected
            item-text="Negocio"
            item-value="IdEstablecimiento"
            label="Buscar Nit ..."        
            @change="getVentas()"     
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong>No se contro ningun registro</strong>
              </v-list-item-title>
            </v-list-item>
          </template>          
          </v-autocomplete>
        </div>
        <ul class="app-breadcrumb breadcrumb">
         <h5>{{this.$route.path.indexOf('rg')>0?'Regimen General':'Regimen Pequeño Contribuyente'}}</h5>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
           
          <template v-if="!$store.getters.getSelectedItem">
            <h3>No ha seleccionado ningún Nit <v-icon class="amber--text mdi-48px">mdi-emoticon-confused-outline</v-icon></h3>
          </template>
          <template v-else>
            <!-- Series vencidas!-->
            <div v-for="item in getSeriesVencimiento" :key="item.IdSerie" id="vencimientoseries">
              <v-alert dense border="left" type="warning" v-if="item.Diasrestantes>0">
                La serie <strong>{{item.Serie}}</strong> vencerá en  <strong> {{item.Diasrestantes}}</strong> dias.
              </v-alert>
              <v-alert dense border="left" type="error" v-else>
                La serie <strong>{{item.Serie}}</strong> se encuentra <strong> VENCIDA </strong>.
              </v-alert>
            </div>
            <v-tabs v-model="tab" fixed-tabs background-color="#17a2b8" dark>
              
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab href="#tab-1">
                Ventas
              </v-tab>
              <v-tab href="#tab-2" @click="rutas('/rg/compras')" v-if="$route.path.indexOf('rg')>0">
                Compras
              </v-tab>
              <v-tab href="#tab-3" @click="rutas('/rg/series')">
                Serie
              </v-tab>
            </v-tabs>
            <div>
              <!-- INICIO TAB 1-->  
              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                    <div id="ventas">
                        <v-card>
                            <v-card-title>
                              <v-btn class="mx-2" fab dark small color="#17a2b8" data-toggle="modal" data-target="#modalRegistro" @click="dialog=true">
                                <v-icon dark>mdi-plus</v-icon>
                              </v-btn>
                              LISTADO VENTAS
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                color="teal"
                                single-line
                                hide-details
                            ></v-text-field>
                            </v-card-title>
                            <v-data-table
                            :headers="headers"
                            :items="dataitems"
                            sort-by="Fecha"
                            :sort-desc="[true]"
                            :items-per-page=5
                            :search="search"
                            >              
                            <template v-slot:[`item.Establecimiento`]="{ item }">
                              <h6 style="margin:0;padding:0">{{item.Establecimiento}}</h6>
                              <p style="margin:0;padding:0">{{item.Nombre}}</p>
                            </template>
                            <template v-slot:[`item.Total`]="{ item }">
                              <h5><span class="badge badge-primary" v-show="item.Total>0">Q {{item.Total}}</span></h5>
                              <h6><span class="badge badge-danger" v-show="item.Total<0.001">Sin movimiento</span></h6>
                            </template>
                            <template v-slot:[`item.IVA`]="{ item }">
                              <h5><span class="badge badge-primary" v-show="item.IVA>0">Q {{item.IVA}}</span></h5>
                              <h6><span class="badge badge-danger" v-show="item.IVA<0.001">{{item.TipoVenta=='Exenta'?'Exenta':'Sin movimiento'}}</span></h6>
                            </template>

                            <template v-slot:[`item.Fecha`]="{ item }">
                              <p>{{item.Fecha | formatoFechas}}</p>
                            </template>                 
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="btn-group btn-group-toggle" data-toggle="buttons">                        
                                    <button class="btn btn-info btn-sm"  @click="detalleventa(item,1)"><v-icon class="text-white mdi-18px">mdi-note-plus-outline</v-icon></button>                      
                                    <button class="btn btn-warning btn-sm" @click="printPDF(item)"><v-icon class="mdi-18px">mdi-printer-check</v-icon></button>                      
                                    <button class="btn btn-danger btn-sm" @click="deleteSwal(item.IdVenta)"><v-icon class="text-white mdi-18px">mdi-trash-can-outline</v-icon></button>
                                    <button class="btn btn-primary active btn-sm" @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white mdi-18px">mdi-order-bool-ascending</v-icon></button>
                                </div>       
                            </template>
                            <template v-slot:no-data>
                                <button class="btn btn-primary text-white" type="button">Sin Ventas</button>
                            </template>
                            </v-data-table>
                        </v-card>
                      </div>
                </v-tab-item>        
              </v-tabs-items>
            </div>
          
          </template>
          
          </div>
        </div>
      </div>
    </main>
<!-- Modal Crear Venta-->
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" >
      <v-card>
        <v-card-title class="text-white teal">
          {{formTitle}}
        </v-card-title>
        <v-card-text>
         <v-container>
           <v-row>
             <v-col cols="6">
                <v-col cols="12">
                    <v-text-field color="blue" :value="getEstablecimiento" disabled outlined label="Establecimiento*" required></v-text-field>
                </v-col>
                <v-col cols="12">                  
                  <v-select 
                  :items="getSeries" 
                  v-model="editedItem.IdResolucion"
                  outlined
                  hide-details
                  hide-selected
                  item-text="Serie"
                  item-value="IdResolucion"
                  color="blue"
                  label="Seleccione Serie" 
                  @change="getFolioSiguiente()"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field color="blue" v-model="editedItem.Folio" outlined label="Folio*" placeholder="1000" required></v-text-field>
                </v-col>
                <v-col cols="12">                  
                  <v-select 
                  :items="TipoVenta" 
                  v-model="editedItem.TipoVenta"
                  outlined
                  hide-details
                  hide-selected
                  label="Tipo de Venta" 
                  color="blue"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field color="blue" v-model="editedItem.Fecha" outlined label="Periodo*" disabled required></v-text-field>
                </v-col>
             </v-col>
             <v-col cols="6">
                <v-col>
                  <p>Seleccione periodo haciendo Clic en el mes</p>
                <v-date-picker
                    v-model="editedItem.Fecha"
                    :show-current="false"
                    header-color="teal"
                    color="red"
                    type="month"
                    locale="es"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                  ></v-date-picker>  
                </v-col>            
             </v-col>
         
           </v-row>          
         </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal text-white"  @click="save">
            Guardar
          </v-btn>
          <v-btn color="grey darken-2 text-white"  @click="close(), dialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  
    </div>
</template>
<script>
import axios from "axios"
import * as dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {mapGetters} from 'vuex'
import {reportes} from '@/libs/reportes'

export default {
  
  data() {
    return {
      dialog:false,
      urlapi:this.$store.getters.getUrlApi,
      token:this.$store.getters.getToken,   
      tab:'tab-1',        
      dataitems:[],
      search: '',
      year:'',
      TipoVenta:['Bienes','Exenta','Combustibles'],
      headers: [
            {text: 'Nit', align: 'start',value: 'Nit'},
            { text: 'Establecimiento', value: 'Establecimiento' },
            { text: 'Serie', value: 'Serie' },
            { text: 'Folio', value: 'Folio' },
            { text: 'Periodo', value: 'Fecha' },
            { text: 'Total', value: 'Total' },
            { text: 'Iva', value: 'IVA' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],     
      editedIndex: -1,
      editedItem:{
        IdEstablecimiento:0,
        IdResolucion:false,
        Folio:0,
        Fecha:dayjs().subtract(1, 'months').format('YYYY-MM'),//moment().subtract(1, 'months').format('YYYY-MM')
        FechaCreacion:dayjs().format('YYYY-MM-DD'),
        TipoVenta:'Bienes',
      },
      defaultItem:{
        IdEstablecimiento:0,
        IdResolucion:false,
        Folio:0,
        Fecha:dayjs().subtract(1, 'months').format('YYYY-MM'),
        FechaCreacion:dayjs().format('YYYY-MM-DD'),
        TipoVenta:'Bienes',
      },
    }
  },
  created() {  
   if(this.$store.getters.getSelectedItem){
     this.getVentas()
   }
  },
  computed:{
    formTitle () {
          return this.editedIndex === -1 ? 'Nueva Venta' : 'Editar Venta'
        },
    ...mapGetters(["getItems"]),
     selecteditem: {
        get () {
          return this.$store.getters.getSelectedItem
        },
        set (value) {
          this.$store.dispatch('setSelectedItem', value)
        }
      },
      getEstablecimiento(){
        return this.$store.getters.getSelectedItem?this.$store.getters.getSelectedItem.Establecimiento:'Error al cargar negocio'
      },
      getSeries(){
        return this.$store.getters.getSeriesActivas
      },
      getSeriesVencimiento(){
        return this.$store.getters.getvencimiento
      },
      
  },
  methods:{       
        async getVentas(){ 
         const item=this.$store.getters.getSelectedItem
         
         if(item){         
            await axios.get(`${this.urlapi}/ventas/${item.IdEstablecimiento}`,{headers:{'auth-user':this.$store.getters.getToken}})
            .then(response=>{
              this.dataitems = response.data.ventasbienes;
              if(response.data.ventacombustible.length>0){
                response.data.ventacombustible.forEach(element => {                  
                  this.dataitems.push(element)
                });
                }  
              //Traemos listado de los clientes 
              
              this.$store.dispatch("setTopClientes")   
                
              
            }).catch(error=>{
              console.log(error)             
            })       
          }           
               this.$store.dispatch("setVentas",this.dataitems) 
              this.$store.dispatch("setSeries",item.IdEstablecimiento)
        },
        getFolioSiguiente(){
     
          if(this.dataitems.length>0){
            const itemsSerie =[]; 
            //Filtramos la Serie
            this.dataitems.forEach(ventas=>{
              if(ventas.IdResolucion==this.editedItem.IdResolucion){
                //Llenamos array con las ventas de la serie
                itemsSerie.push(ventas)
              }
              
            })
              //verificamos que hayan ventas en el array
              if(itemsSerie.length>0){
                const siguienteFolio = itemsSerie.sort((venta1,venta2)=>{
                  return venta2.Fecha>venta1.Fecha
                })

                this.editedItem.Folio = parseInt(siguienteFolio[0].Folio)+1
                //Calculando automaticamente el siguiente periodo a trabajar
                let ultimafecha = dayjs(siguienteFolio[0].Fecha)
                this.editedItem.Fecha = dayjs(ultimafecha).add(2, 'months').format('YYYY-MM');
                this.year = this.editedItem.Fecha
              }else{
                //Sino hay ventas en el array el folio es igual a 1
                this.editedItem.Folio=1
              }
          }else{
            this.editedItem.Folio=1
          }
        },
        editItem (item) {  
          this.editedIndex = this.dataitems.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.editedItem.Fecha = dayjs(item.Fecha,'YYYY-MM-DD').add(1,'day').format('YYYY-MM');
          this.year = this.editedItem.Fecha
          this.dialog=true
        },
        close() {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            this.dialog=false
          })
        },
        save () {
          if (this.editedIndex > -1) {
             this.editarRegistro();
          } else {
            this.guardarRegistro();         
          }
          this.close()
        },     
        //Metodo Guardar
        guardarRegistro(){     
          this.editedItem.IdEstablecimiento=this.$store.getters.getSelectedItem.IdEstablecimiento
          this.editedItem.Fecha=dayjs(this.editedItem.Fecha).format('YYYY-MM-DD')
          if(this.editedItem.IdResolucion==0) delete this.editedItem.IdResolucion
             
         axios.post(`${ this.urlapi}/ventas/`,this.editedItem,{headers:{'auth-user': this.token}})
            .then(()=>{
                this.getVentas();
                this.exitoSwal()
              }).catch(()=>{this.errorSwal()}) 
        },
        //Metodo Editar
       editarRegistro(){
          const formData ={
             Folio:this.editedItem.Folio,
             Fecha:this.editedItem.Fecha+'-01',
             FechaCreacion:dayjs().format('YYYY-MM-DD'),
             TipoVenta:this.editedItem.TipoVenta
         }
          if(this.editedItem.IdResolucion>0) formData.IdResolucion=this.editedItem.IdResolucion
          
           if(this.editedItem.Total>0)this.updateDate()
        
          axios.put(`${ this.urlapi}/ventas/${this.editedItem.IdVenta}`,formData,
            {headers:{'auth-user': this.token}}).then(()=>{           
                  this.getVentas()
                  this.exitoSwal()
            }).catch(err=>{
                this.errorSwal()
                console.log(err)
            })
          
        },
        updateDate(){
          let currentdate = dayjs(this.year).format('YYYY-MM')
          let newdate = dayjs(this.editedItem.Fecha).format('YYYY-MM')
          if(currentdate!==newdate){
            if(this.editedItem.TipoVenta==="Bienes"){
              axios.put(`${this.urlapi}/facturasventas/year/${this.editedItem.IdVenta}`,{'year':newdate},{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
              }).catch(err=>{console.error(err)})
            }else{
              axios.put(`${this.urlapi}/facturascombustible/date/${this.editedItem.IdVenta}`,{'date':newdate},{headers:{'auth-user':this.$store.getters.getToken}}).then(()=>{
              }).catch(err=>{console.error(err)})
            }
          }
          
        },
        eliminarItem(Id){
            axios.delete(`${this.urlapi}/ventas/${Id}`,{headers:{'auth-user': this.token}})
              .then(()=>{
                this.getVentas()
                this.exitoSwal()         
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        detalleventa(item){
          
          let res = this.$route.path.split("/");
          this.$store.dispatch("setIdVenta",item)
          if(item.TipoVenta==='Combustibles'){
            this.$router.push(`/${res[1]}/facturascombustible`)
          }else{

            this.$router.push(`/${res[1]}/facturasventas`)
          }
        },            
        rutas(path){
         const rutaActual = this.$route.path;

         if(this.$route.path.indexOf('rp')>0){path='/rp/series'}
          //Validamos que se pidan rutas diferentes
         if(rutaActual !==path){this.$router.push(path)}
       },
       //NOTIFICACIONES SWAL
       exitoSwal(){
         Swal.fire({
          icon: 'success',
          title: '!Realizado¡',
          showConfirmButton: false,
          timer: 2000
          }) 
       },
       errorSwal(){
         Swal.fire({
          icon: 'error',
          title: 'Error',
          text:'No se pudo realizar la acción'
          }) 
       },
       deleteSwal(Id){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(Id);
            }
          })
       },
       printPDF(item){
        // const url =  `https://asecontables.microsystems.com.gt/reportes/auditoria.php?fel=false&id=${item.IdVenta}`
        //   window.open(url,"blank")
        reportes(item,this.$route.path)
      },  
  },
  filters: {
    formatoFechas: function (value) {
        if (!value) return ''
        return dayjs(value).add(1, 'day').format('MMMM YYYY').toUpperCase()
    }
  }
}
</script>

<style scoped>
a{
text-decoration: none;
}
.Bold { font-weight: bold; }
</style>

