<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div>
          <h1><i class="fa fa-dashboard"></i>{{titlerouter}}</h1>
          <p>Listado de todos los Usuarios</p>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <v-btn class="white--text" color="#009688" data-toggle="modal" data-target="#modalRegistro">
              <v-icon left>mdi-account-plus</v-icon>
              NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <div class="tile-body">
                <template>
                <v-card>
                    <v-card-title>
                    {{titlerouter}}
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="dataitems"
                    :search="search"
                    >
                    <template v-slot:[`item.Estado`]="{item}">
                        <span class="badge" :class="item.condicion==1?'badge-success':'badge-danger'"> {{item.condicion==1?'Activo':'Inactivo'}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <button class="btn btn-info btn-sm"  @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white">mdi-account-edit</v-icon></button>                      
                            <button class="btn btn-danger btn-sm" @click="deleteSwal(item.IdPermiso)"><v-icon class="text-white">mdi-account-remove</v-icon></button>
                        </div>       
                    </template>
                    <template v-slot:no-data>
                        <button class="btn btn-primary text-white" type="button" @click="getItems">Cargar Información</button>
                    </template>
                    </v-data-table>
                </v-card>
                </template>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Modal -->
<div class="modal fade" id="modalRegistro" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalRegistroLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalRegistroLabel">{{formTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          
            <v-text-field  v-model="editedItem.Nombre" label="Nombre *" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Icono" label="Icono" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Path" label="Path" color="blue" dense outlined required></v-text-field>           
 
        </form>  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary white--text" data-dismiss="modal" @click="close">Cancelar</button>
        <button type="button" class="btn btn-primary white--text" data-dismiss="modal"  @click="save">Guardar</button>
      </div>
    </div>
  </div>
</div>

    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            titlerouter:'Permisos Usuario',
            dataitems:[],
            editedIndex: -1,
            search: '',
            headers: [
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Icono', value: 'Icono' },
            { text: 'Path', value: 'Path' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],
        editedItem: {
          Nombre:'',
          Icono:'',
          Path:'',
        },
        defaultItem: {
          Nombre:'',
          Icono:'',
          Path:'',
        },       
       }
    },
    created() {
        this.getItems()
    },
    computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
        },
        inputvalidos(){
            if(this.editedItem.Nombre.length>5 && this.editedItem.Clave.length>5 && this.editedItem.Login.length>5){
                return  true
            }
            return false
        }
    },
    methods: {
        getItems(){
            axios.get(`${this.$store.state.urlapi}/userspermisos/`,{headers:{'auth-user':this.$store.state.token}})
            .then(response=>{
                this.dataitems = response.data;
            }).catch(error=>{
                console.log(error)
            })        
        },
        editItem(item) {  
          this.editedIndex = this.dataitems.indexOf(item)       
          this.editedItem = Object.assign({}, item)      
        },
        guardarRegistro(){          
          axios.post(`${this.$store.state.urlapi}/userspermisos/`,this.editedItem,{headers:{'auth-user':this.$store.state.token}}).then(response=>{
            if(response.status==200){
                this.getItems()
                this.exitoSwal()
            }
            }).catch(error=>{
                console.log(error)
                this.errorSwal()
            })         
        },
        editarRegistro(){
            const id = this.editedItem.IdPermiso
            axios.put(`${this.$store.state.urlapi}/userspermisos/${id}`,this.editedItem,{headers:{'auth-user':this.$store.state.token}}).then(()=>{
                this.getItems()
                this.exitoSwal()
            }).catch(error=>{
                console.log(error)
                this.errorSwal()
            })
        },
        close () {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        eliminarItem(Id){
              axios.delete(`${this.$store.state.urlapi}/userspermisos/${Id}`,{headers:{'auth-user': this.token}})
              .then(()=>{
                this.getItems()  
                this.exitoSwal()         
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        save () {
          if (this.editedIndex > -1) {
                this.editarRegistro();
          } else {
                this.guardarRegistro(); 
          }
          this.close()
        },
        
        //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        },
        deleteSwal(Id){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(Id);
            }
          })
       }
      
    },
}
</script>