import axios from 'axios'
import Swal from 'sweetalert2'
import router from '../router/index'
export default {
    state:{
        token:'',
        currentuser:false
    },
    mutations:{
        setCurrentUser(state,user){
            state.currentuser=user
        },
        setTokenUser(state,token){
            state.token = token
        }
    },
    actions:{
        setCurrentUser({commit,rootState},user){         
            axios.post(`${rootState.urlapi}/users/login`,user).then(response=>{                                
               commit("setTokenUser",response.data.authuser)
               commit("setCurrentUser",response.data.user)
               router.push("/contribuyentes");
              // this.$router.push('/contribuyentes') 
             }).catch(error=> {
                   Swal.fire({       
                       icon: 'error',
                       title: 'Usuario y/o Contraseña Incorrectos',
                       showConfirmButton: false,
                       timer: 1000
                   })
                   console.log(error)
               })           
        }
    },
    getters:{
        getCurrentUser(state){
            return state.currentuser
        },
        getToken(state){
            return state.token
        }
    }
}