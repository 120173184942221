<template >
    <div>        
        <section class="material-half-bg">
            <div class="cover"></div>
            </section>
            <div class="lockscreen-content" v-if="getCurrentUser.IdUsuario">
                    <v-card color="#343a40" dark>
                        <v-card-text>
                            Verificando Credenciales...
                            <v-progress-linear
                                indeterminate
                                color="#ffffff"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </div>  
            <section class="lockscreen-content" v-if="!getCurrentUser.IdUsuario">
             
            <!-- Formulario Ingreso-->                     
            <div class="lock-box tile">                                
                <br/>
                <h3 class="text-center user-name"><i class="fa fa-lg fa-fw fa-user"></i>INGRESO</h3>  
                <br/> 
                <hr> 
                <br/>
                
               <form v-on:submit.prevent="setCurrentUser(user)">
                    <v-text-field
                        v-model="user.Login" 
                        color="red"
                        label="USUARIO"
                        autofocus
                        required
                        ></v-text-field>  
                    <v-text-field
                            v-model="user.Clave"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-1"
                            label="CONTRASEÑA" 
                            @click:append="show1 = !show1"
                            required
                        ></v-text-field>
                
                    <div class="form-group btn-container">
                        <button type="submit" class="btn btn-primary btn-block text-white"><i class="fa fa-unlock fa-lg"></i>INGRESAR </button>
                    </div>
                </form>
            </div>
              <v-chip small >Microsystems App 2.2.1</v-chip> 
            </section>           
    </div>
</template>

<script>

import {mapActions,mapGetters} from 'vuex'

export default {
    name:'login',
      
    data: () => ({
    //Login
    autenticando:true, 
    show1: false,
    user:{
        Login:'',
        Clave:'',
    }
    }),
    created() {
        this.autenticando=false;
    },
    computed: {
        ...mapGetters(['getCurrentUser'])
    },
    methods:{
        ...mapActions({
            setCurrentUser:'setCurrentUser'
        })
        
    }
}
</script>
