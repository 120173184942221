<template>
    <div>
      <main class="app-content">
        <div class="app-title">
          <div class="col-md-6">
            <v-autocomplete
            :items="getItems"
            v-model="selecteditem"
            dense
            color="indigo"
            outlined
            clearable
            hide-details
            hide-selected
            item-text="Negocio"
            item-value="IdEstablecimiento"
            label="Buscar Nit ..."        
            @change="loadSeries()"     
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong>No se contro ningun registro</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
          </v-autocomplete>
          </div>
          <ul class="app-breadcrumb breadcrumb">
            <h3>Serie</h3>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-12">
            
            <div class="tile">
              <!-- Series vencidas!-->
              <div v-for="item in getSeriesVencimiento" :key="item.IdSerie" id="vencimientoseries">
                <v-alert dense border="left" type="warning" v-if="item.Diasrestantes>0">
                  La serie <strong>{{item.Serie}}</strong> vencerá en  <strong> {{item.Diasrestantes}}</strong> dias.
                </v-alert>
                <v-alert dense border="left" type="error" v-else>
                  La serie <strong>{{item.Serie}}</strong> se encuentra <strong> VENCIDA </strong>.
                </v-alert>
              </div>

              <v-tabs v-model="tab" fixed-tabs background-color="indigo" dark >
              
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab href="#tab-1" @click="rutas('/rg/ventas')">
                Ventas
              </v-tab>
              <v-tab href="#tab-2" @click="rutas('/rg/compras')" v-if="$route.path.indexOf('rg')>0">
                Compras
              </v-tab>           
              <v-tab href="#tab-3" >
                <v-icon color="amber" v-show="alertmsg">mdi-alert</v-icon>
                Series
              </v-tab>
            </v-tabs>
              <div id="series">
                    <br/>
                    <v-btn color="blue white--text"  @click="dialog = true">Crear Serie </v-btn>
                     <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center"> No.</th>
                            <th class="text-center"> Serie  </th>
                            <th class="text-center"> Inicio serie</th>
                            <th class="text-center"> Fin serie </th>
                            <th class="text-center">Estado</th>
                            <th class="text-center"> Vencimiento  </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in getSeries" :key="index" class="text-center" :class="{'Bold text-danger': item.DiasRestantes<1 }">
                            <td>{{index+1}}</td>
                            <td>{{ item.Serie }}</td>
                            <td>{{ item.InicioResolucion}}</td>
                            <td>{{item.FinResolucion}} </td>
                            <td>
                              <v-switch  
                                :label="item.Estado>0?'Activo':'Inactivo'" 
                                :color="item.Estado>0?'success':'error'" 
                                :input-value="item.Estado>0?true:false" 
                                @change="updateEstado(item)" 
                                hide-details
                                v-if="item.Serie!=='FEL'"
                                ></v-switch>
                            </td>
                            <td>{{formatoFecha(item.Vencimiento)}}</td>
                            
                            <td>
                              <div class="btn-group btn-group-toggle" data-toggle="buttons" v-if="item.Serie!=='FEL'">
                                  <button class="btn btn-info btn-sm"  @click="dialog = true,editedIndex =-1, editItem(item)"><v-icon class="text-white">mdi-lead-pencil</v-icon></button>                      
                                  <button class="btn btn-danger btn-sm" @click="deleteSwal(item.IdResolucion)"><v-icon class="text-white">mdi-delete</v-icon></button>
                              </div> 
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>  
                  </div> 
            </div>
          </div>
        </div>
      </main>
<!-- Modal Regimen-->
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" >
      <v-card>
        <v-card-title class="text-white teal">
          {{titleSerie}}
        </v-card-title>
        <v-card-text>
         <v-container>
           <v-row>
             <v-col cols="12" sm="6" md="4">
                <v-text-field color="blue" v-model="editedItem.Serie" outlined label="Serie*" placeholder="B" required></v-text-field>
             </v-col>
             <v-col cols="12" sm="6" md="4">
                <v-text-field color="blue" v-model="editedItem.InicioResolucion" outlined label="Inicio*" placeholder="500" required></v-text-field>
             </v-col>
             <v-col cols="12" sm="6" md="4">
                <v-text-field color="blue" v-model="editedItem.FinResolucion" outlined label="Fin*" placeholder="1000" required></v-text-field>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="12">
                <v-text-field color="blue" v-model="editedItem.NumeroResolucion" outlined label="Resolucion*" placeholder="1000" required></v-text-field>
             </v-col>
             <v-col>
                 <v-text-field v-model="editedItem.Vencimiento" label="Vencimiento Resolución" outlined color="blue" type="date"></v-text-field>
             </v-col>
           </v-row>          
         </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal text-white"  @click="save">
            Guardar
          </v-btn>
          <v-btn color="grey darken-2 text-white"  @click="close(), dialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    </div>
</template>

<script>
import axios from "axios";
import * as dayjs from 'dayjs'
import Swal from 'sweetalert2'

export default {
    name: 'Series',
    
    data() {
        return {
            dialog:false,
            tab:'tab-3',
            search:'',
            urlapi:this.$store.getters.getUrlApi,
            token:this.$store.getters.getToken,
            alertmsg:false,
            dataseries:[],
            editedIndex:-1,
            editedItem:{},
            defaultItem:{},    
        }
    },
    created(){
   this.loadSeries()
    },
    computed: {
      titleSerie() {
        return this.editedIndex === -1 ? 'Crear Nueva Serie' : 'Editar Serie'
      },
      getSeries(){
        return this.$store.getters.getSeries
      },
      getItems(){
        return this.$store.getters.getItems
      },
      selecteditem: {
        get () {
          return this.$store.getters.getSelectedItem
        },
        set (value) {
          this.$store.dispatch('setSelectedItem', value)
        }
      },
      getSeriesVencimiento(){
        return this.$store.getters.getvencimiento
      },
    },
    methods:{
        formatoFecha(fecha){
          return dayjs(fecha).locale('es').format('DD/MM/YYYY')
        },
        loadSeries(){   
         this.alertmsg = false           
         const Establecimiento=this.$store.getters.getSelectedItem
         if(Establecimiento){
          this.$store.dispatch("setSeries",Establecimiento.IdEstablecimiento)
         }
         //this.$store.getters.getSelectedItem?this.$store.getters.getSelectedItem.IdEstablecimiento:'Error al cargar negocio'
         
       
        },
        editItem (item) {  
          delete item.Diasrestantes //Eliminamos esta propiedad                  
          this.editedIndex = this.$store.getters.getSeries.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.editedItem.Vencimiento = dayjs(item.Vencimiento).format('YYYY-MM-DD');
        },
        close () {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        save () {
          if (this.editedIndex <0) {
            this.guardarRegistro(); 
            } else {
             this.editarRegistro();
          }
          this.close()
        }, 
        guardarRegistro(){               
          this.editedItem.IdEstablecimiento=this.$store.getters.getSelectedItem.IdEstablecimiento
          axios.post(`${ this.urlapi}/series`,this.editedItem,{headers:{'auth-user': this.token}})
            .then(()=>{
              this.loadSeries() 
              this.exitoSwal()         
            }).catch(error=>{
              this.errorSwal()
              console.log(error)
            })
          this.dialog = false
        },
        editarRegistro(){
            const IdResolucion = this.editedItem.IdResolucion
            delete this.editedItem.IdResolucion
            axios.put(`${this.urlapi}/series/${IdResolucion}`,this.editedItem,{headers:{'auth-user': this.token}})
              .then(()=>{
                this.loadSeries()   
                this.exitoSwal()          
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
            this.dialog = false
        },
        updateEstado(item){
          let estado = item.Estado>0?false:true
            axios.put(`${this.urlapi}/series/estado/${item.IdResolucion}`,{"Estado":estado},{headers:{'auth-user': this.token}})
              .then(()=>{
                this.loadSeries()   
                this.exitoSwal()          
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        eliminarItem(Id){
            axios.delete(`${this.urlapi}/series/${Id}`,{headers:{'auth-user': this.token}})
              .then(()=>{
                this.loadSeries()  
                this.exitoSwal()         
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        rutas(path){
         const rutaActual = this.$route.path;
         if(this.$route.path.indexOf('rp')>0){path='/rp/ventas'}
          //Validamos que se pidan rutas diferentes
         if(rutaActual !==path){this.$router.push(path)}
       },
       //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        },
        deleteSwal(Id){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(Id);
            }
          })
       } 
    }
}
</script>

<style scoped>
a{
text-decoration: none;
}
.Bold { font-weight: bold; }
</style>