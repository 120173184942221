<template>
  <div>
    <main class="app-content">
      <div class="app-title">
        <div class="col-md-5">
          <v-autocomplete
            :items="listadoContribuyentes"
            v-model="selecteditem"
            dense
            color="#17a2b8"
            outlined
            clearable
            hide-details
            hide-selected
            item-text="Nombre"
            item-value="idpersona"
            label="Seleccione Contribuyente ..."
            @change="getItems()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  <strong>No se contro ningun registro</strong>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <v-btn class="white--text" color="#009688" @click="rutas('Nuevo')">
            <v-icon left>mdi-plus-box</v-icon>
            NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <div class="tile-body" v-if="$store.getters.getCliente">
              <template>
                <v-card>
                  <v-tabs color="deep-purple accent-4" right v-model="tab">
                    <v-tab
                      href="#tab-1"
                      @click="$router.push('/pagos/transacciones')"
                      >Listado Pagos</v-tab
                    >
                    <v-tab
                      href="#tab-2"
                      @click="$router.push('/pagos/tipopagos')"
                      >Tipo Pagos</v-tab
                    >
                    <v-tab
                      href="#tab-3"
                      @click="$router.push('/pagos/mediopago')"
                      >Medios de Pago</v-tab
                    >
                    <v-tabs-items v-model="tab">
                      <v-tab-item value="tab-1"> </v-tab-item>
                      <v-tab-item value="tab-2"> </v-tab-item>
                      <v-tab-item value="tab-3"> </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </v-card>
              </template>
              <template>
                <v-card>
                  <v-card-title>
                    {{ titlerouter }}
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      color="teal"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="dataitems"
                    :search="search"
                  >
                  <template v-slot:[`item.codigo`]="{item}" >
                    <v-chip color="teal" small dark>{{ item.codigo }}</v-chip>
                  </template>
                  <template v-slot:[`item.fecha`]="{item}" >
                    <v-chip color="teal" small dark>{{ item.fecha }}</v-chip>
                  </template>
                  <template v-slot:[`item.usuario`]="{ item }">
                      {{item.usuario.slice(0, 5)}}
                  </template>
                    <template v-slot:[`item.cliente`]="{ item }">
                      <p>{{ item.cliente }}</p>
                      <strong>Nit: {{ item.nit }}</strong>
                    </template>
                    <template v-slot:[`item.estado`]="{ item }">
                      <v-switch
                        :label="item.estado"
                        color="green"
                        :input-value="item.estado == 'Cancelado' ? true : false"
                        @change="cambiarestado(item)"
                        hide-details
                        v-if="item.tipo !=='FEL'"
                      ></v-switch>

                      <p v-else> {{item.estado}} </p>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <div
                        class="btn-group btn-group-toggle"
                        data-toggle="buttons"
                        v-if="item.estado !='Anulado'"
                      >
                        <button
                          class="btn btn-info btn-sm"
                          @click="printPDF(item)"
                        >
                          <v-icon class="text-white">mdi-printer</v-icon>
                        </button>
                        <button
                          class="btn btn-warning btn-sm"
                          @click="editarDetalle(item)"
                          data-toggle="modal" data-target="#modalRegistro"
                          v-if="item.tipo !=='FEL'"
                        >
                          <v-icon class="text-white">mdi-pencil</v-icon>
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="deleteSwal(item)"
                        >
                          <v-icon class="text-white">mdi-cash-remove</v-icon>
                        </button>
                      </div>
                    </template>
                    <template v-slot:no-data>
                      <button
                        class="btn btn-primary text-white"
                        type="button"
                        @click="getItems"
                      >
                        Cargar Información
                      </button>
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </div>
            <div class="tile-body" v-else>
              <!-- SINO SE HA SELECCIONADO CONTRIBUYENTE-->
              <v-row>
                <v-col>
                  <h3>
                    <v-icon class="teal--text mdi-48px"
                      >mdi-arrow-up-bold-circle-outline</v-icon
                    >Primero debe seleccionar un Contribuyente
                  </h3>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div
      class="modal fade"
      id="modalRegistro"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalRegistroLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalRegistroLabel">Editar ó Agregar Detalle Pago</h5>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <v-autocomplete
                :items="categorias"
                v-model="editedItem.idcategoria"
                dense
                color="teal"
                outlined
                clearable
                hide-selected
                item-text="categoria"
                item-value="idcategoria"
                label="Seleccione Tipo"
                required
              >
              </v-autocomplete>
              <v-text-field
                v-model="editedItem.descripcion"
                label="Descripción"
                color="teal"
                outlined
                dense
                required
              ></v-text-field>
              <v-text-field
                v-model="editedItem.cantidad"
                label="Cantidad"
                color="teal"
                outlined
                dense
                required
              ></v-text-field>
              <v-text-field
                v-model="editedItem.precio"
                label="Precio"
                color="teal"
                outlined
                dense
                required
              ></v-text-field>

              <v-text-field
                label="Total"
                color="teal"
                required
                :value="editedItem.cantidad * editedItem.precio"
                outlined
                dense
                readonly
              ></v-text-field>
              <v-autocomplete
                :items="mediosdepagos"
                v-model="editedItem.idmedpago"
                dense
                color="teal"
                outlined
                clearable
                hide-selected
                item-text="nombre"
                item-value="idmediopago"
                label="Medio de Pago"
              >
              </v-autocomplete>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary white--text"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary white--text"
              data-dismiss="modal"
              @click="actualizarDetalle"
            >
              Guardar Cambios
            </button>
            <button
              type="button"
              class="btn btn-primary white--text"
              data-dismiss="modal"
              @click="insertarDetalle"
            >
              Agregar a Recibo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import { mapGetters } from 'vuex'
import anularFel from "@/libs/anularFel.js";

export default {
  name: "Transaccion",
  data() {
    return {
      indexpares:0,
      titlerouter: "Control de Pagos",
      token: this.$store.getters.getToken,
      dataitems: [],
      tab: "tab-1",
      editedIndex: -1,
      search: "",
      categorias: [],
      headers: [
        { text: "Codigo", value: "codigo" },
        { text: "Fecha ", value: "fecha" },
        { text: "Categoria", value: "categoria" },
        { text: "Descripcion", value: "descripcion" },
        { text: "Medio de Pago", value: "mediopago" },
        { text: "Total", value: "total" },
        { text: "Tipo", value: "tipo" },
        { text: "Atendido por", value: "usuario" },
        { text: "Estado", value: "estado" },
        { text: "Opciones", value: "actions", sortable: false },
      ],

      //TransaccionDETALLE
      editedItem: {
        cantidad: 1,
        precio: 0,
        periodo: "",
        descripcion: "",
        idcategoria: 1,
        idtransaccion: "",
        idmedpago: 1,
      },
    };
  },
  created() {
    this.$store.dispatch("setDataItems");
    this.getContribuyentes();
    this.getCategorias()
    if (this.$store.getters.getCliente) {
      this.getItems();
    }
  },
  computed: {
    ...mapGetters(['listadoContribuyentes','getUrlCertificacion']),
    selecteditem: {
      get() {
        return this.$store.getters.getCliente;
      },
      set(value) {
        this.$store.dispatch("setCliente", value);
      },
    },
    mediosdepagos(){
      return this.$store.getters.getTransaccionMedioPagos
    }
  },
  methods: {
    getItems() {
      axios
        .get(
          `${this.$store.state.urlapi}/pagos/transaccion/${this.$store.getters.getCliente}`,
          { headers: { "auth-user": this.token } }
        )
        .then((response) => {
          if (response.status == 200) {
            this.dataitems = response.data;
          } else {
            this.dataitems = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategorias() {
      axios
        .get(`${this.$store.state.urlapi}/pagos/categorias`, {
          headers: { "auth-user": this.token },
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.categorias = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getContribuyentes() {
      await this.$store.dispatch('setContribuyentes')
    },
    eliminarItem(item) {  
      console.log(item)    
      axios
        .delete(`${this.$store.state.urlapi}/pagos/transacciondetalle/${item.iddetalle}`, {
          headers: { "auth-user": this.token },
        })
        .then(() => {
          this.getItems();
          this.exitoSwal();
        })
        .catch((error) => {
          this.errorSwal();
          console.log(error);
        });
    },
    anularDTE(xmlAnularFel,item){
        axios.post(`${this.getUrlCertificacion}/fel/felSAT.php?op=anularFel`,{'xmlfel':xmlAnularFel}).then(res=>{
          
              if(res.data.Codigo==1){
                this.actualizar(item);
              }else{
                this.errorSwal(res.data.Mensaje);
              }
        }).catch((err)=>{this.errorSwal('No pudo Anularse la factura'),console.log(err)})
      },
    actualizar(item) {
      let data = { estado: item.estado, fecha: dayjs().format("YYYY-MM-DD") };
      axios
        .put(
          `${this.$store.state.urlapi}/pagos/transaccion/${item.idtransaccion}`,
          data,
          { headers: { "auth-user": this.token } }
        )
        .then(() => {
          this.exitoSwal(), this.getItems();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    printPDF(item) {
      if (item.tipo === "Factura") {
        window.open(
          "/reportes/pagos/factura.php?id=" + item.idtransaccion,
          "blank"
        );
      } else if (item.tipo === "FEL") {
        window.open(
          "/reportes/pagos/fel.php?id=" + item.idtransaccion,
          "blank"
        );
      }else {
        window.open(
          "/reportes/pagos/index.php?id=" + item.idtransaccion,
          "blank"
        );
      }
      //.idtransaccion
      // window.open('/reportes/pagos/index.php?id='+id,"blank")
    },
    rutas(ruta) {
      switch (ruta) {
        case "Nuevo":
          this.$router.push(`/pagos/nuevo`);
          break;

        default:
          break;
      }
    },
    async editarDetalle(item) {
      axios.get(
          `${this.$store.state.urlapi}/pagos/transacciondetalle/${item.iddetalle}`,
          { headers: { "auth-user": this.token } }
        ).then(res=>{
          this.editedItem = res.data[0]
        })
      //   await this.$store.dispatch('setTransaccionDetalle',item.idtransaccion)
    },
    actualizarDetalle(){
      const iddetalle = this.editedItem.iddetalle
      axios.put(
          `${this.$store.state.urlapi}/pagos/transacciondetalle/${iddetalle}`,this.editedItem,
          { headers: { "auth-user": this.token } }
        ).then(res=>{
          if(res.status==200){
            this.exitoSwal()
            this.getItems()
          }
        }).catch(this.errorSwal())
    },
    async insertarDetalle(){
      delete this.editedItem.iddetalle
    await axios.post(`${this.$store.state.urlapi}/pagos/transacciondetalle`,this.editedItem,
          { headers: { "auth-user": this.token }}
        ).then(()=>{
            this.exitoSwal()
            this.getItems()
          
        }) 
    },
    cambiarestado(item) {
      let estadocontrario =
        item.estado == "Cancelado" ? "Pendiente" : "Cancelado";
      Swal.fire({
        title: "Presione Sí para Cambiar",
        html:
          "Esta seguro de cambiar el estado de <b>" +
          item.estado +
          "</b> a <b>" +
          estadocontrario +
          "</b>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Cambiar",
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          item.estado = estadocontrario;
          this.actualizar(item);
        } else if (result.isDismissed) {
          Swal.fire("Operación CANCELADA", "", "info");
        }
      });
    },
    //NOTIFICACIONES SWAL ALERT
    exitoSwal() {
      Swal.fire({
        icon: "success",
        title: "¡Realizado!",
        showConfirmButton: false,
        timer: 1500,
      });
    },
    errorSwal() {
      Swal.fire({
        icon: "error",
        title: "Ocurrio un Error :(",
        text: "Si el error persiste contacte a soporte técnico",
      });
    },
    deleteSwal(item) {
      Swal.fire({
        title: "Presione Sí para eliminar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí",
      }).then((result) => {
        if (result.isConfirmed) {
          if(item.tipo ==='FEL'){
              const emisor = this.$store.getters.getEstablecimiento;
              const xmlAnularFel = anularFel(emisor,item)
              item.estado = 'Anulado'; //cambiamos el estado de la factura
              this.anularDTE(xmlAnularFel,item) 
            }else{
              this.eliminarItem(item)
            }
         
        }
      });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.Bold {
  font-weight: bold;
}
p {
  margin: 0 0 0 0;
  padding: 0;
}
.theme--light.v-data-table .v-row-group__header,
.theme--light.v-data-table .v-row-group__summary {
  background: rgb(26, 165, 84);
  color: white;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  color: rgb(68, 68, 68);
}
</style>
