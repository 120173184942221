import axios from "axios";
export default{
    state:{
        idtransaccion:0,
        transaccionItems:[]
    },
    mutations:{
        setIdTransaccion(state,id){
            state.idtransaccion=id;
        },
        setTransaccionDetalle(state,items){
            state.transaccionItems=items;
        }
    },
    actions:{
        setIdTransaccion({commit},id){
            commit('setIdTransaccion',id)
        },
        setTransaccionDetalle({commit,rootState},id){
            axios.get(`${rootState.urlapi}/pagos/transacciondetalle/${id}`,{headers:{'auth-user':rootState.Usuario.token}}).then(res=>{
                console.log('setTransaccionDetalle==>',res)
                commit("setIdTransaccion",res.data)
            })
        }
    },
    getters:{
        getIdTransaccion(state){
            return state.idtransaccion
        },
        getTransaccionItems(state){
            return state.transaccionItems
        }
    }
}