<template>
<header class="app-header"><a class="app-header__logo" href="#">{{auth}}</a>
      
      <ul class="app-nav">       
        <li><a href="https://portal.sat.gob.gt/" target="_blank"><img class="rounded-circle user-image" src="../assets/iconSat/AgenciaVirtual.png" height="48px"></a></li>
        <li><a href="https://portal.sat.gob.gt/portal/consulta-registro-tributario-unificado/" target="_blank"><img class="rounded-circle user-image" src="../assets/iconSat/rtu.png" height="48px"></a></li>
        <li><a href="https://declaraguate.sat.gob.gt/" target="_blank"><img class="rounded-circle user-image" src="../assets/iconSat/Declaraguate.png" height="48px"></a></li>

      </ul>
    </header>
</template>

<script>
//import router from '../router/index'
export default {
    name: 'NavBar',
    props:{
      auth:String
    },
    data() {
      return {
        
      }
    },
    methods:{   
    }
}
</script>