<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div class="col-md-5">
          <v-autocomplete
            :items="clientes"
            v-model="selecteditem"
            dense
            color="#17a2b8"
            outlined
            clearable
            hide-details
            hide-selected
            item-text="Nombre"
            item-value="idpersona"
            label="Seleccione Contribuyente ..."        
            @change="getItems()"     
          >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                <strong>No se contro ningun registro</strong>
              </v-list-item-title>
            </v-list-item>
          </template>          
          </v-autocomplete>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <v-btn class="white--text" color="#009688" @click="rutas('Nuevo')">
              <v-icon left>mdi-cash-usd</v-icon>
              NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <div class="tile-body" v-if="$store.getters.getCliente">
              <template>
                  <v-card>
                    <v-tabs
                      color="deep-purple accent-4"
                      right
                      v-model="tab"
                    >
                      <v-tab href="#tab-1" @click="$router.push('/pagos/transacciones')">Listado Pagos</v-tab>
                      <v-tab href="#tab-2" @click="$router.push('/pagos/tipopagos')">Tipo Pagos</v-tab>
                      <v-tab
                      href="#tab-3"
                      @click="$router.push('/pagos/mediopago')"
                      >Medios de Pago</v-tab
                    >
                    <v-tabs-items v-model="tab">
                      <v-tab-item value="tab-1"> </v-tab-item>
                      <v-tab-item value="tab-2"> </v-tab-item>
                      <v-tab-item value="tab-3"> </v-tab-item>
                    </v-tabs-items>
                    </v-tabs>
                  </v-card>
                </template>
                <template>
                <v-card>
                    <v-card-title>
                    <v-btn class="white--text" color="#009688" @click="dialog=true">
              <v-icon left>mdi-plus-circle-outline</v-icon>
              NUEVO TIPO PAGO
          </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        color="teal"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="dataitems"
                    :search="search"                    
                    >
                    <template v-slot:[`item.cliente`]="{item}">
                      <p>{{item.cliente}}</p>
                      <strong>Nit: {{item.nit}}</strong>
                      
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <button class="btn btn-info btn-sm"  @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro">Editar</button>                      
                            <button class="btn btn-danger btn-sm" @click="deleteSwal(item.idcategoria)">Eliminar</button>
                        </div>       
                    </template>
                    <template v-slot:no-data>
                        <button class="btn btn-primary text-white" type="button" @click="getItems">Cargar Información</button>
                    </template>
                    </v-data-table>
                </v-card>
                </template>
            </div>
            <div class="tile-body" v-else>
              <!-- SINO SE HA SELECCIONADO CONTRIBUYENTE-->
               <v-row>
                 <v-col>
                    <h3><v-icon class="teal--text mdi-48px">mdi-arrow-up-bold-circle-outline</v-icon>Primero debe seleccionar un Contribuyente</h3>
                 </v-col>
               </v-row>
            </div>
          </div>
        </div>
      </div>
    </main>
<!-- MODAL AGREGAR CLIENTE -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline teal text-white">
            {{formTitle}}
          </v-card-title>

          <v-card-text>
            <br><br>
            <v-text-field v-model="editedItem.categoria" label="Tipo Gasto" color="teal" outlined dense required autofocus></v-text-field>
            <v-select v-model="editedItem.tipo" :items="tipo" color="teal" label="Tipo" outlined dense hide-selected></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <button type="button" class="btn btn-primary text-white ma-2" @click="save"><v-icon color="white">mdi-check-circle</v-icon>LISTO</button>         
            <button type="button" class="btn btn-secondary text-white" @click="close"><v-icon color="white">mdi-close-circle</v-icon> CANCELAR</button>         
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>  
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2'

export default {
    name:'Categorias',
    data() {
        return {
            token:this.$store.getters.getToken,
            titlerouter:'Control de Pagos',
            dataitems:[],
            clientes:[],
            tipo:["Ingreso","Egreso"],
            tab:'tab-2',
            dialog:false,
            editedIndex: -1,
            search: '',
            headers: [
            { text: 'Categoria', value: 'categoria' },
            { text: 'Tipo ', value: 'tipo' },
            { text: 'Estado', value: 'estado' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],
        //Transaccion    
        editedItem: {
            categoria:'',
            tipo:'Ingreso',
            estado:'Activo'
        },
        defaultItem: {
            categoria:'',
            tipo:'Ingreso',
            estado:'Activo'
        },
       }
    },
    created() {
      if(this.$store.getters.getCliente){
        this.getItems()
      }
        
    },
    computed:{
      selecteditem: {
        get () {
          return this.$store.getters.getCliente
        },
        set (value) {
          this.$store.dispatch('setCliente', value)
        }
      },
      formTitle() {
          return this.editedIndex === -1 ? 'Nuevo Registro' : 'Editar Registro'
        },
    },
    methods: {
        getItems(){
            axios.get(`${this.$store.state.urlapi}/pagos/categorias`,{headers:{'auth-user':this.token}})
            .then(response=>{
                if(response.status==200){
                  this.dataitems = response.data;
                }else{
                  this.dataitems =[]
                }
            }).catch(error=>{
                console.log(error)
            })        
        },
        editItem(item) {
          this.dialog=true    
          this.editedIndex = this.dataitems.indexOf(item)       
          this.editedItem = Object.assign({}, item)          
        },
        close () {
          this.dialog=false 
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        save () {
          if (this.editedIndex > -1) {
                this.editarRegistro();
          } else {
                this.guardarRegistro(); 
          }
          this.close()
        },
        guardarRegistro(){          
          axios.post(`${this.$store.state.urlapi}/pagos/categorias`,this.editedItem,{headers:{'auth-user':this.token}}).then(response=>{
            if(response.status==201){
                this.getItems()
                this.exitoSwal()            
            }else{
                this.errorSwal()
            }
            })
        },
        editarRegistro(){
          const id = this.editedItem.idcategoria
            axios.put(`${this.$store.state.urlapi}/pagos/categorias/${id}`,this.editedItem,{headers:{'auth-user':this.token}}).then(response=>{
              if(response.status==200){
                  this.getItems()
                  this.exitoSwal()            
              }else{
                  this.errorSwal()
              }
            })
        },
        eliminarItem(id){
              axios.delete(`${this.$store.state.urlapi}/pagos/categorias/${id}`,{headers:{'auth-user': this.token}})
              .then(response=>{
                if(response.status==200){
                  this.getItems()
                  this.exitoSwal()            
                }else{
                    this.errorSwal()
                }        
              }).catch(error=>{
                this.errorSwal()
                console.log(error)
              })
        },
        rutas(ruta){

            switch (ruta) {
              case 'Nuevo':
                this.$router.push(`/pagos/nuevo`)
                break;
            
              default:
                break;
            }
        },
        //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        },
        deleteSwal(Id){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(Id);
            }
          })
       }
      
    },
}
</script>

<style scoped>
a{
text-decoration: none;
}
.Bold { font-weight: bold; }
p{
 margin :0 0 0 0; 
 padding: 0;
}
</style>