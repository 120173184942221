<template>
    <div>
        <main class="app-content">
      <div class="app-title">
        <div>
          <h1><i class="fa fa-dashboard"></i>{{titlerouter}}</h1>
          <p>Listado de todos los Usuarios</p>
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <v-btn class="white--text" color="#009688" data-toggle="modal" data-target="#modalRegistro">
              <v-icon left>mdi-account-plus</v-icon>
              NUEVO
          </v-btn>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="tile">
            <div class="tile-body">
                <template>
                <v-card>
                    <v-card-title>
                    {{titlerouter}}
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                    <v-data-table
                    :headers="headers"
                    :items="dataitems"
                    :search="search"
                    >
                    <template v-slot:[`item.Estado`]="{item}">
                        <span class="badge" :class="item.condicion==1?'badge-success':'badge-danger'"> {{item.condicion==1?'Activo':'Inactivo'}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <button class="btn btn-info btn-sm"  @click="editItem(item)" data-toggle="modal" data-target="#modalRegistro"><v-icon class="text-white">mdi-account-edit</v-icon></button>                      
                            <button class="btn btn-info btn-sm"  @click="getPermisos(item.IdUsuario)" v-if="item.condicion==1" data-toggle="modal" data-target="#modalPermisos"><v-icon class="text-white">mdi-shield-lock-outline</v-icon></button>                      
                            <button class="btn btn-danger btn-sm" @click="deleteSwal(item)"><v-icon class="text-white">mdi-account-remove</v-icon></button>
                        </div>       
                    </template>
                    <template v-slot:no-data>
                        <button class="btn btn-primary text-white" type="button" @click="getItems">Cargar Información</button>
                    </template>
                    </v-data-table>
                </v-card>
                </template>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Modal -->
<div class="modal fade" id="modalRegistro" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalRegistroLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalRegistroLabel">{{formTitle}}</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" @click="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>          
            <v-text-field  v-model="editedItem.Nombre" label="Nombre *" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Direccion" label="Dirección" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Telefono" label="Telefono" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Login" label="Login *" color="blue" dense outlined required></v-text-field>           
            <v-text-field  v-model="editedItem.Clave" label="Clave *" color="blue" type="password" dense outlined required></v-text-field>           
 
  
             <div class="form-group">
              <label>Cambiar Estado</label>
              <select class="form-control" v-model="editedItem.condicion">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div> 
        </form>  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary white--text" data-dismiss="modal" @click="close">Cancelar</button>
        <button type="button" class="btn btn-primary white--text" data-dismiss="modal"  @click="save">Guardar</button>
      </div>
    </div>
  </div>
</div>
<!--MODAL PERMISOS -->
<div class="modal fade" id="modalPermisos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="modalPermisosLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalPermisosLabel">Permisos Usuario</h5>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-md-6">
              <v-card
                class="mx-auto"
                max-width="300"
                tile
              >
                <v-list dense>
                  <v-subheader><h3>Listado Permisos</h3></v-subheader>
                  <v-list-item-group
                    color="blue"
                  >
                    <v-list-item
                      v-for="(item, i) in itemspermisos"
                      :key="i"
                      @click="asignacionPermisos(item,1)"
                    >
                      <v-list-item-content>
                        <strong>{{item.Nombre}}</strong>                      
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon  color="green">mdi-chevron-right-circle</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </div>
            <div class="col-md-6">
              <v-card class="mx-auto"
                max-width="300"
                tile
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th colspan="2">
                        <v-subheader><h3>Permisos Actuales</h3></v-subheader>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in permisosasignados"
                        :key="item.IdPermiso"
                         @click="asignacionPermisos(item,0)"
                      >
                        <td>{{ item.Nombre }}</td>
                        <td><v-icon  color="red">mdi-close-circle</v-icon></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </div>                  
        </form>  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary white--text" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary white--text" data-dismiss="modal"  @click="savePermisos">Guardar</button>
      </div>
    </div>
  </div>
</div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            token:this.token,
            urlapi:this.$store.state.urlapi,
            titlerouter:'Usuarios',
            dataitems:[],
            permisosasignados:[],
            itemspermisos:[],
            idusuario:0,
            editedIndex: -1,
            search: '',
            headers: [
            { text: 'Nombre', value: 'Nombre' },
            { text: 'Telefono', value: 'Telefono' },
            { text: 'Login', value: 'Login' },
            { text: 'Direccion', value: 'Direccion' },
            { text: 'Estado', value: 'Estado' },
            { text: 'Opciones', value: 'actions', sortable: false },
            ],
        editedItem: {
          Nombre:'',
          Telefono:'',
          Direccion:'',
          Login:'',
          condicion:1,
        },
        defaultItem: {
          Nombre:'',
          Telefono:'',
          Direccion:'',
          Login:'',
          condicion:1,
        },       
       }
    },
    created() {
        this.getItems()
        this.getPermisosItems()
    },
    computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
        },
        inputvalidos(){
            if(this.editedItem.Nombre.length>5 && this.editedItem.Clave.length>5 && this.editedItem.Login.length>5){
                return  true
            }
            return false
        }
    },
    methods: {
        getItems(){
            axios.get(`${this.urlapi}/users/`,this.token)
            .then(response=>{
                this.dataitems = response.data;
            }).catch(error=>{
                console.log(error)
            })        
        },
        editItem(item) {  
          this.editedIndex = this.dataitems.indexOf(item)       
          this.editedItem = Object.assign({}, item)      
        },
        guardarRegistro(){          
          axios.post(`${this.urlapi}/users/`,this.editedItem,this.token).then(response=>{
            if(response.status==200){
                this.getItems()
                this.exitoSwal()
            }
            }).catch(error=>{
                console.log(error)
                this.errorSwal()
            })         
        },
        editarRegistro(){
            const id = this.editedItem.IdUsuario
            axios.put(`${this.urlapi}/users/${id}`,this.editedItem,this.token).then(()=>{
                this.getItems()
                this.exitoSwal()
            }).catch(error=>{
                console.log(error)
                this.errorSwal()
            })
        },
        close () {
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
        eliminarItem(item){
                this.editItem (item);
                this.editedItem.condicion =2;
                this.editarRegistro();
        },
        save () {
          if (this.editedIndex > -1) {
                this.editarRegistro();
          } else {
                this.guardarRegistro(); 
          }
          this.close()
        },
        //Metodos Permisos
        getPermisosItems(){
            axios.get(`${this.urlapi}/userspermisos/`,this.token)
            .then(response=>{
                this.itemspermisos = response.data;
            }).catch(error=>{
                console.log(error)
            })        
        },
        //Insertar Permisos
        asignacionPermisos(item,accion){
          // accion == 1 push
          // accion == 0 pop
           const index = this.permisosasignados.findIndex(buscar => buscar.IdPermiso == item.IdPermiso);
          if(accion==1&&index<0){
            this.permisosasignados.push(item)
          }else if(accion==0){
            this.permisosasignados.splice(index,1)
          }

        },
        getPermisos(IdUsuario){
          this.permisosasignados=[]//Vaciamos el array
          this.idusuario = IdUsuario
          axios.get(`${this.urlapi}/userspermisos/${IdUsuario}`).then(res=>{
            res.status==200? this.permisosasignados=res.data : console.log(res.statusText)
          }).catch(()=>{this.errorSwal()})
        },
        savePermisos(){
          axios.post(`${this.urlapi}/userspermisos/rol/${this.idusuario}`,this.permisosasignados,this.token).then(res=>{
           res.status==200 ? this.exitoSwal() : this.errorSwal()
          }).catch(()=>{this.errorSwal()})
        },
        //NOTIFICACIONES SWAL ALERT
        exitoSwal(){
            Swal.fire({
                icon: 'success',
                title: '¡Realizado!',
                showConfirmButton: false,
                timer: 1500
            })
        },
        errorSwal(){
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un Error :(',
                text:'Si el error persiste contacte a soporte técnico'
            })
        },
        deleteSwal(item){
         Swal.fire({
            title: 'Presione Sí para eliminar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'Cancelar',
            confirmButtonText: 'Sí'
          }).then((result) => {
            if (result.isConfirmed) {
              this.eliminarItem(item);
            }
          })
       }
      
    },
}
</script>