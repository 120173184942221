import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    state:{
        claves:[],
        idpersona:0,
    },
    mutations:{
        setClaves(state,data){
            state.claves=data;
        }
    },
    actions:{
        async getClaves({commit,rootState,getters}){
           await axios.get(`${rootState.urlapi}/claves/${getters.getIdPersona}`,{headers:{'auth-user': getters.getToken}}).then(res=>{
                if(!res.data[0]){commit("setClaves",[])}
                commit("setClaves",res.data)
            })
        },
        async postClaves({rootState,getters},data){
            data.idpersona = getters.getIdPersona;
            await axios.post(`${rootState.urlapi}/claves`,data,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo crearse el registro')})
        }, 
        async putClaves({rootState,getters},data){
            data.idpersona = getters.getIdPersona;
           await axios.put(`${rootState.urlapi}/claves/${data.idclaves}`,data,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo crearse el registro')})
        },
        async deleteClaves({rootState,getters},id){
            await axios.delete(`${rootState.urlapi}/claves/${id}`,{headers:{'auth-user': getters.getToken}})
            .then(()=>{swalOk()}).catch(()=>{swalError('No pudo eliminarse el registro')})
        }
    },
    getters: {
        getClaves(state){
            return state.claves;
        }
    }
}

function swalOk(){
    Swal.fire({       
        icon: 'success',
        title: '¡Realizado!',
        showConfirmButton: false,
        timer: 1500
    })
}

function swalError(message){
    Swal.fire({       
        icon: 'error',
        title: 'Error',
        text:message
    })
}