import Vue from 'vue'
//import {mapGetters} from 'vuex'
import VueRouter from 'vue-router'
import Login from '@/components/login.vue'
//Rutas Contribuyente 
import Contribuyente from  '@/components/contribuyentes/Contribuyentes.vue'
import ContribuyentePerfil from  '@/components/contribuyentes/Perfil.vue'

import Establecimientos from  '@/components/Establecimientos.vue'
import FELCombustible from  '@/components/FEL/FELCombustible.vue'
import FELCompras from  '@/components/FEL/FELCompras.vue'
import FELVentas from  '@/components/FEL/FELVentas.vue'
import Usuarios from  '@/components/Usuarios.vue'
import UsuariosPermisos from  '@/components/UsuariosPermisos.vue'
import LibroVentasRG from  '@/components/regimengeneral/LibroVentasRG.vue'
import LibroComprasRG from  '@/components/regimengeneral/LibroComprasRG.vue'
import FacturasVentasRG from  '@/components/regimengeneral/FacturasVentasRG.vue'
import FacturasComprasRG from  '@/components/regimengeneral/FacturasComprasRG.vue'
import FacturasCombustibleRG from  '@/components/regimengeneral/FacturasCombustible.vue'
import Series from  '@/components/regimengeneral/Series.vue'
import store from '@/store/index'
//Ruta Control de pagos
import Transacciones from  '@/components/controlpagos/Transacciones.vue'
import Categorias from  '@/components/controlpagos/Categorias.vue'
import TransaccionesDetalles from  '@/components/controlpagos/TransaccionDetalle.vue'
import ReportePagos from  '@/components/controlpagos/Reportes.vue'
import MedioPago from  '@/components/controlpagos/MediodePago.vue'



Vue.use(VueRouter)

const router = new VueRouter({ 
  mode: 'history',
  routes : [
    {
      path: '*',
      component: () => import(/* webpackChunkName: "about" */ '../views/error404.vue')
    },
  {
    path: '/',
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    component:Login
  },
  {
    path: '/establecimientos',
    name: 'establecimientos',
    component: Establecimientos,
    meta:{autenticado:true}
  },
  {
    path: '/contribuyentes',
    name: 'Contribuyentes',   
    meta:{autenticado:true},
    component:Contribuyente
  },
  {
    path: '/contribuyentes/perfil',
    name: 'contribuyenteperfil',   
    meta:{autenticado:true},
    component:ContribuyentePerfil,
  },
  {
    path: '/:idregimen/ventas',
    name: 'LibroVentasRG',   
    meta:{autenticado:true},
    component:LibroVentasRG,
  },
  {
    path: '/procesardte',
    name: 'ProcesarDTE',   
    meta:{autenticado:true},
    component:FELCombustible,
  },
  {
    path: '/procesarfel',
    name: 'ProcesarFEL',   
    meta:{autenticado:true},
    component:FELCompras,
  },
  {
    path: '/procesarfelventas',
    name: 'ProcesarFelVentas',   
    meta:{autenticado:true},
    component:FELVentas,
  },
  {
    path: '/rg/compras',
    name: 'LibroComprasRG',   
    meta:{autenticado:true},
    component:LibroComprasRG,
  },
  {
    path: '/:idregimen/series',
    name: 'Series',   
    meta:{autenticado:true},
    component:Series,
  },
  {
    path: '/:idregimen/facturasventas',
    name: 'FacturasVentasRG',   
    meta:{autenticado:true},
    component:FacturasVentasRG,
  },
  {
    path: '/rg/facturascombustible',
    name: 'FacturasCombustible',   
    meta:{autenticado:true},
    component:FacturasCombustibleRG,
  },
  {
    path: '/rg/facturascompras',
    name: 'FacturasComprasRG',   
    meta:{autenticado:true},
    component:FacturasComprasRG,
  },
  {
    path: '/usuarios',
    name: 'Usuarios',   
    meta:{autenticado:true},
    component:Usuarios,
  },
  {
    path: '/usuariospermisos',
    name: 'UsuariosPermisos',   
    meta:{autenticado:true},
    component:UsuariosPermisos,
  },
  //Rutas de Control Pagos
  {
    path: '/pagos/transacciones',
    name: 'Transacciones',   
    meta:{autenticado:true},
    component:Transacciones,
  },
  {
    path: '/pagos/reportes',
    name: 'ReportePagos',   
    meta:{autenticado:true},
    component:ReportePagos,
  },
  {
    path: '/pagos/nuevo',
    name: 'TransaccionesDetalles',   
    meta:{autenticado:true},
    component:TransaccionesDetalles,
  },
  {
    path: '/pagos/tipopagos',
    name: 'Categoria',   
    meta:{autenticado:true},
    component:Categorias,
  },
  {
    path: '/pagos/mediopago',
    name: 'MedioPago',   
    meta:{autenticado:true},
    component:MedioPago,
  }
],

})

router.beforeEach((to, from, next) => {
  let  currentuser = store.getters.getCurrentUser.IdUsuario?true:false;
  let autorizacion = to.matched.some((record) => record.meta.autenticado)
  if(!currentuser && autorizacion){
    next({ name: 'login' })
  }else if(currentuser && !autorizacion){ 
    next({ name: 'login' })
  }else{
    next()
  }
})





export default router
