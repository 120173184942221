import axios from "axios";
import * as dayjs from 'dayjs'

export default {
    state:{
        itemsserie:[],
        seriesvencidas:[],
    },
    mutations:{
        setSeries(state,series){
            state.itemsserie=series
        }
    },
    actions:{
        setSeries({commit,rootState},IdEstablecimiento){            
            axios.get(`${rootState.urlapi}/series/${IdEstablecimiento}`,{headers:{'auth-user':rootState.Usuario.token}})
            .then(response=>{  
                if(response.data.length>0){

                    commit("setSeries",response.data)
                    response.data.splice(response.data.length,1,{"IdResolucion":0,"Serie":"FEL","Estado":1,Vencimiento:dayjs().add(1, 'years')}) 
                }else{
                    commit("setSeries",[{"IdResolucion":0,"Serie":"FEL","Estado":1,Vencimiento:dayjs().add(1, 'years')}])
                }                         
            })
        }
    },
    getters:{
        getSeriesActivas(state){
            let itemactivos = []
            state.itemsserie.forEach(item => {
                if(item.Estado===1){
                    itemactivos.push(item)
                }
            });
            return itemactivos
        },
        getSeries(state){
            return state.itemsserie
        },
        getvencimiento(state){
            state.seriesvencidas=[]
            //Establecemos la fecha actual
            let fechaactual= dayjs().format('YYYY-MM-DD')
            state.itemsserie.filter(item=>{
                
                    //Asignamos la fecha vencimiento de la serie
                    const vencimientoserie = dayjs(item.Vencimiento)
                    //Calculamos la diferencia de dias entre fecha actual y fecha vencimiento
                    let dias = vencimientoserie.diff(fechaactual,'day'); 
                    item.Diasrestantes = dias
                    if(dias<=31){
                        //Insertamos las series vencidas
                        state.seriesvencidas.push(item)
                    }
                
            })
            //Retornamos las series vencidas
            return state.seriesvencidas;
        }
    }
}
