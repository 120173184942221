import Vue from 'vue'
import Vuex from 'vuex'
import Usuario from "./usuario";
import Establecimientos from "./establecimientos";
import Series from "./series";
import Ventas from "@/components/ventas/ventas.vuex";
import Compras from "./compras";
import Personas from "./personas";
import Transaccion from "./transaccion";
import Facturas from "./facturasfel";
import TransaccionDetalles from './transacciondetalle'
import TransaccionMedioPago from './transaccionmediopago'
import Contacto from "@/components/contribuyentes/contacto/contacto.vuex";
import Claves from "@/components/contribuyentes/claves/claves.vuex";
import Contribuyentes from "@/components/contribuyentes/contribuyentes.vuex";
Vue.use(Vuex)

export const RUTA_SERVIDOR_API = process.env.VUE_APP_RUTA_API;
export const SERVIDOR = process.env.VUE_APP_RUTA_SERVIDOR;

const store = new Vuex.Store({
  state: {
    IdContribuyente:0,
    urlapi:RUTA_SERVIDOR_API,
    urlproduccion:SERVIDOR,
  },
  mutations: {},
  actions:{},
  getters:{
    getUrlApi(state){
      return state.urlapi
    },
    getUrlCertificacion(state){
      return state.urlproduccion
    }
  },
  modules:{
    Usuario,Establecimientos,Series,Ventas,Compras,Personas,Facturas,Transaccion,
    TransaccionDetalles,TransaccionMedioPago,Contacto,Claves,Contribuyentes
  }
})

export default store 