export function reportes(item,path){
    let Url=''
    let Fel=false
    let Regimen = path.indexOf('rp')>0?false:true
    //Verificamos si la serie es FEL
    item.Serie=='FEL'?Fel=true:Fel=false
   if(item.TipoVenta==='Combustibles'){
      Fel?Url='/reportes/ventasfelrg.php?type=GS&id=':Url='/reportes/ventas.php?type=GS&id='
      
      window.open(Url+item.IdVenta,"blank")
    }else if(item.TipoVenta==='Bienes' && Regimen){
      Fel?Url='/reportes/ventasfelrg.php?type=BN&id=':Url='/reportes/ventas.php?type=BN&id='
      window.open(Url+item.IdVenta,"blank")
    }else{
        path.indexOf('rp')>0?Url=`/reportes/comprasyventas.php?fel=${Fel}&id=`:Url="/reportes/ventas.php?type=BN&id="
      window.open(Url+item.IdVenta,"blank")
    }
}